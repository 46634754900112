import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { TIdinData, TLocateData } from 'interfaces/account-settings';
import { TField } from 'interfaces/authentication';
import {
  ActionType,
  AToZData,
  DataConfig,
  EFavoriteTabsTypes,
  THomeworkData
} from 'interfaces/generic';
import { TTourDataSteps, TTourSteps } from 'interfaces/tour';
import { TAfterSignInActions } from 'services/afterSignIn';
import { AppDataActionTypes } from 'store/action-types/app-data';

export type THeaderState = {
  isVisible: boolean;
  height: number;
};

export type TSectionDropdown = {
  label: string;
  sectionType: string;
  formType: string;
  hasSettings: boolean;
  showHide: boolean;
  hidden: boolean;
  type: string;
};

export type PersonalizationGenericSettings = {
  gamblingAreaPopup?: {
    timer: number;
    destinationUrl: string;
  };
  accountSettings?: {
    playerInfoShowDate?: string;
  };
};

type InitialData = {
  colCount: number;
  recaptchaEnabled?: boolean;
  recaptchaVersion?: number;
  siteKey?: string;
  turnstileEnabled: boolean;
  turnstileSiteKey: string;
  turnstileVerifyActions: string[];
  dataConfigs?: Record<string, DataConfig>;
  rounding?: number;
  roundingLoading: boolean;
  sectionsData?: {
    sections: any[];
    myProfile: any[];
    accountDropdownSectionsDesktop: Array<TSectionDropdown>;
    accountDropdownSectionsMobile: Array<TSectionDropdown>;
  } | null;
  postGridHiddenParams: {
    addressSelected: boolean;
    manualSelected: boolean;
    hiddenFields: any[];
    hiddenFieldValues: Record<string, any>;
  };
  showAdditionalInfo: boolean;
  openedModals: Set<string>;
  atozMenuData: Record<string, any>;
  menuJsonData: AToZData[];
  regFieldData?: TField[];
  cashoutModalOpen: boolean;
  depositModalOpen: boolean;
  fireBaseToken: string;
  oneSignalSubId: string;
  confirmedDeposit: boolean;
  seonSessionData?: string;
  headerState: THeaderState;
  geolocationShakeData: string | null;
  homeworkData: THomeworkData | null;
  sportsConfigsData: { [key: string]: string };
  recaptchaKey?: string;
  sportsBookType: string;
  isPaymentsLoading: boolean;
  setCryptoCurrencies: { Currency: string; Balance: number }[];
  betShops?: { name: string; betshops: { id: number; address: string }[] }[];
  sid?: string;
  remainingSessionDuration?: {
    type: string | null;
    isOpen: boolean;
  };
  favoriteOnHeaderOpen: boolean;
  favoriteSelectedTab: EFavoriteTabsTypes;
  idinData: TIdinData;
  locateData: TLocateData;
  isDarkMode: boolean;
  higherZIndex: boolean;
  initSportsbookIframe: boolean;
  forgotPasswordOpen: boolean;
  forgotUsernameOpen: boolean;
  showToTopNav: boolean;
  tourSteps: TTourSteps;
  tourModalVisible: boolean;
  tourStepsInitialData: TTourDataSteps;
  personalizedGenericSettingsResourceId: null | string;
  personalizedGenericSettings: PersonalizationGenericSettings;
  afterSignInActions: TAfterSignInActions;
};

const initialData: InitialData = {
  colCount: 0,
  roundingLoading: true,
  turnstileEnabled: false,
  turnstileSiteKey: '',
  turnstileVerifyActions: [],
  sectionsData: null,
  postGridHiddenParams: {
    addressSelected: false,
    manualSelected: false,
    hiddenFields: [],
    hiddenFieldValues: {}
  },
  showAdditionalInfo: false,
  openedModals: new Set(),
  atozMenuData: {},
  menuJsonData: [],
  cashoutModalOpen: false,
  depositModalOpen: false,
  fireBaseToken: '',
  oneSignalSubId: '',
  confirmedDeposit: false,
  headerState: {
    isVisible: false,
    height: 0
  },
  geolocationShakeData: null,
  sportsConfigsData: {},
  recaptchaKey: '',
  homeworkData: null,
  sportsBookType: '',
  isPaymentsLoading: true,
  setCryptoCurrencies: [],
  sid: '',
  remainingSessionDuration: {
    type: null,
    isOpen: false
  },
  favoriteOnHeaderOpen: false,
  favoriteSelectedTab: EFavoriteTabsTypes.SPORTSBOOK,
  idinData: null,
  locateData: null,
  isDarkMode:
    document?.documentElement?.getAttribute('data-theme')?.split('-')[1] ===
    'dark',
  higherZIndex: false,
  initSportsbookIframe: false,
  forgotPasswordOpen: false,
  forgotUsernameOpen: false,
  showToTopNav: false,
  tourSteps: {},
  tourModalVisible: true,
  tourStepsInitialData: {},
  personalizedGenericSettingsResourceId: null,
  personalizedGenericSettings: {},
  afterSignInActions: {}
};

const appDataReducer = (
  state = initialData,
  action: ActionType
): InitialData => {
  switch (action.type) {
    case AppDataActionTypes.SET_COL_COUNT:
      return {
        ...state,
        colCount: action.payload
      };

    case AppDataActionTypes.SET_RECAPTCHA_DETAILS:
      return {
        ...state,
        recaptchaEnabled: action.payload.recaptchaEnabled,
        recaptchaVersion: action.payload.recaptchaVersion,
        siteKey: action.payload.siteKey
      };

    case AppDataActionTypes.SET_TURNSTILE_DETAILS:
      return {
        ...state,
        turnstileEnabled: action.payload.turnstileEnabled,
        turnstileSiteKey: action.payload.turnstileSiteKey,
        turnstileVerifyActions: action.payload.turnstileVerifyActions
      };

    case AppDataActionTypes.SET_DATA_CONFIGS:
      return {
        ...state,
        dataConfigs: {
          ...(state?.dataConfigs || {}),
          [action.payload.moduleId]: action.payload.configs
        }
      };

    case AppDataActionTypes.SET_ROUNDING:
      return {
        ...state,
        rounding: action.payload
      };

    case AppDataActionTypes.SET_ROUNDING_LOADING:
      return {
        ...state,
        roundingLoading: action.payload
      };
    case AppDataActionTypes.SET_SECTIONS_DATA:
      return {
        ...state,
        sectionsData: action.payload
      };

    case AppDataActionTypes.SET_POSTGRID_HIDDEN_PARAMS:
      return {
        ...state,
        postGridHiddenParams: action.payload
      };

    case AppDataActionTypes.UPDATE_POSTGRID_HIDDEN_PARAMS:
      return {
        ...state,
        postGridHiddenParams: {
          ...state.postGridHiddenParams,
          ...action.payload
        }
      };

    case AppDataActionTypes.RESET_POSTGRID_HIDDEN_PARAMS:
      return {
        ...state,
        postGridHiddenParams: action.payload
      };

    case AppDataActionTypes.SET_SHOW_ADDITIONAL_INFO:
      return {
        ...state,
        showAdditionalInfo: action.payload
      };

    case AppDataActionTypes.SET_OPENED_MODALS:
      return {
        ...state,
        openedModals: action.payload
      };

    case AppDataActionTypes.SET_ATOZMENU_DATA: {
      return {
        ...state,
        atozMenuData: {
          [action.payload.id]: action.payload.data
        }
      };
    }

    case AppDataActionTypes.SET_SPORTSCONFIGS_DATA:
      return {
        ...state,
        sportsConfigsData: action.payload
      };
    case AppDataActionTypes.SET_MENU_JSON_DATA:
      return {
        ...state,
        menuJsonData: action.payload
      };
    case AppDataActionTypes.SET_REG_FIELD:
      return {
        ...state,
        regFieldData: action.payload
      };
    case AppDataActionTypes.SET_CASHOUT_MODAL_OPEN:
      return {
        ...state,
        cashoutModalOpen: action.payload
      };
    case AppDataActionTypes.SET_FIREBASE_TOKEN:
      return {
        ...state,
        fireBaseToken: action.payload
      };
    case AppDataActionTypes.SET_ONE_SIGNAL_SUB_ID:
      return {
        ...state,
        oneSignalSubId: action.payload
      };
    case AppDataActionTypes.SET_DEPOSIT_MODAL_OPEN:
      return {
        ...state,
        depositModalOpen: action.payload
      };
    case AppDataActionTypes.SET_DEPOSIT_PAYMENT_STATUS:
      return {
        ...state,
        confirmedDeposit: action.payload
      };
    case AppDataActionTypes.SET_SEON_SESSION_DATA:
      return {
        ...state,
        seonSessionData: action.payload
      };
    case AppDataActionTypes.SET_HEADER_STATE:
      return {
        ...state,
        headerState: { ...state.headerState, ...action.payload }
      };
    case AppDataActionTypes.SET_GEOLOCATION_SHAKE_DATA:
      return {
        ...state,
        geolocationShakeData: action.payload
      };
    case AppDataActionTypes.SET_RECAPTCHA_KEY:
      return {
        ...state,
        recaptchaKey: action.payload
      };
    case AppDataActionTypes.SET_BET_SHOPS:
      return {
        ...state,
        betShops: action.payload
      };
    case AppDataActionTypes.SET_HOMEWORK_DATA:
      return {
        ...state,
        homeworkData: action.payload
      };
    case AppDataActionTypes.SET_SPORTSBOOK_TYPE:
      return {
        ...state,
        sportsBookType: action.payload
      };
    case AppDataActionTypes.SET_IS_PAYMENTS_LOADING:
      return {
        ...state,
        isPaymentsLoading: action.payload
      };
    case AppDataActionTypes.SET_CRYPTO_CURRENCIES:
      return {
        ...state,
        setCryptoCurrencies: action.payload
      };
    case AppDataActionTypes.SET_SID:
      return {
        ...state,
        sid: action.payload
      };
    case AppDataActionTypes.SET_REMAINING_SESSION_DURATION_MODAL:
      return {
        ...state,
        remainingSessionDuration: action.payload
      };
    case AppDataActionTypes.SET_FAVORITE_ON_HEADER_OPEN:
      return {
        ...state,
        favoriteOnHeaderOpen: action.payload
      };
    case AppDataActionTypes.SET_FAVORITE_ON_HEADER_SELECTED_TAB:
      return {
        ...state,
        favoriteSelectedTab: action.payload
      };
    case AppDataActionTypes.SET_IDIN_DATA:
      return {
        ...state,
        idinData: action.payload
      };
    case AppDataActionTypes.SET_LOCATE_DATA:
      return {
        ...state,
        locateData: action.payload
      };
    case AppDataActionTypes.SET_IS_DARK_MODE:
      return {
        ...state,
        isDarkMode: action.payload
      };
    case AppDataActionTypes.SET_HIGHER_Z_INDEX:
      return {
        ...state,
        higherZIndex: action.payload
      };
    case AppDataActionTypes.SET_SHOW_TO_TAP_NAV:
      return {
        ...state,
        showToTopNav: action.payload
      };

    case AppDataActionTypes.SET_INIT_SPORTSBOOK_IFRAME:
      return {
        ...state,
        initSportsbookIframe: true
      };

    case AppDataActionTypes.SET_FORGOT_PASSWORD_OPEN:
      return {
        ...state,
        forgotPasswordOpen: action.payload
      };

    case AppDataActionTypes.SET_FORGOT_USERNAME_OPEN:
      return {
        ...state,
        forgotUsernameOpen: action.payload
      };

    case AppDataActionTypes.SET_TOUR_STEPS_INITIAL_DATA:
      return {
        ...state,
        tourStepsInitialData: action.payload
      };

    case AppDataActionTypes.SET_TOUR_MODAL_VISIBLE:
      return {
        ...state,
        tourModalVisible: action.payload
      };

    case AppDataActionTypes.SET_TOUR_STEPS: {
      return {
        ...state,
        tourSteps: action.payload
      };
    }

    case AppDataActionTypes.SET_PERSONALIZATION_GENERIC_SETTINGS:
      return {
        ...state,
        personalizedGenericSettings: action.payload
      };

    case AppDataActionTypes.SET_AFTER_SIGN_IN_ACTIONS: {
      return {
        ...state,
        afterSignInActions: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'appData',
  storage: storage,
  whitelist: ['afterSignInActions']
};

export const appData = persistReducer(persistConfig, appDataReducer);
