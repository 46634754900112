import dayjs from 'dayjs';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { deepMerge } from 'utils/collection-manipulation/deep-merge';
import { SECONDS_PER_MINUTE } from 'utils/constants/dateInfo/time-numbers';
import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import { storageKeyName } from 'utils/generic/storage-key-name';
import RidGenerator from 'utils/swarm/rid-generator';
import { NewCasinoGame } from 'interfaces/new-casino';
import {
  Competition,
  FavoriteCompetitionInfo
} from 'interfaces/sportsbook-data-levels';
import { TTourDataSteps } from 'interfaces/tour';
import { authCommand } from 'services/authentication';
import { swarmCommand } from 'services/get-swarm-data';
import {
  TTeamsDictionary,
  TTeamsDictionaryItem
} from 'newcomponents/Shared/sportsbook/FavoriteTeam/partials/SearchTeam';
import store from 'store';
import {
  setFavoriteCasinoGames,
  setFavoriteCasinoProviders,
  setFavoriteCompetitions,
  setFavoriteMarkets,
  setFavoriteSports,
  setFavoriteSportsData,
  setPersonalisedDataLoaded,
  setPersonalizationData,
  setPersonalizedCompetitionResourceId,
  setPersonalizedGameResourceId,
  setPersonalizedGenericSettings,
  setPersonalizedMarketResourceId,
  setPersonalizedProviderResourceId,
  setPersonalizedSportGamesResourceId,
  setPersonalizedSportsResourceId,
  setPersonalizedSportTeamsResourceId,
  setSportTeams,
  setTourStepsInitialData
} from 'store/actions';
import { PersonalizationGenericSettings } from 'store/reducers/app-data';

const SCOPE = 'spring_builder_x';
const SHOW_POPUP_RESOURCE = 'showPersonalizationPopup';
const CASINO_GAMES_RESOURCE = 'casinoFavoriteGames';
const SPORT_FAVORITE_TEAMS_RESOURCE = 'sportFavoriteTeams';
const CASINO_PROVIDERS_RESOURCE = 'casinoFavoriteProviders';
const SPORT_COMPETITIONS_RESOURCE = 'sportFavoriteCompetition';
const SPORT_MARKET_GROUP_RESOURCE = 'favoriteMarkets';
const SPORT_GAMES_RESOURCE = 'sportSBFavoriteGames';
const SPORTS_RESOURCE = 'sportFavoriteSportList';
const PERSONALIZATION_GENERIC_SETTINGS = 'sbGenericSettings';
const TOUR_STEPS = 'tourSteps';

type TSportIds = {
  [key: number]: {};
};
export type TPersonalizedSportGames = {
  sportsbook: {
    live: TSportIds;
    prematch: TSportIds;
  };
  esport: {
    live: TSportIds;
    prematch: TSportIds;
  };
};
type TPreparedSportGroup = {
  count: number;
  data: {};
  ids: number[];
};
export type TPersonalizedSportGamesPrepared = {
  sportsbook: {
    live: TPreparedSportGroup;
    prematch: TPreparedSportGroup;
  };

  esport: {
    live: TPreparedSportGroup;
    prematch: TPreparedSportGroup;
  };
  favoriteMatchesCountPeerCompetition: Record<Competition['id'], number>;
} | null;

const tourStepsData = {
  sportsbook: { 0: {} },
  casino: { 0: {} }
};

type TPersonalizationResponse = {
  details: {
    data: {
      sportFavoriteSportList: {
        configs: { [key: number]: {} };
        _id: string;
      };
      sportFavoriteCompetition: {
        configs: { [key: number]: FavoriteCompetitionInfo };
        _id: string;
      };
      casinoFavoriteGames: {
        configs: { [key: number]: NewCasinoGame };
        _id: string;
      };
      casinoFavoriteProviders: {
        configs: { [key: number]: {} };
        _id: string;
      };
      favoriteMarkets: {
        configs: { [key: number]: {} };
        _id: string;
      };
      sportSBFavoriteGames: {
        configs: TPersonalizedSportGames;
        _id: string;
      };
      sportFavoriteTeams: {
        configs: { [key: number]: TTeamsDictionaryItem };
        _id: string;
      };
      tourSteps: {
        configs: TTourDataSteps;
      };
      sbGenericSettings: {
        configs: PersonalizationGenericSettings;
        _id: string;
      };
    };
  };
};

export type TPersonalizationPreparedData = {
  games: NewCasinoGame[];
  sports: number[];
  competitions: FavoriteCompetitionInfo[];
  providers: string[];
  markets: string[];
  sportGames: TPersonalizedSportGamesPrepared | null;
  teams: TTeamsDictionaryItem[];
  tourStepsInitialData: {
    [key: string]: TTourDataSteps;
  };
  personalizationGenericSettings: PersonalizationGenericSettings;
};

export const FavoritePersonalization = () => {
  const setFavoriteTeamsCommand = (teams: TTeamsDictionary) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_FAVORITE_TEAMS_RESOURCE,
        configs: teams
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedSportTeamsResourceId(response?.details?.data?._id)
          );
      },
      undefined,
      () => {}
    );
  };

  const storeHasUserSeenPopup = (value: boolean) => {
    const command = {
      command: CommandNames.SET_USER_DATA_PERSONALIZATION,
      params: {
        scope: SCOPE,
        resource: SHOW_POPUP_RESOURCE,
        configs: { 0: value }
      },
      rid: RidGenerator.gForCommand()
    };

    authCommand(command, () => {
      storeTourSteps(tourStepsData);
      store.dispatch(setTourStepsInitialData(tourStepsData));
    });
  };

  const getShowPopup = (callback: (res: any) => void) => {
    swarmCommand(
      CommandNames.GET_USER_DATA_PERSONALIZATION,
      { scope: SCOPE, resource: [SHOW_POPUP_RESOURCE, TOUR_STEPS] },
      (res: TPersonalizationResponse) => {
        callback(res);

        if (!res?.details?.data?.tourSteps) {
          storeTourSteps(tourStepsData);
          store.dispatch(setTourStepsInitialData(tourStepsData));
        }
      }
    );
  };

  const storeCasinoGames = (games: { [key: number]: NewCasinoGame }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: CASINO_GAMES_RESOURCE,
        configs: games
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedGameResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeCasinoProviders = (providers: { [key: string]: {} }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: CASINO_PROVIDERS_RESOURCE,
        configs: providers
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedProviderResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeMarketGroups = (markets: { [key: string]: {} }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_MARKET_GROUP_RESOURCE,
        configs: markets
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedMarketResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeSportGames = (sportGames: TPersonalizedSportGames) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_GAMES_RESOURCE,
        configs: sportGames
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedSportGamesResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeSports = (sports: Record<number, object>) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORTS_RESOURCE,
        configs: sports
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedSportsResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeSportCompetitions = (competitions: {
    [key: number]: FavoriteCompetitionInfo;
  }) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: SPORT_COMPETITIONS_RESOURCE,
        configs: competitions
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setPersonalizedCompetitionResourceId(response?.details?.data?._id)
          );
      }
    );
  };

  const storeTourSteps = (configs: TTourDataSteps) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: TOUR_STEPS,
        configs
      },
      (response: any) => {
        response?.details?.data?._id &&
          store.dispatch(
            setTourStepsInitialData(response?.details?.data?.configs || {})
          );
      }
    );
  };

  const storePersonalizedGenericSettings = (
    configs: PersonalizationGenericSettings
  ) => {
    swarmCommand(
      CommandNames.SET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: PERSONALIZATION_GENERIC_SETTINGS,
        configs: {
          ...store.getState().appData.personalizedGenericSettings,
          ...configs
        }
      },
      (response: any) => {
        const newData = deepMerge(
          { accountSettings: {} },
          response?.details?.data?.configs || {},
          { shouldMergeInFirst: false, applyEmptyArraysAndObjects: true }
        );

        response?.details?.data?._id &&
          store.dispatch(setPersonalizedGenericSettings(newData));
      }
    );
  };

  const prepareFavoritesInitialData = (
    callback: (preparedData: TPersonalizationPreparedData) => void,
    isGenericResources?: boolean
  ) => {
    swarmCommand(
      CommandNames.GET_USER_DATA_PERSONALIZATION,
      {
        scope: SCOPE,
        resource: isGenericResources
          ? [PERSONALIZATION_GENERIC_SETTINGS]
          : [
              CASINO_GAMES_RESOURCE,
              CASINO_PROVIDERS_RESOURCE,
              SPORT_COMPETITIONS_RESOURCE,
              SPORT_MARKET_GROUP_RESOURCE,
              SPORT_GAMES_RESOURCE,
              SPORTS_RESOURCE,
              TOUR_STEPS,
              SPORT_FAVORITE_TEAMS_RESOURCE
            ]
      },
      (res: TPersonalizationResponse) => {
        if (res === null) {
          callback({
            competitions: [],
            sports: [],
            games: [],
            providers: [],
            markets: [],
            sportGames: null,
            tourStepsInitialData: {},
            teams: [],
            personalizationGenericSettings: { accountSettings: {} }
          });
        }

        if (isGenericResources && !Object.keys(res).length) {
          callback({
            competitions: [],
            sports: [],
            games: [],
            providers: [],
            markets: [],
            sportGames: null,
            tourStepsInitialData: {},
            teams: [],
            personalizationGenericSettings: { accountSettings: {} }
          });
        }

        if (res?.details?.data) {
          const data = res.details.data;
          const preparedData: TPersonalizationPreparedData = {
            competitions: [],
            games: [],
            sports: [],
            providers: [],
            markets: [],
            sportGames: null,
            tourStepsInitialData: {},
            teams: [],
            personalizationGenericSettings: {}
          };

          if (data?.sportFavoriteSportList?.configs) {
            preparedData.sports = Object.keys(
              data.sportFavoriteSportList.configs
            ).map(k => +k);

            store.dispatch(
              setPersonalizedSportsResourceId(data.sportFavoriteSportList._id)
            );
          }

          if (data?.sportFavoriteCompetition?.configs) {
            for (const [key] of Object.entries(
              data.sportFavoriteCompetition.configs
            )) {
              preparedData.competitions.push(
                data.sportFavoriteCompetition.configs[+key]
              );
            }

            store.dispatch(
              setPersonalizedCompetitionResourceId(
                data.sportFavoriteCompetition._id
              )
            );
          }

          if (data?.sportFavoriteTeams?.configs) {
            for (const [key] of Object.entries(
              data.sportFavoriteTeams.configs
            )) {
              preparedData.teams.push(data.sportFavoriteTeams.configs[+key]);
            }

            store.dispatch(
              setPersonalizedSportTeamsResourceId(data.sportFavoriteTeams._id)
            );
          }

          if (data?.casinoFavoriteGames?.configs) {
            for (const [key] of Object.entries(
              data.casinoFavoriteGames.configs
            )) {
              preparedData.games.push(data.casinoFavoriteGames.configs[+key]);
            }

            store.dispatch(
              setPersonalizedGameResourceId(data.casinoFavoriteGames._id)
            );
          }

          if (data?.casinoFavoriteProviders?.configs) {
            for (const [key] of Object.entries(
              data.casinoFavoriteProviders.configs
            )) {
              preparedData.providers.push(key);
            }

            store.dispatch(
              setPersonalizedProviderResourceId(
                data.casinoFavoriteProviders._id
              )
            );
          }

          if (data?.favoriteMarkets?.configs) {
            preparedData.markets = Object.keys(data.favoriteMarkets.configs);

            store.dispatch(
              setPersonalizedMarketResourceId(data.favoriteMarkets._id)
            );
          }

          const unsignedFavSportGame: {
            groupKey: 'sportsbook' | 'esport';
            gameType: 'prematch' | 'live';
            id: number;
          } = JSON.parse(
            LocalStorage.getItem(
              storageKeyName('account', 'UNSIGNED_FAV_SPORT_GAME')
            )
          );

          LocalStorage.removeItem(
            storageKeyName('account', 'UNSIGNED_FAV_SPORT_GAME')
          );

          if (data?.sportSBFavoriteGames?.configs || unsignedFavSportGame) {
            const initaialState = {
              count: 0,
              data: {},
              ids: []
            };

            preparedData.sportGames = {
              sportsbook: {
                live: structuredClone(initaialState),
                prematch: structuredClone(initaialState)
              },
              esport: {
                live: structuredClone(initaialState),
                prematch: structuredClone(initaialState)
              },
              favoriteMatchesCountPeerCompetition: {}
            };

            if (data?.sportSBFavoriteGames?.configs) {
              preparedData.sportGames.sportsbook.live.ids = Object.keys(
                data.sportSBFavoriteGames.configs.sportsbook.live
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.sportsbook.live
                  ).map(id => +id)
                : [];

              preparedData.sportGames.sportsbook.prematch.ids = Object.keys(
                data.sportSBFavoriteGames.configs.sportsbook.prematch
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.sportsbook.prematch
                  ).map(id => +id)
                : [];

              preparedData.sportGames.esport.live.ids = Object.keys(
                data.sportSBFavoriteGames.configs.esport.live
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.esport.live
                  ).map(id => +id)
                : [];

              preparedData.sportGames.esport.prematch.ids = Object.keys(
                data.sportSBFavoriteGames.configs.esport.prematch
              ).length
                ? Object.keys(
                    data.sportSBFavoriteGames.configs.esport.prematch
                  ).map(id => +id)
                : [];
            }

            if (unsignedFavSportGame) {
              if (
                !preparedData.sportGames[unsignedFavSportGame.groupKey][
                  unsignedFavSportGame.gameType
                ].ids.includes(+unsignedFavSportGame.id)
              ) {
                preparedData.sportGames[unsignedFavSportGame.groupKey][
                  unsignedFavSportGame.gameType
                ].ids.push(+unsignedFavSportGame.id);
              }
            }

            data?.sportSBFavoriteGames?._id &&
              store.dispatch(
                setPersonalizedSportGamesResourceId(
                  data.sportSBFavoriteGames._id
                )
              );
          }

          if (!data?.tourSteps?.configs) {
            data.tourSteps = {
              configs: tourStepsData
            };
          }

          preparedData.tourStepsInitialData = data.tourSteps.configs;

          if (data?.sbGenericSettings?.configs) {
            const { timer, destinationUrl } =
              data.sbGenericSettings?.configs?.gamblingAreaPopup || {};

            const seconds = dayjs(Date.now()).diff(dayjs(timer), 'seconds');

            if (
              timer &&
              destinationUrl?.length &&
              seconds < SECONDS_PER_MINUTE &&
              decodeURI(window.location.href).startsWith(destinationUrl)
            ) {
              window.postMessage(
                {
                  timer,
                  destinationUrl,
                  action: 'gambling_area_changed'
                },
                '*'
              );
            }

            preparedData.personalizationGenericSettings =
              data?.sbGenericSettings?.configs || {};
          }

          callback(preparedData);
        } else {
          store.dispatch(setPersonalisedDataLoaded(true));
        }
      }
    );
  };

  const clearResource = (id: string) => {
    swarmCommand(
      CommandNames.DELETE_USER_RESOURCES_PERSONALIZATION,
      {
        _id: id
      },
      () => {}
    );
  };

  const clearDataFromStorage = () => {
    store.dispatch(
      setPersonalizationData({
        casinoGames: [],
        sports: [],
        competitions: [],
        casinoProviders: [],
        categories: []
      })
    );
    store.dispatch(setFavoriteCompetitions([]));
    store.dispatch(setSportTeams([]));
    store.dispatch(setFavoriteCasinoGames([]));
    store.dispatch(setFavoriteCasinoProviders([]));
    store.dispatch(setFavoriteSports([]));
    store.dispatch(setFavoriteMarkets([]));
    store.dispatch(
      setFavoriteSportsData({
        esport: {
          live: { ids: [], data: {}, count: 0 },
          prematch: { ids: [], data: {}, count: 0 }
        },
        sportsbook: {
          live: { ids: [], data: {}, count: 0 },
          prematch: { ids: [], data: {}, count: 0 }
        },
        favoriteMatchesCountPeerCompetition: {}
      })
    );
  };

  return {
    getShowPopup: getShowPopup,
    storeHasUserSeenPopup: storeHasUserSeenPopup,
    storeCasinoProviders: storeCasinoProviders,
    storeSportCompetitions: storeSportCompetitions,
    storeMarketGroups: storeMarketGroups,
    storeSportGames: storeSportGames,
    storeSports: storeSports,
    storeCasinoGames: storeCasinoGames,
    storeTourSteps: storeTourSteps,
    prepareFavoritesInitialData: prepareFavoritesInitialData,
    clearResource: clearResource,
    clearDataFromStorage: clearDataFromStorage,
    setFavoriteTeamsCommand: setFavoriteTeamsCommand,
    storePersonalizedGenericSettings: storePersonalizedGenericSettings
  };
};
