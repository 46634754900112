import SessionStorage from 'utils/bom-dom-manipulation/session-storage';
import { deepMerge } from 'utils/collection-manipulation/deep-merge';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { ActionType } from 'interfaces/generic';
import {
  ERacingResultsTimeFilterTabs,
  ERacingTabsTimeFilters,
  TRacingResultsGame,
  TRacingResultsRegion
} from 'interfaces/racing';
import {
  EachWayPartnerTerms,
  IRacingGameHorseAdditionalData,
  RacingGame,
  RacingRegion,
  TSwarmRacingGame
} from 'interfaces/sportsbook-data-levels';
import { RacingDataActionTypes } from 'store/action-types';

const dateFilter = SessionStorage.getItem(
  storageKeyName('racing', 'SPORTSBOOK_DATE_AND_SPORT_FILTER_ACTIVE_TAB')
);

const resultsDateFilter = SessionStorage.getItem(
  storageKeyName('racing', 'RESULTS_DATE_FILTER_ACTIVE_TAB')
);

type State = {
  upcomingData: RacingGame[];
  horseGamesAdditionalData: Record<
    number,
    IRacingGameHorseAdditionalData | undefined | null
  >;
  competitionData: Record<string, RacingRegion>;
  gameData: {};
  storedGameIds: number[];
  eachWayTerms: Record<string, EachWayPartnerTerms>;
  dateAndSportFilterActiveTab: string;
  isCompetitionsLoading: boolean;
  isGamesLoading: boolean;
  isUpcomingRacesLoading: boolean;
  gameFromActiveTab: string;
  isCheckGameStartTs: boolean;
  isCheckCompetition: boolean;
  resultsTimeFilterActiveTab: ERacingResultsTimeFilterTabs;
  resultsCompetitionData: Record<
    ERacingResultsTimeFilterTabs,
    TRacingResultsRegion[]
  >;
  isResultsCompetitionDataLoading: boolean;
  resultsSingleGameData: Record<number, TRacingResultsGame>;
};

const initialData: State = {
  upcomingData: [],
  horseGamesAdditionalData: {},
  competitionData: {},
  gameData: {},
  storedGameIds: [],
  eachWayTerms: {},
  dateAndSportFilterActiveTab:
    dateFilter !== 'null' ? dateFilter : ERacingTabsTimeFilters.TODAY,
  isCompetitionsLoading: true,
  isGamesLoading: true,
  isUpcomingRacesLoading: true,
  gameFromActiveTab: '',
  isCheckGameStartTs: true,
  isCheckCompetition: true,
  resultsTimeFilterActiveTab: (resultsDateFilter !== 'null'
    ? resultsDateFilter
    : ERacingResultsTimeFilterTabs.YESTERDAY) as ERacingResultsTimeFilterTabs,
  resultsCompetitionData: {
    [ERacingResultsTimeFilterTabs.YESTERDAY]: [],
    [ERacingResultsTimeFilterTabs.TODAY]: []
  },
  isResultsCompetitionDataLoading: true,
  resultsSingleGameData: {}
};

export const raceData = (state = initialData, action: ActionType): State => {
  switch (action.type) {
    case RacingDataActionTypes.SET_UPCOMING_DATA: {
      return {
        ...state,
        upcomingData: action.payload.swarmData
      };
    }

    case RacingDataActionTypes.REMOVE_UPCOMING_ALL_DATA: {
      return {
        ...state,
        upcomingData: []
      };
    }

    case RacingDataActionTypes.SET_HORSE_GAME_ADDITIONAL_DATA: {
      const horseGamesAdditionalData = { ...state.horseGamesAdditionalData };
      horseGamesAdditionalData[action.payload.id] =
        action.payload.horseGameAdditionalData;

      return {
        ...state,
        horseGamesAdditionalData: horseGamesAdditionalData
      };
    }

    case RacingDataActionTypes.REMOVE_HORSE_GAME_ADDITIONAL_DATA: {
      const horseGamesAdditionalData = { ...state.horseGamesAdditionalData };

      delete horseGamesAdditionalData[action.payload.id];

      return {
        ...state,
        horseGamesAdditionalData: { ...horseGamesAdditionalData }
      };
    }

    case RacingDataActionTypes.REMOVE_HORSE_GAMES_ADDITIONAL_DATA: {
      return {
        ...state,
        horseGamesAdditionalData: {}
      };
    }

    case RacingDataActionTypes.SET_RACING_COMPETITION_DATA: {
      return {
        ...state,
        competitionData: { ...action.payload.data }
      };
    }

    case RacingDataActionTypes.REMOVE_RACING_COMPETITION_DATA: {
      return {
        ...state,
        competitionData: {}
      };
    }

    case RacingDataActionTypes.SET_GAME: {
      const swarmData: TSwarmRacingGame | null = action.payload.swarmData;

      const gameData = !swarmData
        ? {}
        : deepMerge(state.gameData, swarmData.game, {
            shouldMergeInFirst: false,
            applyEmptyArraysAndObjects: true
          });

      return {
        ...state,
        gameData
      };
    }

    case RacingDataActionTypes.REMOVE_GAME: {
      return {
        ...state,
        gameData: {}
      };
    }

    case RacingDataActionTypes.SET_STORED_GAME_ID: {
      const ids = [...state.storedGameIds];

      return {
        ...state,
        storedGameIds: [...new Set([...ids, action.payload.id])] /*[...]*/
      };
    }

    case RacingDataActionTypes.REMOVE_STORED_GAME_IDS: {
      return {
        ...state,
        storedGameIds: []
      };
    }

    case RacingDataActionTypes.SET_EACH_WAY_PARTNER_TERMS: {
      return {
        ...state,
        eachWayTerms: {
          ...state.eachWayTerms,
          [action.payload.marketId]: action.payload.manualEachWayData
        }
      };
    }

    case RacingDataActionTypes.SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB: {
      return {
        ...state,
        dateAndSportFilterActiveTab: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_COMPETITIONS_LOADING: {
      return {
        ...state,
        isCompetitionsLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_UPCOMING_RACES_LOADING: {
      return {
        ...state,
        isUpcomingRacesLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_GAME_FROM_ACTIVE_TAB: {
      return {
        ...state,
        gameFromActiveTab: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_CHECK_GAME_START_TS: {
      return {
        ...state,
        isCheckGameStartTs: action.payload
      };
    }

    case RacingDataActionTypes.SET_IS_CHECK_COMPETITION: {
      return {
        ...state,
        isCheckCompetition: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_TIME_FILTER_ACTIVE_TAB: {
      return {
        ...state,
        resultsTimeFilterActiveTab: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_COMPETITIONS_DATA: {
      const data: Record<ERacingResultsTimeFilterTabs, TRacingResultsRegion[]> =
        state.resultsCompetitionData;

      data[action.payload.timeFilter as ERacingResultsTimeFilterTabs] =
        action.payload.resultsCompetitionData;

      return {
        ...state,
        resultsCompetitionData: { ...data }
      };
    }

    case RacingDataActionTypes.REMOVE_RESULTS_COMPETITIONS_DATA: {
      return {
        ...state,
        resultsCompetitionData: {
          [ERacingResultsTimeFilterTabs.YESTERDAY]: [],
          [ERacingResultsTimeFilterTabs.TODAY]: []
        }
      };
    }

    case RacingDataActionTypes.SET_IS_RESULTS_COMPETITION_DATA_LOADING: {
      return {
        ...state,
        isResultsCompetitionDataLoading: action.payload
      };
    }

    case RacingDataActionTypes.SET_RESULTS_GAME_DATA: {
      let data: Record<number, TRacingResultsGame> = {};

      const newData = { ...state.resultsSingleGameData };
      newData[action.payload.data.id] = action.payload.data;
      data = newData;

      return {
        ...state,
        resultsSingleGameData: data
      };
    }

    case RacingDataActionTypes.REMOVE_RESULTS_GAMES_DATA: {
      return {
        ...state,
        resultsSingleGameData: {}
      };
    }

    default: {
      return state;
    }
  }
};
