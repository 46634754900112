import { useMemo } from 'react';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { isMobile } from 'utils/is-mobile';

const HEADER_ROWS_HEIGHT = 114;

export const useContentHeight = (
  considerTabs = true,
  extra?: number
): string => {
  return useMemo(() => {
    const headerRows = document.getElementsByClassName('header-rows')[0];

    if (SpringConfigs.MOCKED_DATA) {
      return `100%`;
    }

    return `calc(100vh - env(safe-area-inset-bottom)${
      isMobile() && SpringConfigs.BOTTOM_NAVIGATION ? ' - 75px' : ''
    }${considerTabs ? ' - 42px' : ''} - ${
      headerRows
        ? headerRows?.getBoundingClientRect().height
        : HEADER_ROWS_HEIGHT
    }px - ${extra || 0}px)`;
  }, [extra]);
};
