import { FC } from 'react';
import { NoItemWrapper } from './style';
import { NoDataIconWrapper } from 'newcomponents/UI/NoDataIconWrapper';
import './index.less';

type TNoItem = {
  title: string;
  text?: string;
  size?: 'large' | 'medium';
  icon?: string;
  className?: string;
  applyEmptyBoxStyles?: boolean;
};

export const NoItem: FC<TNoItem> = ({
  title,
  text,
  size = 'large',
  icon,
  className,
  applyEmptyBoxStyles
}) => {
  return (
    <NoItemWrapper
      className={`${size} ${className ?? ''}`}
      style={{ justifyContent: 'center' }}
    >
      <NoDataIconWrapper
        icon={icon}
        applyEmptyBoxStyles={applyEmptyBoxStyles}
      />
      <figcaption>
        <h3 className="no-item-title">{title}</h3>
        {text && <p className="no-item__text">{text}</p>}
      </figcaption>
    </NoItemWrapper>
  );
};
