import { ActionType } from 'interfaces/generic';
import { BetslipActionTypes } from 'store/action-types';

// QuickBet
export const updateQuickBetStake = (payload: number): ActionType => {
  return {
    type: BetslipActionTypes.UPDATE_QUICK_BET_STAKE,
    payload
  };
};

export const toggleQuickBetInStore = (payload?: boolean): ActionType => {
  return {
    type: BetslipActionTypes.TOGGLE_QUICK_BET,
    payload
  };
};

export const toggleQuickBetPopup = (): ActionType => {
  return {
    type: BetslipActionTypes.TOGGLE_QUICK_BET_POPUP
  };
};

export const updateQuickBetInProcessCount = (
  payload: 'increase' | 'decrease'
): ActionType => {
  return {
    type: BetslipActionTypes.UPDATE_QUICK_BET_IN_PROCESS_COUNT,
    payload
  };
};

export const setQuickBetSelectedEventId = (payload: string): ActionType => {
  return {
    type: BetslipActionTypes.SET_QUICK_BET_SELECTED_EVENT_ID,
    payload
  };
};

// BookBet
export const setBookBetId = (payload: string | null): ActionType => {
  return {
    type: BetslipActionTypes.SET_BOOK_BET_ID,
    payload
  };
};

export const setUseBonusBalance = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_USE_BALANCE_BONUS,
    payload
  };
};

export const setIsBookingMode = (payload: boolean): ActionType => {
  return {
    type: BetslipActionTypes.SET_IS_BOOKING_MODE,
    payload
  };
};
