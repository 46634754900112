import dayjs from 'dayjs';
import qs from 'qs';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { Event as WebSocketEvent } from 'reconnecting-websocket/events';
import { modifyAndSetTaxDataOnRedux } from 'utils/betslip/modify-and-set-tax-data-on-redux';
import LocalStorage from 'utils/bom-dom-manipulation/local-storage';
import { arrayFindBy } from 'utils/collection-manipulation/array-find-by';
import RidConstants from 'utils/constants/swarm/rid-prefix-constants';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import {
  CommandNames,
  CommandSource
} from 'utils/constants/swarm/swarm-command-names';
import { SwarmSuccessMessages } from 'utils/constants/swarm/swarm-success-messages';
import {
  getUniqueIdentification,
  getUserUniqueIdentification,
  userPcInfo
} from 'utils/fingerprint-actions/afec-actions';
import { showToastError } from 'utils/generic/show-toast-error';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { isMobile } from 'utils/is-mobile';
import RidGenerator from 'utils/swarm/rid-generator';
import { showSwarmError } from 'utils/swarm/swarm-error-handler';
import msgChecker from 'utils/swarm/swarm-msg-checker';
import { showToastSuccess } from 'utils/swarm/swarm-success-handler';
import {
  RegisteredCommand,
  WebsocketEnhanced
} from 'interfaces/spring-websocket-interfaces';
import { appendScript } from 'services/turnstile/append-script';
import { TurnstileCaptcha } from './turnstile-captcha';
import Store from 'store';
import {
  setGeoLocationShakeData,
  setRecaptchaDetails,
  setSID,
  setTurnstileDetails
} from 'store/actions/app-data';
import { setIsConnected, setPartnerConfigs } from 'store/actions/socket';

const GEO_LOCATION_DATA_ID = 9999999999;
const SOCKET_RECONNECT_MAX_TIME = 60000;

function SpringConnector(): Promise<WebsocketEnhanced> {
  return new Promise((resolve: (value: WebsocketEnhanced) => void) => {
    // websocket connection url. must be retrieved from widget settings
    const swarmUrl = SpringConfigs.SWARM_URL;

    // ws object
    const socket: WebsocketEnhanced = new ReconnectingWebSocket(swarmUrl, [], {
      reconnectionDelayGrowFactor: 1
    }) as WebsocketEnhanced;

    // parameter for swarm setting, must be retrieved from widget settings
    const languagePrefix =
      window.currentLanguageObject?.swarmLangPrefix ||
      SpringConfigs.LANGUAGE_PREFIX ||
      'eng';

    // partner ID, must be retrieved from widget settings
    const siteId = SpringConfigs.PARTNER_ID;
    // commands that were called from components
    let registeredCommands: RegisteredCommand[] = [];

    let isOpen = false;

    /**
     * Processes request session after socket is opened and updates ready state
     */
    const _socketOpen = () => {
      if (socket.readyState !== ReconnectingWebSocket.OPEN) {
        return;
      }

      isOpen = true;

      _requestSession();
    };

    /**
     * Socket closed
     */
    const _socketClose = () => {
      Store.dispatch(setIsConnected(false));
      console.warn('WebSocket closed');
    };

    /**
     * Socket Error
     */
    const _socketError = (e: WebSocketEvent) => {
      console.warn('ws Error :: ', e);
    };

    /**
     * Get swarm message and process it
     */
    const _socketMessage = (msgEvent: MessageEvent): void => {
      const msg: {
        rid: string;
        data: any;
        code: number;
        msg: string;
      } = JSON.parse(msgEvent.data);

      const { rid, data, code } = msg;

      if (msgChecker.verifyErrorExists(msg)) {
        console.info('Error from Swarm');

        const [commandObj] = arrayFindBy(registeredCommands, 'rid', rid, true);

        if (commandObj instanceof Object) {
          const { callbackError, messageContext } = commandObj;
          callbackError?.(msg);
          !msgChecker.skipErrorMsg(commandObj.command, code, data?.result) &&
            showSwarmError(data, msg.msg, messageContext);
        }

        // pass error to one time command
        if (msgChecker.verifyCommand(msg)) {
          const { rid } = msg;
          const [commandObj, commandObjIndex] = arrayFindBy(
            registeredCommands,
            'rid',
            rid,
            true
          );

          if (commandObj) {
            registeredCommands.splice(commandObjIndex, 1);

            // if (typeof commandObj.error === 'function') {
            //   commandObj.error(msg.data, msg);
            // }
          }
        }

        return;
      }

      // check request session
      if (msgChecker.verifyRequestSession(msg)) {
        resolve(socket);
        _getPartnerConfig();
        registeredCommands.forEach(
          ({ rid, callback, callbackUpdate, command }) => {
            sendCommand(command, rid, callback, callbackUpdate, () => {}, true);
          }
        );

        Store.dispatch(setIsConnected(true));
        Store.dispatch(setSID(msg?.data?.sid));
        Store.dispatch(
          setRecaptchaDetails({
            recaptchaEnabled: !!msg?.data?.recaptcha_enabled,
            recaptchaVersion: msg?.data?.recaptcha_version || 2,
            siteKey: msg?.data?.site_key
          })
        );
        Store.dispatch(
          setTurnstileDetails({
            turnstileEnabled: !!msg?.data?.turnstile_enabled,
            turnstileSiteKey: msg?.data?.turnstile_site_key || '',
            turnstileVerifyActions: msg?.data?.turnstile_verify_actions || []
          })
        );

        //append cloudflare turnstile sdn script
        if (msg?.data?.turnstile_enabled) {
          appendScript();
        }

        return;
      }

      // check GeoLocation
      if (msgChecker.verifyGeoLocation(msg)) {
        Store.dispatch(
          setGeoLocationShakeData(
            msg.data[GEO_LOCATION_DATA_ID]?.data?.notification_type
          )
        );

        return;
      }

      // process one time command
      if (msgChecker.verifyCommand(msg)) {
        const [commandObj, commandObjIndex] = arrayFindBy(
          registeredCommands,
          'rid',
          rid,
          true
        );

        if (commandObj) {
          registeredCommands.splice(commandObjIndex, 1);
          const { callback } = commandObj || {};

          if (!callback || typeof callback !== 'function') {
            return;
          }

          if (commandObj.successMessage) {
            showToastSuccess(
              commandObj.successMessage,
              commandObj.messageContext
            );
          }

          callback(data);
        }

        return;
      }

      // first data after subscription
      if (msgChecker.verifySubscribe(msg) && msg.data && msg.data.data) {
        springListener(msg.data.data, msg.data.subid, msg.rid, 'first');

        return;
      }

      //  Update subscription
      if (msgChecker.verifySubscriptionUpdate(msg)) {
        // const subId = Object.keys(msg.data)[0];
        Object.keys(msg.data).forEach((subId: string) => {
          springListener(msg.data[subId], subId, '0', 'update');
        });

        return;
      }
    };

    /**
     * Catches socket messages
     * @param  {Object} data
     * @param  {String} subId subscription id
     * @param  {String} rid   request id
     * @param  {String} type  received data type ('first' || 'update')
     */
    const springListener = (
      data: Object,
      subId: string,
      rid: string,
      type: string
    ) => {
      if (type === 'first') {
        subscriptionFirstData(data, subId, rid);
      } else if (type === 'update') {
        subscriptionUpdatedData(data, subId);
      }
    };

    /**
     * Processes first message after subscription and calls callback provided from component
     */
    const subscriptionFirstData = (
      data: Object,
      subid: string,
      rid: string
    ) => {
      const [command, commandIndex] = arrayFindBy(
        registeredCommands,
        'rid',
        rid,
        true
      );

      const { callback } = command || {};

      if (!callback || typeof callback !== 'function') {
        return;
      }

      registeredCommands[commandIndex]['subId'] = subid;

      callback(data);
    };

    /**
     * Processes update message after subscription and calls callback provided from component
     */
    const subscriptionUpdatedData = (data: Object, subid: string) => {
      const [command] = arrayFindBy(registeredCommands, 'subId', subid, true);

      const { callbackUpdate } = command || {};

      if (!callbackUpdate || typeof callbackUpdate !== 'function') {
        return;
      }

      callbackUpdate(data);
    };

    const _requestUserIdentificationToken = (userData: object) => {
      setTimeout(() => {
        sendCommand(
          {
            command: CommandNames.STORE_USER_IDENTIFICATION_TOKEN,
            params: { identification_info: userData },
            rid: RidGenerator.gForCommand()
          },
          '',
          () => undefined,
          null,
          null,
          true
        );
      }, 0);
    };

    /**
     * Makes call to swarm for requesting session
     */
    const _requestSession = async () => {
      if (SpringConfigs.MOCKED_DATA) {
        const requestSessionCommand = JSON.stringify(_requestSessionCommand());

        socket.send(requestSessionCommand);
      } else {
        const userIdentifier = await getUniqueIdentification();

        const requestSessionCommand = _requestSessionCommand(userIdentifier);

        sendCommand(requestSessionCommand, '', null, null, null, true);
        let userData: any = await getUserUniqueIdentification();

        userData = { ...userData, local_ip: userPcInfo.localIp };
        userData && _requestUserIdentificationToken(userData);
      }
    };

    /**
     * Makes call to swarm for partner configs
     */
    const _getPartnerConfig = () => {
      sendCommand(_partnerConfigCommand(), '', (data: any) => {
        const partnerConfigs = data?.data?.partner[siteId];

        if (partnerConfigs) {
          Store.dispatch(
            setPartnerConfigs({ ...partnerConfigs })
            // setPartnerConfigs({ ...partnerConfigs, is_bonus_bet_taxed: true })
          );
          modifyAndSetTaxDataOnRedux(partnerConfigs);
          LocalStorage.setItem(
            storageKeyName('account', 'LOYALTY_POINTS_AVAILABLE'),
            JSON.stringify(data.data.partner[siteId]?.is_using_loyalty_program)
          );
        } else {
          showToastError('Partner Configs Not Retrieved');
        }
      });
    };

    /**
     * Function that gathers and returns command object
     * @return command object of request_session for swarm
     */
    const _requestSessionCommand = (fingerprint?: string) => {
      const params: Record<string, null | number | string | boolean> = {
        language: languagePrefix,
        site_id: siteId,
        source: SpringConfigs.SOURCE,
        is_wrap_app: SpringConfigs.WRAPPER_APP
      };

      const tid = Number(
        qs.parse(window.location.search, { ignoreQueryPrefix: true }).tid
      );

      if (tid) {
        params.terminal = tid;
      }

      if (fingerprint) {
        params.afec = fingerprint;
      }

      return {
        command: CommandNames.REQUEST_SESSION,
        params: params,
        rid: RidGenerator.gForRequestSession()
      };
    };

    /**
     * Function that gathers and returns command object
     * @return command object of partner.config for swarm
     */
    const _partnerConfigCommand = () => {
      return {
        command: CommandNames.GET,
        params: {
          source: CommandSource.PARTNER_CONFIG,
          what: {
            partner: []
          }
        },
        rid: RidGenerator.gForCommand()
      };
    };

    /** Sends swarm command for component
     * @param {Object}   commandObj               actual command structure which should be sent to swarm
     * @param {String}   componentRid             rid generated in component for storing in registeredCommands
     * @param {Function} componentCallback        callback function provided by component that will be called after getting message
     * @param {Function} componentCallbackUpdate  callback function provided by component that will be called on data update message
     * @param {Function} componentCallbackError   callback function provided by component that will be called on swarm error message
     * */
    const sendCommand = async (
      commandObj: any,
      componentRid: string | null = '',
      componentCallback: Function | null = null,
      componentCallbackUpdate: Function | null = null,
      componentCallbackError: Function | null = null,
      reConnect?: boolean
    ) => {
      if (SpringConfigs.MOCKED_DATA) {
        import(
          /* webpackChunkName: "mocked-data" */ 'utils/mocked-data/mock-helper'
        ).then(mockHelper => {
          componentCallback?.(
            mockHelper.getMockedData(commandObj.params, commandObj.command)
          );
        });

        return;
      }

      const turnstileVerifyActions =
        Store.getState().appData.turnstileVerifyActions || [];

      if (turnstileVerifyActions.includes(commandObj.command)) {
        try {
          await TurnstileCaptcha(commandObj.command);
        } catch (e) {
          console.error(e);
          componentCallbackError && componentCallbackError({ code: e });

          return;
        }
      }

      if (commandObj.rid && commandObj.rid !== '') {
        componentRid = commandObj.rid;
      }

      const { successMessage, messageContext } = commandObj;

      delete commandObj.successMessage;
      delete commandObj.messageContext;
      const commandObjJSON = JSON.stringify(commandObj);

      if (!reConnect) {
        registerCommand(
          componentRid as string,
          (data: any) => {
            componentCallback?.(data);

            /* ATTENTION this is needed to check mock data,
                             uncomment to find out missing mock data with SpringConfigs.MOCK_DATA set to false */

            // import(
            //   /* webpackChunkName: "mocked-data" */ 'utils/mocked-data/mock-helper'
            // ).then(mockHelper => {
            //   if (
            //     !mockHelper.getMockedData(commandObj.params, commandObj.command)
            //   ) {
            //     console.log(componentRid);
            //     console.log(JSON.stringify(data));
            //     console.log(
            //       mockHelper.getMockedData(
            //         commandObj.params,
            //         commandObj.command,
            //         true
            //       )
            //     );
            //   }
            // });
          },
          componentCallbackUpdate,
          componentCallbackError,
          commandObj,
          successMessage,
          messageContext
        );
      }

      const isConnected = Store.getState().socket.isConnected;

      if (
        commandObjJSON &&
        (isConnected ||
          commandObj.command === RidConstants.REQUEST_SESSION ||
          reConnect)
      ) {
        socket.send(commandObjJSON);
      }
    };

    /**
     * Sends unsubscribe command to swarm
     * @param commandRid - rid generated in component
     */
    const unsubscribe = (commandRid: string) => {
      const subId = unregisterCommand(commandRid);

      if (!subId) {
        return;
      }

      const command = {
        command: CommandNames.UNSUBSCRIBE,
        params: {
          subid: subId
        },
        rid: RidGenerator.gForUnsubscribe()
      };

      socket.send(JSON.stringify(command));
    };

    /**
     * Register command called from component
     * @param {String}      commandRid               rid generated in component for storing in registeredCommands
     * @param {Function}    componentCallback        callback function provided by component that will be called after getting message
     * @param {Function}    componentCallbackUpdate  callback function provided by component that will be called on data update message
     * @param {Function}    componentCallbackError   callback function provided by component that will be called on error message
     * @param {Object}      commandObj               command object that is structured for swarm calls
     * @param {Object}      successMessage           success messages enumeration that will be shown after successful response from swarm
     * @param {HTMLElement} messageContext           context (container) for displayed message to be shown in
     */
    const registerCommand = (
      commandRid: string,
      componentCallback: Function | null,
      componentCallbackUpdate: Function | null,
      componentCallbackError: Function | null,
      commandObj: any,
      successMessage: SwarmSuccessMessages,
      messageContext: HTMLElement | undefined | null
    ) => {
      /**
       * for reconnecting purpose we check rid first and if we do not have it so
       * it means this subscription is not a old one and we should push it to registeredCommands
       */

      if (registeredCommands.findIndex(i => i.rid === commandRid) < 0) {
        registeredCommands.push({
          successMessage,
          messageContext,
          rid: commandRid,
          callback: componentCallback,
          callbackUpdate: componentCallbackUpdate,
          callbackError: componentCallbackError,
          command: commandObj
        });
      }

      return true;
    };

    /**
     * Unregister command found by its unique id from registered commands
     * @param  {String}  commandRid - unique id generated inside component
     * @return {String}  returns subscription id stored in registered command for processing unsubscribe call.
     *                   Empty string is returned if command is not found or rid was empty
     */
    const unregisterCommand = (commandRid = '') => {
      if (commandRid.length > 0) {
        const registeredCommandsCopy = [...registeredCommands];
        const [command, commandIndex] = arrayFindBy(
          registeredCommands,
          'rid',
          commandRid,
          true
        );

        if (commandIndex > -1) {
          registeredCommandsCopy.splice(commandIndex, 1);
          registeredCommands = registeredCommandsCopy;

          return command.subId;
        } else {
          return '';
        }
      } else {
        return '';
      }
    };

    const functionOnOnline = () => {
      socket.reconnect();
      const elem = document.getElementById('offline_cover');

      if (elem) {
        elem.remove();
      }
    };

    const unsubscribeAll = (commandRids: Array<string>) => {
      const subIds: Array<string> = [];

      commandRids.forEach((item: string) => {
        const id = unregisterCommand(item);
        id && !subIds.find(subId => subId === item) && subIds.push(id);
      });

      if (!subIds.length) {
        return;
      }

      const uniqueIds = new Set(subIds);

      const command = {
        command: CommandNames.UNSUBSCRIBE_BULK,
        params: {
          subids: Array.from(uniqueIds)
        },
        rid: RidGenerator.gForUnsubscribe()
      };

      socket.send(JSON.stringify(command));
    };

    const functionOnOffline = () => {
      socket.close();
      /**
       * @ATTENTION after we have a design for offline mode, we can use redux to show/hide offline component
       */
      const node = document.createElement('div');
      node.setAttribute('id', 'offline_cover');
      node.style.backgroundColor = 'rgba(0,0,0,0.4)';
      node.style.width = '100%';
      node.style.height = '100%';
      node.style.zIndex = '100';
      node.style.position = 'fixed';
      node.style.top = '0';
      document.body.appendChild(node);
    };

    const socketReconnect = () => {
      socket.reconnect();
    };

    if (isMobile()) {
      let timeStart: null | number = null;
      document.addEventListener(
        'visibilitychange',
        () => {
          if (!document.hidden) {
            if (
              !isOpen &&
              timeStart &&
              dayjs().valueOf() - timeStart > SOCKET_RECONNECT_MAX_TIME
            ) {
              socket.reconnect();
            }
          } else {
            timeStart = dayjs().valueOf();
            isOpen = false;
          }
        },
        false
      );
    }

    if (socket) {
      socket.addEventListener('open', _socketOpen);
      socket.addEventListener('close', _socketClose);
      socket.addEventListener('message', _socketMessage);
      socket.addEventListener('error', _socketError);
      socket.sendCommand = sendCommand;
      socket.unsubscribe = unsubscribe;
      socket.unsubscribeAll = unsubscribeAll;
      socket.socketReconnect = socketReconnect;

      window.addEventListener('online', functionOnOnline, false);
      window.addEventListener('offline', functionOnOffline, false);
      window.addEventListener('reconnect', socketReconnect, false);
    }
  });
}

export default SpringConnector;
