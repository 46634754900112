import { createSelector } from 'reselect';
import { getSelectorRoot } from 'utils/selectors';
import { applyMarketsCountToGame } from 'utils/sportsbook/favorites/fav-sorter';
import { NewCasinoGame } from 'interfaces/new-casino';
import {
  Competition,
  Game,
  IToggleCasinoGameAction,
  IToggleCompetitionAction,
  IToggleMarketAction,
  IToggleSportMatchAction,
  IToggleSportsAction,
  Market,
  Sport
} from 'interfaces/sportsbook-data-levels';

const getFavData = getSelectorRoot('favData');

const getMarketsCount = getFavData('marketsCount');

export const getFavoriteDataLoading = getFavData('dataLoading');
export const getFavoriteMatchIdsLoading = getFavData('idsLoading');

const getFavoriteEsport = getFavData('esport');
export const getFavoriteCasinoGames = getFavData('casino');
export const getFavoriteMatchesCountPeerCompetition = getFavData(
  'favoriteMatchesCountPeerCompetition'
);
const getFavoriteSportsbook = getFavData('sportsbook');
export const getFavoriteMarketGroupNames = getFavData('markets');
export const getFavoriteSportIds = getFavData('sports');
export const getFavoriteCompetitions = getFavData('competitions');
export const getFavoriteSportTeams = getFavData('teams');
export const getFavoriteProviders = getFavData('providers');

export const getFavoriteMatches = createSelector(
  [getFavoriteSportsbook, getFavoriteEsport, getMarketsCount],
  (_sportsbook, _esport, marketsCount) => {
    const sportsbook = {
      live: {
        ..._sportsbook.live,
        data: applyMarketsCountToGame(_sportsbook.live.data, marketsCount)
      },
      prematch: {
        ..._sportsbook.prematch,
        data: applyMarketsCountToGame(_sportsbook.prematch.data, marketsCount)
      }
    };

    const esport = {
      live: {
        ..._esport.live,
        data: applyMarketsCountToGame(_esport.live.data, marketsCount)
      },
      prematch: {
        ..._esport.prematch,
        data: applyMarketsCountToGame(_esport.prematch.data, marketsCount)
      }
    };

    const liveSports = {
      ...sportsbook.live.data,
      ...esport.live.data
    };

    const prematchSports = {
      ...sportsbook.prematch.data,
      ...esport.prematch.data
    };

    const all = { ...liveSports, ...prematchSports };

    return { all, esport, sportsbook, liveSports, prematchSports };
  }
);

export const getFavoriteMatchIds = createSelector(
  [getFavoriteEsport, getFavoriteSportsbook],
  (esport, sportsbook) => {
    const esportAllGameIds = [...esport.live.ids, ...esport.prematch.ids];
    const sportsbookAllGameIds = [
      ...sportsbook.live.ids,
      ...sportsbook.prematch.ids
    ];

    return {
      esport: {
        live: esport.live.ids,
        prematch: esport.prematch.ids,
        all: esportAllGameIds
      },
      sportsbook: {
        live: sportsbook.live.ids,
        prematch: sportsbook.prematch.ids,
        all: sportsbookAllGameIds
      },
      liveSports: [...esport.live.ids, ...sportsbook.live.ids],
      prematchSports: [...esport.prematch.ids, ...sportsbook.prematch.ids],
      all: [...sportsbookAllGameIds, ...esportAllGameIds]
    };
  }
);

export const getFavoriteEntitiesCount = createSelector(
  [
    getFavoriteEsport,
    getFavoriteSportsbook,
    getFavoriteCasinoGames,
    getFavoriteProviders,
    getFavoriteCompetitions
  ],
  (_esport, _sports, _casino, _providers, _competitions) => {
    const casino = _casino?.length || 0;
    const providers = _providers?.length;
    const competitions = _competitions.length;
    const esport = _esport.live.count + _esport.prematch.count;
    const sportsbook = _sports.live.count + _sports.prematch.count;

    return {
      esport,
      casino: casino ?? 0,
      providers: providers ?? 0,
      sportsbook,
      competitions,
      sports: sportsbook + esport,
      total: esport + sportsbook + casino + competitions,
      sportsWithCompetitions: sportsbook + esport + competitions,
      liveSports: _esport.live.count + _sports.live.count,
      prematchSports: _esport.prematch.count + _sports.prematch.count,
      onlyLiveSports: {
        ids: [..._esport.live.ids, ..._sports.live.ids],
        count: _esport.live.count + _sports.live.count
      }
    };
  }
);

export const getFavoriteActiveStateById = (
  ...args:
    | [Game['id'], IToggleSportMatchAction['groupKey']]
    | [Sport['id'], IToggleSportsAction['groupKey']]
    | [Market['group_name'], IToggleMarketAction['groupKey']]
    | [Competition['id'], IToggleCompetitionAction['groupKey']]
    | [NewCasinoGame['id'], IToggleCasinoGameAction['groupKey']]
) => {
  const [id, type] = args;

  switch (type) {
    case 'sports':
      return createSelector([getFavoriteSportIds], sportIds =>
        sportIds.includes(+id)
      );

    case 'casino':
      return createSelector([getFavoriteCasinoGames], data =>
        data?.some(
          ({ id: gameId, extearnal_game_id: gameExtId }) =>
            id === gameId || gameExtId === id
        )
      );

    case 'competitions':
      return createSelector([getFavoriteCompetitions], data =>
        data.some(competition => competition.id === id)
      );

    case 'esport':
    case 'sportsbook':
      return createSelector([getFavoriteMatchIds], ({ all }) =>
        all.includes(id)
      );

    case 'markets':
      return createSelector([getFavoriteMarketGroupNames], markets =>
        markets.includes(id)
      );
  }
};

export const getFavoriteMatchesCountByCompetition = (
  competitionId: Competition['id']
) => {
  return createSelector(
    [getFavoriteMatchesCountPeerCompetition],
    favoriteMatchesCountPeerCompetition => {
      return favoriteMatchesCountPeerCompetition?.[competitionId] ?? 0;
    }
  );
};

export const getUserPersonalizationData = getFavData('userPersonalization');
export const getCollaspsedFavoriteBar = getFavData('collaspsedFavoriteBar');
