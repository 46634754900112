import SessionStorage from 'utils/bom-dom-manipulation/session-storage';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { ActionType } from 'interfaces/generic';
import {
  ERacingResultsTimeFilterTabs,
  TRacingResultsGame,
  TRacingResultsRegion
} from 'interfaces/racing';
import {
  EachWayPartnerTerms,
  IRacingGameHorseAdditionalData,
  RacingGame,
  RacingRegion,
  TSwarmRacingGame
} from 'interfaces/sportsbook-data-levels';
import { RacingDataActionTypes } from 'store/action-types';

export const setUpcomingData = (swarmData?: RacingGame[]): ActionType => {
  return {
    type: RacingDataActionTypes.SET_UPCOMING_DATA,
    payload: {
      swarmData
    }
  };
};

export const removeUpcomingAllData = (): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_UPCOMING_ALL_DATA
  };
};

export const setHorseGameAdditionalData = (
  id: number,
  horseGameAdditionalData: IRacingGameHorseAdditionalData | undefined | null
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_HORSE_GAME_ADDITIONAL_DATA,
    payload: {
      id,
      horseGameAdditionalData
    }
  };
};

export const removeHorseGameAdditionalData = (id: number): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_HORSE_GAME_ADDITIONAL_DATA,
    payload: {
      id
    }
  };
};

export const removeHorseGamesAdditionalData = (): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_HORSE_GAMES_ADDITIONAL_DATA
  };
};

export const setCompetitionData = (
  data: Record<number, RacingRegion>
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RACING_COMPETITION_DATA,
    payload: {
      data
    }
  };
};

export const removeCompetitionData = (): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_RACING_COMPETITION_DATA
  };
};

export const setGame = (swarmData: TSwarmRacingGame): ActionType => {
  return {
    type: RacingDataActionTypes.SET_GAME,
    payload: {
      swarmData
    }
  };
};

export const removeGame = (): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_GAME
  };
};

export const setEachWayPartnerTerms = (
  manualEachWayData: EachWayPartnerTerms,
  marketId: number
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_EACH_WAY_PARTNER_TERMS,
    payload: {
      manualEachWayData,
      marketId
    }
  };
};

export const setDateAndSportFilterActiveTab = (
  dateAndSportFilterActiveTab: string
): ActionType => {
  /* --- Please do not use this setter from another place. This local storage setter should only set the value from here. --- */

  SessionStorage.setItem(
    storageKeyName('racing', 'SPORTSBOOK_DATE_AND_SPORT_FILTER_ACTIVE_TAB'),
    dateAndSportFilterActiveTab
  );

  return {
    type: RacingDataActionTypes.SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB,
    payload: dateAndSportFilterActiveTab
  };
};

export const setIsCompetitionsLoading = (
  competitionsLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_COMPETITIONS_LOADING,
    payload: competitionsLoading
  };
};

export const setIsUpcomingRacesLoading = (
  upcomingRacesLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_UPCOMING_RACES_LOADING,
    payload: upcomingRacesLoading
  };
};

export const setGameFromActiveTab = (
  singleGameFromNextOffTab: string | null
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_GAME_FROM_ACTIVE_TAB,
    payload: singleGameFromNextOffTab
  };
};

export const setIsCheckGameStartTs = (
  isCheckGameStartTs: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_CHECK_GAME_START_TS,
    payload: isCheckGameStartTs
  };
};

export const setIsCheckCompetition = (
  isCheckCompetition: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_CHECK_COMPETITION,
    payload: isCheckCompetition
  };
};

export const setStoredGameId = (id?: number): ActionType => {
  return {
    type: RacingDataActionTypes.SET_STORED_GAME_ID,
    payload: { id }
  };
};

export const removeStoredGameIds = (storedGameId?: number): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_STORED_GAME_IDS,
    payload: { storedGameId }
  };
};

export const setResultsTimeFilterActiveTab = (
  resultsTimeFilterActiveTab: ERacingResultsTimeFilterTabs
): ActionType => {
  /* --- Please do not use this setter from another place. This local storage setter should only set the value from here. --- */

  SessionStorage.setItem(
    storageKeyName('racing', 'RESULTS_DATE_FILTER_ACTIVE_TAB'),
    resultsTimeFilterActiveTab
  );

  return {
    type: RacingDataActionTypes.SET_RESULTS_TIME_FILTER_ACTIVE_TAB,
    payload: resultsTimeFilterActiveTab
  };
};

export const setResultsCompetitionsData = (
  resultsCompetitionData?: TRacingResultsRegion[],
  timeFilter?: ERacingResultsTimeFilterTabs
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RESULTS_COMPETITIONS_DATA,
    payload: { resultsCompetitionData, timeFilter }
  };
};

export const removeResultsCompetitionsData = (): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_RESULTS_COMPETITIONS_DATA
  };
};

export const setIsResultsCompetitionDataLoading = (
  isResultsCompetitionDataLoading: boolean
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_IS_RESULTS_COMPETITION_DATA_LOADING,
    payload: isResultsCompetitionDataLoading
  };
};

export const seTRacingResultsGameData = (
  resultsSingleGameData?: TRacingResultsGame
): ActionType => {
  return {
    type: RacingDataActionTypes.SET_RESULTS_GAME_DATA,
    payload: { data: resultsSingleGameData }
  };
};

export const removeResultsGamesData = (): ActionType => {
  return {
    type: RacingDataActionTypes.REMOVE_RESULTS_GAMES_DATA
  };
};
