import { CUSTOM_CATEGORIES } from 'utils/constants/casino/custom-categories';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { iteratorUtil } from 'utils/generic/iterator.util';
import { ICasinoCachedGames } from 'interfaces/casino-data';
import { ActionType } from 'interfaces/generic';
import {
  NewCasinoCategory,
  NewCasinoGamesFetchingParams
} from 'interfaces/new-casino';
import { CasinoGamesConfigs } from 'newelements/Casino/Games/__partials/providers/CasinoGamesConfigsContext';
import { NewCasinoActionTypes } from 'store/action-types/new-casino';

type CasinoStore = {
  categories: {
    original: NewCasinoCategory[] | null;
    custom: NewCasinoCategory[];
    originalSet: string[] | null;
    originalSetLeftSideBar: string[] | null;
  };
  casinoCategoriesProviderIds: string;
  canRequestCategoriesNestedData: boolean;
  canRequestProvidersNestedData: boolean;
  lastPlayedCatSettings: { lastPlayed: boolean; lastPlayedCatType: number };
  categoryCustomIds: string[];
  cachedGames: ICasinoCachedGames;
  gamesConfigs: null | Partial<CasinoGamesConfigs>;
  enhancedParams: Partial<NewCasinoGamesFetchingParams> | null;
};

const initialCasinoStore: CasinoStore = {
  categories: {
    original: null,
    custom: [],
    originalSet: null,
    originalSetLeftSideBar: null
  },
  casinoCategoriesProviderIds: '',
  canRequestCategoriesNestedData: !SpringConfigs.CASINO_CATEGORY_ELEMENT_EXIST,
  canRequestProvidersNestedData: !SpringConfigs.CASINO_PROVIDER_ELEMENT_EXIST,
  lastPlayedCatSettings: { lastPlayed: true, lastPlayedCatType: 0 },
  categoryCustomIds: [],
  cachedGames: {},
  gamesConfigs: null,
  enhancedParams: null
};

const cachedGamesIterator = iteratorUtil(0);

export const newCasinoStore = (
  state = initialCasinoStore,
  action: ActionType
): CasinoStore => {
  switch (action.type) {
    case NewCasinoActionTypes.SET_CASINO_ORIGINAL_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          original: action.payload
        }
      };

    case NewCasinoActionTypes.SET_ENHANCED_PARAMS: {
      return {
        ...state,
        enhancedParams: action.payload
      };
    }

    case NewCasinoActionTypes.SET_GAMES_TOTAL_COUNT: {
      const newCustomCategories = state.categories.custom.map(item => {
        if (item.id === CUSTOM_CATEGORIES.all.id) {
          return { ...item, games_count: action.payload };
        }

        return item;
      });

      return {
        ...state,
        categories: {
          ...state.categories,
          custom: newCustomCategories
        }
      };
    }

    case NewCasinoActionTypes.SET_GAMES_CONFIG: {
      return {
        ...state,
        gamesConfigs: action.payload
      };
    }

    case NewCasinoActionTypes.SET_CASINO_CUSTOM_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          custom: action.payload
        }
      };

    case NewCasinoActionTypes.SET_CASINO_ORIGINAL_CATEGORIES_SET:
      return {
        ...state,
        categories: {
          ...state.categories,
          originalSet: action.payload
        }
      };
    case NewCasinoActionTypes.SET_CASINO_ORIGINAL_CATEGORIES_SET_LEFT_SIDEBAR:
      return {
        ...state,
        categories: {
          ...state.categories,
          originalSetLeftSideBar: action.payload
        }
      };

    case NewCasinoActionTypes.SET_CASINO_CATEGORY_PROVIDER_IDS:
      return {
        ...state,
        casinoCategoriesProviderIds: action.payload
      };
    case NewCasinoActionTypes.SET_CAN_REQUEST_CATEGORIES_NESTED_DATA:
      return {
        ...state,
        canRequestCategoriesNestedData: action.payload
      };
    case NewCasinoActionTypes.SET_CAN_REQUEST_PROVIDERS_NESTED_DATA:
      return {
        ...state,
        canRequestProvidersNestedData: action.payload
      };
    case NewCasinoActionTypes.SET_LAST_PLAYED_CAT_SETTINGS:
      return {
        ...state,
        lastPlayedCatSettings: action.payload
      };
    case NewCasinoActionTypes.SET_CATEGORIES_CUSTOM_IDS:
      return {
        ...state,
        categoryCustomIds: action.payload
      };

    case NewCasinoActionTypes.ADD_CACHED_GAME: {
      const draft = { ...state };
      const currentCachedGames = Object.keys(draft.cachedGames).length;

      // Remove the oldest cached game if the limit is reached
      if (currentCachedGames > SpringConfigs.CASINO_CACHED_GAMES_MAX_COUNT) {
        const oldestCachedGame = Object.values(draft.cachedGames)
          .filter(cachedGame => !!cachedGame.storeId || !cachedGame.active)
          .map(cachedGame => ({
            active: cachedGame.active,
            storeId: cachedGame.storeId,
            id: cachedGame.data.extearnal_game_id
          }))
          .sort((a, b) => a.storeId - b.storeId)[0];

        delete draft.cachedGames[oldestCachedGame.id];
      }

      draft.cachedGames = {
        ...state.cachedGames,
        [action.payload.extearnal_game_id]: {
          active: true,
          storeId: cachedGamesIterator.next(),
          data: action.payload
        }
      };

      return draft;
    }

    case NewCasinoActionTypes.ADD_CACHED_GAME_ID: {
      if (state.cachedGames[action.payload]) {
        return state;
      }

      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          [action.payload]: {}
        }
      };
    }

    case NewCasinoActionTypes.SET_CACHED_GAME_ACTIVE:
      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          [action.payload]: {
            ...state.cachedGames[action.payload],
            active: true
          }
        }
      };

    case NewCasinoActionTypes.SET_CACHED_GAME_INACTIVE:
      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          [action.payload]: {
            ...state.cachedGames[action.payload],
            active: false
          }
        }
      };

    case NewCasinoActionTypes.SET_CACHED_GAMES_INACTIVE: {
      const draftCachedGames = { ...state.cachedGames };

      // Set all cached games to inactive
      Object.keys(draftCachedGames).forEach(key => {
        draftCachedGames[key].active = false;
      });

      // Preserve the game with the given excluded id to remain active
      if (action.payload) {
        draftCachedGames[action.payload].active = true;
      }

      return {
        ...state,
        cachedGames: draftCachedGames
      };
    }

    case NewCasinoActionTypes.SET_CACHED_GAME_ORDER:
      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          [action.payload.id]: {
            ...state.cachedGames[action.payload.id],
            order: action.payload.order
          }
        }
      };

    case NewCasinoActionTypes.SET_CACHED_GAME_MODE:
      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          [action.payload.id]: {
            ...state.cachedGames[action.payload.id],
            mode: action.payload.mode
          }
        }
      };

    default: {
      return state;
    }
  }
};
