import i18n from 'i18next';
import { bankersRounding } from 'utils/betslip/bankersRounding';
import { getType } from 'utils/betslip/betslip';
import { multiply, subtract } from 'utils/generic/calculator';
import { ProfileData } from 'interfaces/authentication';
import { BetSlipEventObj } from 'interfaces/bet-data';
import {
  EBetslipBetType,
  EBetslipType,
  ESportBonusType,
  SportBonus
} from 'interfaces/betslip';
import { calcPercentOf } from 'newelements/_Betslip/utils/calculator/shared';
import { GENERAL_FIELD_KEY } from 'newelements/_Betslip/utils/constants/shared';
import { roundOdd } from 'newelements/_Betslip/utils/roundings/round-amount';
import Store from 'store';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip';
import { IGetAvailableBonusResult } from 'store/reducers/betslip/calculations';

export type TGetAvailableBonusParams = {
  stake: number;
  multiTotalReturn: string | number;
  eventId?: string | number;
};

type TGetAvailableBonus = (
  params: TGetAvailableBonusParams
) => IGetAvailableBonusResult;

export const getBonusInfo: TGetAvailableBonus = ({
  stake,
  eventId,
  multiTotalReturn
}) => {
  const result: IGetAvailableBonusResult = {
    nextPossibleBonusText: null,
    currentAcceptedBonus: null,
    acceptedBonusBets: [],
    bonusAmount: 0
  };

  if (eventId && String(eventId) !== GENERAL_FIELD_KEY) {
    return result;
  }

  const betslipStore: IBetslipRootStateInitialData = Store.getState().betSlip;
  const conflictingGameIds: Set<number> =
    Store.getState().betSlip.conflictingGameIds;

  const { currency: userCurrency }: ProfileData =
    Store.getState().userData.user;

  const partnerConfig = Store.getState().socket.partnerConfigs;

  const { sportBonuses, betslipType, type } = betslipStore;

  const currentType =
    betslipType === EBetslipType.BASIC ? getType(type) : EBetslipBetType.SINGLE;

  const currencyId = userCurrency ?? partnerConfig?.currency;

  const allBets = betslipStore.allLsBets.filter(
    bet => !conflictingGameIds.has(Number(bet.eventId))
  );

  const isBetTypeMultipleOrChain = [
    EBetslipBetType.MULTIPLE,
    EBetslipBetType.CHAIN
  ].includes(currentType);

  const isProfitBoostEnabled =
    !!betslipStore._profitBoost.profitBoostSelectedItemId &&
    !!betslipStore._profitBoost.profitBoostSelectedEventId;

  const isAdvancedBetslip = betslipType === EBetslipType.ADVANCED;
  const isBonusesAvailable = isBetTypeMultipleOrChain || isAdvancedBetslip;

  if (
    isBonusesAvailable &&
    sportBonuses.length &&
    allBets.length &&
    !isProfitBoostEnabled
  ) {
    // group all bonuses by minOdd
    const bonusesGroupedByMinOdd = sportBonuses.reduce<
      Record<string, SportBonus[]>
    >(
      (acc, bonus) => ({
        ...acc,
        [bonus.MinOdds]: [...(acc[bonus.MinOdds] || []), bonus]
      }),
      {}
    );

    // in this variable we store the bets, that meet bonus requirements (bet's coefficient should be more than minOdd of bonus)
    const betsMatchGroups: Record<string, BetSlipEventObj[]>[] = [];

    let currentBetsMatchGroupCount = 0;
    let currentBonusRule;
    let nextBonusRule;

    for (const bonusGroupMinOdd in bonusesGroupedByMinOdd) {
      const betsMatchGroup = allBets.filter(item => {
        return item?.coeficient ? +item.coeficient >= +bonusGroupMinOdd : false;
      });

      betsMatchGroups.push({
        [bonusGroupMinOdd]: betsMatchGroup
      });
    }

    // betsMatchGroups:  [ { minOdd:  BetSlipEventObj[] } ]
    // group: { minOdd:  BetSlipEventObj[] }
    for (const group of betsMatchGroups) {
      // groupKey is same minOdd
      const groupKey = Object.keys(group)[0];
      currentBonusRule = bonusesGroupedByMinOdd[groupKey].find(item => {
        const minBetStakeValue =
          item.MinBetStakes.MinStakes?.find(
            item => item.Currency === currencyId
          )?.Amount ?? 0;

        const isTicketsCountInRange =
          group[groupKey].length >= item.MinimumSelections &&
          group[groupKey].length <= item.MaximumSelections;

        const isLowOddsSelectionsIgnored =
          item.IgnoreLowOddSelection ||
          allBets.length === group[groupKey].length;

        const isMinBetStakeSatisfy = stake >= minBetStakeValue;

        return (
          isTicketsCountInRange &&
          isLowOddsSelectionsIgnored &&
          isMinBetStakeSatisfy
        );
      });

      const minimumSelectionLength = currentBonusRule
        ? currentBonusRule.MinimumSelections
        : group[groupKey].length;

      nextBonusRule = sportBonuses.find(item => {
        return (
          item.MinimumSelections > minimumSelectionLength &&
          +item.MinOdds >= +groupKey
        );
      });
      currentBetsMatchGroupCount = group[groupKey].length;

      if (currentBonusRule && !currentBonusRule.FreeBetId) {
        result.currentAcceptedBonus = currentBonusRule;
        result.acceptedBonusBets = group[groupKey];

        break;
      }
    }

    if (nextBonusRule && !nextBonusRule.FreeBetId) {
      const percent = nextBonusRule.AmountPercent;
      const betCount = (nextBonusRule.MinimumSelections -
        currentBetsMatchGroupCount) as number;

      const minOdd = nextBonusRule.MinOdds;

      result.nextPossibleBonusText = i18n.t('betslip.bonusTextInfo', {
        percent,
        betCount,
        minOdd
      });
    }
  }

  if (result.acceptedBonusBets && result.currentAcceptedBonus) {
    const bonusAmount = getAcceptedBonusAmount({
      stake,
      multiTotalReturn,
      currentBonus: result.currentAcceptedBonus,
      acceptedBonusBets: result.acceptedBonusBets
    });

    result.bonusAmount = bonusAmount;
  }

  return result;
};

export const getAcceptedBonusAmount = ({
  stake,
  currentBonus,
  multiTotalReturn,
  acceptedBonusBets
}: {
  currentBonus: SportBonus;
  acceptedBonusBets: BetSlipEventObj[];
  stake: number;
  multiTotalReturn: string | number;
}): number => {
  const partnerConfig = Store.getState().socket.partnerConfigs;

  const conflictingGameIds: Set<number> =
    Store.getState().betSlip.conflictingGameIds;

  const betslipStore: IBetslipRootStateInitialData = Store.getState().betSlip;
  const { betslipType, type } = betslipStore;

  const allBets = betslipStore.allLsBets.filter(
    bet => !conflictingGameIds.has(Number(bet.eventId))
  );

  // @Todo need to remove this type checks
  const currentType =
    betslipType === EBetslipType.ADVANCED
      ? EBetslipBetType.SINGLE
      : (type as EBetslipBetType);

  if (currentBonus && currentType !== EBetslipBetType.SYSTEM) {
    // @Todo need to understand this part to
    let totalReturn = +multiTotalReturn;

    if (allBets.length !== acceptedBonusBets?.length) {
      let acceptedTotal = acceptedBonusBets.reduce(
        (acc, bet) => (Number(bet?.coeficient) || 0) * acc,
        1
      ) as number;

      if (
        partnerConfig?.max_odd_for_multiple_bet &&
        acceptedTotal >= partnerConfig.max_odd_for_multiple_bet
      ) {
        acceptedTotal = partnerConfig?.max_odd_for_multiple_bet;
      }

      acceptedTotal = Number(roundOdd(acceptedTotal));

      totalReturn = bankersRounding(multiply(acceptedTotal, stake));
    }

    // @Todo need to check this part to
    if (currentBonus.Basis === ESportBonusType.STAKE_BONUS) {
      return bankersRounding(calcPercentOf(stake, currentBonus.AmountPercent));
    }

    if (
      currentBonus.Basis === ESportBonusType.TOTAL_RETURN_WITHOUT_STAKE_BONUS
    ) {
      return bankersRounding(
        calcPercentOf(subtract(totalReturn, stake), currentBonus.AmountPercent)
      );
    }

    return bankersRounding(
      calcPercentOf(+totalReturn, currentBonus.AmountPercent)
    );
  }

  return 0;
};
