import dayjs from 'dayjs';
import { addPropertyToObjectWithLimit } from 'utils/helpers';
import { MarketType } from 'interfaces/bet-data';
import { MultipleCardsData } from 'interfaces/multiple-cards';
import { UserNotifications } from 'interfaces/notifications';
import { TMarketType } from 'interfaces/sportsbook';
import {
  Game,
  GameInfo,
  Region,
  Sport,
  StreamChannelsType,
  TCouponState
} from 'interfaces/sportsbook-data-levels';
import { SportDataActionTypes } from 'store/action-types';
import { SportDataActionType } from 'store/actions';

type Info = Pick<
  GameInfo,
  'current_game_state' | 'current_game_time' | 'score1' | 'score2'
>;
type GameType = Pick<
  Game,
  | 'markets_count'
  | 'region_alias'
  | 'id'
  | 'team1_name'
  | 'team2_name'
  | 'start_ts'
  | 'market'
> & { info: Info };

type TEventsTypeKey =
  | 'liveEvents'
  | 'popularEvents'
  | 'boostedEvents'
  | 'upcomingEvents';

export type TCompetitionWithGame = {
  id: number;
  name: string;
  game: {
    [key: string]: GameType;
  };
};

export type TStreamData = {
  provider_id: number;
  video_id: number;
  allow_zero_balance?: number;
  is_guest?: number;
};

type TEventDataTypeLevel = {
  [k in TEventsTypeKey]: TCompetitionWithGame[];
};

export enum EBoostType {
  ALL = 0,
  LIVE = 1,
  PREMATCH = 2
}
const CASHED_SPORT_DATA_LIMIT = 10;
const GAMES_BY_COMPETITIONS_LIMIT = 10;

type InitialData = {
  multipleSelections: Record<string, MultipleCardsData> | null;
  cashedSport: Record<string, (Sport | Game)[]>;
  cashedGame: Record<string, any> | null;
  streamData: Array<[TStreamData]> | null;
  streamChannels: Array<StreamChannelsType> | null | undefined;
  marketTypes: Record<string, TMarketType[] | null>;
  marketType: Record<string, TMarketType | null>;
  boostedOdds: Record<string, Record<string, { type: EBoostType }>> | null;
  boostedOddsCalled: boolean;
  streamDataLoading: boolean;
  hasTopLeagueGames: boolean;
  scrollToGameId?: {
    id: string;
    top: number;
  };
  scrollToCompetitionId?: {
    id: string;
    top: number;
    sportId?: number;
  };
  eventsData: {
    [key: string]: TEventDataTypeLevel;
  } | null;
  timeFilterOption: {
    '@gt'?: number;
    '@lt'?: number;
  };
  timeFilterOptionObj: {
    option: string;
    date: false;
    count: number;
    name: string;
  };
  sportsList: Record<string, Region[]> | null;
  calendarMarketTypes: Record<string, MarketType[]>;
  calendarMarketType: MarketType | null;
  regionViewAllMarkets: Record<string, Pick<Game, 'market'>>;
  userNotifications: UserNotifications;
  calendarMarketInfoType: string;
  gamesCount: Record<string, number>;
  gamesByCompetitions: Record<string, Record<string, Sport>>;
  tournamentSportIds: Array<string | number | null> | null;
  coupons: TCouponState;
  clickFromFavorites: boolean;
};

const initialData: InitialData = {
  multipleSelections: null,
  cashedSport: {},
  cashedGame: null,
  streamData: null,
  streamChannels: undefined,
  marketTypes: {},
  marketType: {},
  boostedOdds: null,
  boostedOddsCalled: false,
  streamDataLoading: true,
  hasTopLeagueGames: true,
  scrollToGameId: undefined,
  scrollToCompetitionId: undefined,
  calendarMarketInfoType: 'compact',
  eventsData: null,
  timeFilterOption: {},
  timeFilterOptionObj: {
    option: 'All',
    date: false,
    count: -1,
    name: 'All'
  },
  sportsList: null,
  calendarMarketTypes: {
    default: [{ label: 'Match Result', value: 'default' }]
  },
  calendarMarketType: null,
  regionViewAllMarkets: {},
  userNotifications: {},
  gamesCount: {},
  gamesByCompetitions: {},
  tournamentSportIds: null,
  coupons: {
    data: null,
    selected: null,
    games: null
  },
  clickFromFavorites: false
};

// FIXME: Refactor this store to use immer.js as well.
//        Unfortunately for now this store's data used somewhere during
//        socket command send and that causes crash of the app because of
//        the command trying to mutate immutable data.
export const sportData = (
  state = initialData,
  action: SportDataActionType
): InitialData => {
  switch (action.type) {
    case SportDataActionTypes.SET_MULTIPLE_SELECTIONS:
      return {
        ...state,
        multipleSelections: action.payload
      };
    case SportDataActionTypes.SET_CAHSED_GAME_DATA:
      return {
        ...state,
        cashedGame: {
          ...(state.cashedGame || {}),
          [action.payload.id]: action.payload.data
        }
      };
    case SportDataActionTypes.SET_CASHED_SPORT_DATA:
      return {
        ...state,
        cashedSport:
          action.payload && Object.keys(action.payload).length
            ? addPropertyToObjectWithLimit(
                state.cashedSport,
                { [action.payload.alias]: action.payload.data },
                CASHED_SPORT_DATA_LIMIT
              )
            : {}
      };

    case SportDataActionTypes.SET_STREAM_DATA:
      return {
        ...state,
        streamData: {
          ...(state?.streamData || []),
          ...action.payload
        },
        streamDataLoading: false
      };
    case SportDataActionTypes.SET_STREAM_CHANNELS:
      return {
        ...state,
        streamChannels: action.payload
      };
    case SportDataActionTypes.SET_MARKET_TYPES:
      return {
        ...state,
        marketTypes: { ...state.marketTypes, ...action.payload }
      };
    case SportDataActionTypes.SET_MARKET_TYPE:
      return {
        ...state,
        marketType: action.payload
      };
    case SportDataActionTypes.RESET_ALL_MARKET_TYPES:
      return {
        ...state,
        marketType: {},
        marketTypes: {}
      };
    case SportDataActionTypes.SET_BOOSTED_ODDS:
      return {
        ...state,
        boostedOdds: action.payload
      };
    case SportDataActionTypes.SET_BOOSTED_ODDS_CALLED:
      return {
        ...state,
        boostedOddsCalled: true
      };
    case SportDataActionTypes.SET_STREAM_DATA_LOADING:
      return {
        ...state,
        streamDataLoading: true
      };
    case SportDataActionTypes.SET_HAS_TOP_LEAGUE_GAMES:
      return {
        ...state,
        hasTopLeagueGames: action.payload
      };
    case SportDataActionTypes.SCROLL_TO_GAME_ID:
      return {
        ...state,
        scrollToGameId: action.payload
      };
    case SportDataActionTypes.SCROLL_TO_COMPETITION_ID:
      return {
        ...state,
        scrollToCompetitionId: action.payload
      };
    case SportDataActionTypes.SET_LIVE_EVENTS_DATA:
      if (!action.sportAlias) {
        return {
          ...state,
          eventsData: {}
        };
      }

      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          [`${action.sportAlias}`]: {
            ...(state.eventsData?.[
              `${action.sportAlias}`
            ] as TEventDataTypeLevel),
            liveEvents: action.payload
          }
        }
      };
    case SportDataActionTypes.SET_UPCOMING_EVENTS_DATA:
      if (!action.sportAlias) {
        return {
          ...state,
          eventsData: {}
        };
      }

      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          [`${action.sportAlias}`]: {
            ...(state?.eventsData?.[
              `${action.sportAlias}`
            ] as TEventDataTypeLevel),
            upcomingEvents: action.payload
          }
        }
      };
    case SportDataActionTypes.SET_POPULAR_EVENTS_DATA:
      if (!action.sportAlias) {
        return {
          ...state,
          eventsData: {}
        };
      }

      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          [`${action.sportAlias}`]: {
            ...(state.eventsData?.[
              `${action.sportAlias}`
            ] as TEventDataTypeLevel),
            popularEvents: action.payload
          }
        }
      };
    case SportDataActionTypes.SET_BOOSTED_EVENTS_DATA:
      if (!action.sportAlias) {
        return {
          ...state,
          eventsData: {}
        };
      }

      return {
        ...state,
        eventsData: {
          ...state.eventsData,
          [`${action.sportAlias}`]: {
            ...((state.eventsData?.[`${action.sportAlias}`] ||
              {}) as TEventDataTypeLevel),
            boostedEvents: action.payload
          }
        }
      };

    case SportDataActionTypes.SET_TIME_FILTER_DATA:
      if (action.payload.option === 'All') {
        return {
          ...state,
          timeFilterOptionObj: action.payload,
          timeFilterOption: initialData.timeFilterOption
        };
      } else if (action.payload.option !== 'All' && action.payload.date) {
        return {
          ...state,
          timeFilterOptionObj: action.payload,
          timeFilterOption: {
            '@gt': dayjs()
              .startOf('date')
              .add(action.payload.count, 'day')
              .unix(),
            '@lt': dayjs().endOf('date').add(action.payload.count, 'day').unix()
          }
        };
      } else {
        return {
          ...state,
          timeFilterOptionObj: action.payload,
          timeFilterOption: {
            '@gt': dayjs(new Date()).unix(),
            '@lt': dayjs().add(action.payload.count, 'hour').unix()
          }
        };
      }

    case SportDataActionTypes.SET_SPORTS_LIST_SPORT_DATA:
      return {
        ...state,
        sportsList: {
          ...state.sportsList,
          [`${action.sportAlias}`]: action.payload
        }
      };

    case SportDataActionTypes.SET_CALENDAR_MARKET_TYPES:
      return {
        ...state,
        calendarMarketTypes: { ...state.calendarMarketTypes, ...action.payload }
      };
    case SportDataActionTypes.SET_MARKET_INFO_TYPE:
      return {
        ...state,
        calendarMarketInfoType: action.payload
      };
    case SportDataActionTypes.SET_CALENDAR_MARKET_TYPE:
      return {
        ...state,
        calendarMarketType: action.payload
      };

    case SportDataActionTypes.SET_REGION_MARKETS:
      return {
        ...state,
        regionViewAllMarkets: action.payload
      };
    case SportDataActionTypes.SET_USER_NOTIFICATIONS:
      return {
        ...state,
        userNotifications: Object.keys(action.payload).length
          ? { ...state.userNotifications, ...action.payload }
          : { ...initialData.userNotifications }
      };

    case SportDataActionTypes.SET_GAMES_COUNT:
      return {
        ...state,
        gamesCount: action.payload
      };

    case SportDataActionTypes.SET_TOURNAMENT_SPORT_IDS:
      return {
        ...state,
        tournamentSportIds: action.payload
      };

    case SportDataActionTypes.SET_CLICK_FROM_FAVORITES:
      return {
        ...state,
        clickFromFavorites: action.payload
      };

    case SportDataActionTypes.SET_GAMES_BY_COMPETITIONS:
      return {
        ...state,
        gamesByCompetitions: addPropertyToObjectWithLimit(
          state.gamesByCompetitions,
          { [action.payload.alias]: action.payload.data },
          GAMES_BY_COMPETITIONS_LIMIT
        )
      };

    case SportDataActionTypes.SET_COUPONS:
      return {
        ...state,
        coupons: action.payload
      };

    default:
      return state;
  }
};
