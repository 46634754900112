import { AnyAction } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { removeMatchByID } from 'utils/sportsbook/favorites/fav-sorter';
import { NewCasinoGame } from 'interfaces/new-casino';
import {
  FavoriteCompetitionInfo,
  TClearFavoriteGroupKey,
  TFavoritesState,
  TToggleFavoriteGameAction
} from 'interfaces/sportsbook-data-levels';
import {
  FavoritePersonalization,
  TPersonalizedSportGames
} from 'services/favorite-personalization';
import type { TTeamsDictionary } from 'newcomponents/Shared/sportsbook/FavoriteTeam/partials/SearchTeam';
import { FavoriteActionTypes } from 'store/action-types';

// ATTENTION: when adding new value here its default state may not work correctly as persist overrides this state by persisted data from users local storage, check line:45
const initialFavData: TFavoritesState = {
  dataLoading: false,
  idsLoading: false,
  casino: undefined,
  sports: [],
  providers: undefined,
  markets: [],
  teams: [],
  competitions: [],
  marketsCount: {},
  esport: {
    live: { ids: [], data: {}, count: 0 },
    prematch: { ids: [], data: {}, count: 0 }
  },
  sportsbook: {
    live: { ids: [], data: {}, count: 0 },
    prematch: { ids: [], data: {}, count: 0 }
  },
  favoriteMatchesCountPeerCompetition: {},
  personalizedSportsResourceId: null,
  personalizedGameResourceId: null,
  personalizedProviderResourceId: null,
  personalizedCompetitionResourceId: null,
  personalizedMarketResourceId: null,
  personalizedSportGamesResourceId: null,
  personalizedSportTeamsFavoriteResourceId: null,
  userPersonalization: {
    categories: [],
    sports: [],
    competitions: [],
    casinoGames: [],
    casinoProviders: [],
    eSports: []
  },

  collaspsedFavoriteBar: false
};

const resetValue = {
  live: { ids: [], data: {}, count: 0 },
  prematch: { ids: [], data: {}, count: 0 }
};

const favDataRootReducer = (
  state = initialFavData,
  action: AnyAction
): TFavoritesState => {
  //previous data from already persisted store which does not contain new added key overrides default state and sets undefined, in this case providers: undefined which caused issues
  if (action?.type === 'persist/REHYDRATE' && action?.payload?.favData) {
    if (!action.payload.favData.providers?.length) {
      action.payload.favData = {
        ...action.payload.favData,
        providers: undefined
      };
    }

    if (!action.payload.favData.casino?.length) {
      action.payload.favData = {
        ...action.payload.favData,
        casino: undefined
      };
    }

    if (action.payload.favData.sports === undefined) {
      action.payload.favData = {
        ...action.payload.favData,
        sports: []
      };
    }

    if (
      action.payload.favData.favoriteMatchesCountPeerCompetition === undefined
    ) {
      action.payload.favData = {
        ...action.payload.favData,
        favoriteMatchesCountPeerCompetition: {}
      };
    }

    if (action.payload.favData.userPersonalization === undefined) {
      action.payload.favData = {
        ...action.payload.favData,
        userPersonalization: {
          categories: [],
          sports: [],
          competitions: [],
          casinoGames: [],
          casinoProviders: [],
          eSports: []
        }
      };
    }

    if (action.payload.favData.userPersonalization.eSports === undefined) {
      action.payload.favData.userPersonalization = {
        ...action.payload.favData.userPersonalization,
        eSports: []
      };
    }
  }

  switch (action.type) {
    case FavoriteActionTypes.SET_FAVORITES_MARKETS_COUNT:
      state.marketsCount = { ...state.marketsCount, ...action.payload };

      return {
        ...state,
        marketsCount: { ...state.marketsCount, ...action.payload }
      };

    case FavoriteActionTypes.TOGGLE_FAVORITE:
      {
        const payload = action.payload as TToggleFavoriteGameAction;
        const draft = structuredClone(state);

        if (
          payload.groupKey === 'sportsbook' ||
          payload.groupKey === 'esport'
        ) {
          const { entity, groupKey, gameType } = payload;

          const finalData = [...state[groupKey][gameType].ids];

          if (finalData.includes(entity)) {
            finalData.splice(finalData.indexOf(entity), 1);

            draft[groupKey][gameType].data = removeMatchByID(
              draft[groupKey][gameType].data,
              entity
            );
          } else {
            finalData.push(entity);
          }

          const favSportGames = {
            ...draft,
            [groupKey]: {
              ...draft[groupKey],
              [gameType]: {
                ...draft[groupKey][gameType],
                count: finalData.length,
                ids: finalData
              }
            }
          };

          if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
            const { storeSportGames, clearResource } =
              FavoritePersonalization();

            const personolizedSportGames: TPersonalizedSportGames = {
              sportsbook: {
                live: {},
                prematch: {}
              },
              esport: {
                live: {},
                prematch: {}
              }
            };

            let isEmpty = true;

            if (favSportGames.sportsbook.live.ids.length) {
              isEmpty = false;
              favSportGames.sportsbook.live.ids.forEach(id => {
                personolizedSportGames.sportsbook.live[id] = {};
              });
            }

            if (favSportGames.sportsbook.prematch.ids.length) {
              isEmpty = false;
              favSportGames.sportsbook.prematch.ids.forEach(id => {
                personolizedSportGames.sportsbook.prematch[id] = {};
              });
            }

            if (favSportGames.esport.live.ids.length) {
              isEmpty = false;
              favSportGames.esport.live.ids.forEach(id => {
                personolizedSportGames.esport.live[id] = {};
              });
            }

            if (favSportGames.esport.prematch.ids.length) {
              isEmpty = false;
              favSportGames.esport.prematch.ids.forEach(id => {
                personolizedSportGames.esport.prematch[id] = {};
              });
            }

            if (isEmpty) {
              draft.personalizedSportGamesResourceId &&
                clearResource(draft.personalizedSportGamesResourceId);
            } else {
              storeSportGames(personolizedSportGames);
            }
          }

          return favSportGames;
        } else if (payload.groupKey === 'markets') {
          const finalData = [...draft[payload.groupKey]];
          const marketGroupName = payload.entity;

          if (draft.markets.includes(marketGroupName)) {
            finalData.splice(finalData.indexOf(marketGroupName), 1);
          } else {
            finalData.push(marketGroupName);
          }

          if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
            const { storeMarketGroups, clearResource } =
              FavoritePersonalization();

            const markets: { [key: string]: {} } = {};

            finalData.forEach(market => {
              markets[market] = {};
            });

            if (finalData.length) {
              storeMarketGroups(markets);
            } else {
              draft.personalizedMarketResourceId &&
                clearResource(draft.personalizedMarketResourceId);
            }
          }

          return {
            ...draft,
            markets: finalData
          };
        } else if (
          payload.groupKey === 'providers' &&
          SpringConfigs.USER_PERSONALIZATION_ENABLED
        ) {
          const finalData = [...(draft[payload.groupKey] || [])];
          const providerGroupName = payload.entity;

          if (draft.providers?.includes(providerGroupName)) {
            finalData.splice(finalData.indexOf(providerGroupName), 1);
          } else {
            finalData.push(providerGroupName);
          }

          const { storeCasinoProviders, clearResource } =
            FavoritePersonalization();

          const providers: { [key: string]: {} } = {};

          finalData.forEach(provider => {
            providers[provider] = {};
          });

          if (finalData.length) {
            storeCasinoProviders(providers);
          } else {
            draft.personalizedProviderResourceId &&
              clearResource(draft.personalizedProviderResourceId);
          }

          return {
            ...draft,
            providers: finalData
          };
        } else if (payload.groupKey === 'casino') {
          const finalData = [...(draft[payload.groupKey] || [])];
          const existGame = finalData.find(
            game => game.id === payload.entity.id
          );

          if (existGame) {
            finalData.splice(finalData.indexOf(existGame), 1);
          } else {
            finalData.push(payload.entity);
          }

          if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
            const { storeCasinoGames, clearResource } =
              FavoritePersonalization();

            const games: { [key: number]: NewCasinoGame } = {};

            finalData.forEach(game => {
              games[+game.extearnal_game_id] = game;
            });

            if (finalData.length) {
              storeCasinoGames(games);
            } else {
              draft.personalizedGameResourceId &&
                clearResource(draft.personalizedGameResourceId);
            }
          }

          return {
            ...draft,
            [payload.groupKey]: finalData
          };
        } else if (payload.groupKey === 'competitions') {
          const finalData = [...draft[payload.groupKey]];
          const existCompetition = finalData.find(
            ({ id }) => id === payload.entity.id
          );

          if (existCompetition) {
            finalData.splice(finalData.indexOf(existCompetition), 1);
          } else {
            finalData.push(payload.entity);
          }

          if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
            const { storeSportCompetitions, clearResource } =
              FavoritePersonalization();

            const competitions: { [key: number]: FavoriteCompetitionInfo } = {};

            finalData.forEach(competition => {
              competitions[+competition.id] = competition;
            });

            if (finalData.length) {
              storeSportCompetitions(competitions);
            } else {
              draft.personalizedCompetitionResourceId &&
                clearResource(draft.personalizedCompetitionResourceId);
            }
          }

          return {
            ...draft,
            [payload.groupKey]: finalData
          };
        } else if (payload.groupKey === 'teams') {
          let finalData = [...draft[payload.groupKey]];

          finalData = payload.entity;

          const { setFavoriteTeamsCommand, clearResource } =
            FavoritePersonalization();

          const transformedTeams: TTeamsDictionary = finalData.reduce(
            (acc: TTeamsDictionary, team) => {
              acc[team.teamId] = {
                TeamId: team.teamId,
                TeamName: team.teamName,
                SportName: team.sport.name,
                SportId: team.sport.id,
                SportAlias: team.sport.alias
              };

              return acc;
            },
            {}
          );

          if (finalData.length) {
            setFavoriteTeamsCommand(transformedTeams);
          } else {
            draft.personalizedSportTeamsFavoriteResourceId &&
              clearResource(draft.personalizedSportTeamsFavoriteResourceId);
          }

          return {
            ...draft,
            [payload.groupKey]: finalData
          };
        } else if (payload.groupKey === 'sports') {
          const finalData = [...draft[payload.groupKey]];

          const existSport = finalData.find(id => id === payload.entity);

          if (existSport) {
            finalData.splice(finalData.indexOf(existSport), 1);
          } else {
            finalData.push(+payload.entity);
          }

          if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
            const { storeSports, clearResource } = FavoritePersonalization();

            const sports: Record<number, {}> = {};

            finalData.forEach(sportId => {
              sports[sportId] = {};
            });

            if (finalData.length) {
              storeSports(sports);
            } else {
              draft.personalizedSportsResourceId &&
                clearResource(draft.personalizedSportsResourceId);
            }
          }

          return {
            ...draft,
            [payload.groupKey]: finalData.sort((a, b) => a - b)
          };
        }
      }

      return state;

    case FavoriteActionTypes.CLEAR_FAVORITE: {
      const payload = action.payload as TClearFavoriteGroupKey;

      const draft = structuredClone(state);

      const result = {
        ...draft,
        [payload]: resetValue
      };

      if (payload === 'sportsbook' || payload === 'esport') {
        if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
          const { storeSportGames, clearResource } = FavoritePersonalization();

          if (
            result.esport.live.count === 0 &&
            result.esport.prematch.count === 0 &&
            result.sportsbook.live.count === 0 &&
            result.sportsbook.prematch.count === 0
          ) {
            draft.personalizedSportGamesResourceId &&
              clearResource(draft.personalizedSportGamesResourceId);
          } else {
            const activeKey: 'esport' | 'sportsbook' =
              payload === 'sportsbook' ? 'esport' : 'sportsbook';

            const config: TPersonalizedSportGames = {
              sportsbook: {
                live: {},
                prematch: {}
              },
              esport: {
                live: {},
                prematch: {}
              }
            };

            result[activeKey].live.ids.forEach(id => {
              config[activeKey].live[id] = {};
            });
            result[activeKey].prematch.ids.forEach(id => {
              config[activeKey].prematch[id] = {};
            });
            storeSportGames(config);
          }
        }

        return result;
      } else if (
        payload === 'casino' ||
        payload === 'markets' ||
        payload === 'providers' ||
        payload === 'competitions'
      ) {
        draft[payload] = [];

        if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
          const { clearResource } = FavoritePersonalization();

          if (payload === 'casino') {
            draft.personalizedGameResourceId &&
              clearResource(draft.personalizedGameResourceId);
          }

          if (payload === 'competitions') {
            draft.personalizedCompetitionResourceId &&
              clearResource(draft.personalizedCompetitionResourceId);
          }

          if (payload === 'providers') {
            draft.personalizedProviderResourceId &&
              clearResource(draft.personalizedProviderResourceId);
          }
        }

        return {
          ...draft,
          [payload]: []
        };
      } else if (payload === 'sports') {
        if (SpringConfigs.USER_PERSONALIZATION_ENABLED) {
          const { clearResource } = FavoritePersonalization();
          draft.personalizedSportGamesResourceId &&
            clearResource(draft.personalizedSportGamesResourceId);
        }

        return {
          ...draft,
          esport: resetValue,
          sportsbook: resetValue,
          competitions: []
        };
      }

      return draft;
    }

    case FavoriteActionTypes.SET_FAVORITE_SPORTS_DATA:
      return {
        ...state,
        esport: action.payload.esport,
        sportsbook: action.payload.sportsbook,
        favoriteMatchesCountPeerCompetition:
          action.payload.favoriteMatchesCountPeerCompetition
      };

    case FavoriteActionTypes.SET_FAVORITE_DATA_LOADING:
      return {
        ...state,
        dataLoading: action.payload
      };

    case FavoriteActionTypes.SET_FAVORITE_IDS_LOADING:
      return {
        ...state,
        idsLoading: action.payload
      };

    case FavoriteActionTypes.SET_MARKETS_COUNTS:
      return {
        ...state,
        marketsCount: action.payload
      };

    case FavoriteActionTypes.SET_PROVIDERS_FAV_DATA:
      return {
        ...state,
        providers: action.payload
      };
    case FavoriteActionTypes.SET_MARKETS_FAV_DATA:
      return {
        ...state,
        markets: action.payload
      };

    case FavoriteActionTypes.SET_FAVORITE_COMPETITION:
      return {
        ...state,
        competitions: action.payload
      };

    case FavoriteActionTypes.SET_FAVORITE_SPORTS:
      return {
        ...state,
        sports: action.payload?.sort((a: number, b: number) => a - b)
      };

    case FavoriteActionTypes.SET_SPORT_TEAMS:
      return {
        ...state,
        teams: action.payload
      };

    case FavoriteActionTypes.SET_CASINO_FAV_DATA:
      return {
        ...state,
        casino: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_GAME_RESOURCE_ID:
      return {
        ...state,
        personalizedGameResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_SPORTS_RESOURCE_ID:
      return {
        ...state,
        personalizedSportsResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_SPORT_TEAMS_RESOURCE_ID:
      return {
        ...state,
        personalizedSportTeamsFavoriteResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_PROVIDER_RESOURCE_ID:
      return {
        ...state,
        personalizedProviderResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_COMPETITION_RESOURCE_ID:
      return {
        ...state,
        personalizedCompetitionResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_MARKET_RESOURCE_ID:
      return {
        ...state,
        personalizedMarketResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZED_SPORT_GAMES_RESOURCE_ID:
      return {
        ...state,
        personalizedSportGamesResourceId: action.payload
      };

    case FavoriteActionTypes.SET_PERSONALIZATION_DATA:
      return {
        ...state,
        userPersonalization: action.payload
      };

    case FavoriteActionTypes.CLEAR_FAVORITE_MATCHES: {
      const draft = structuredClone(state);
      (
        ['esport', 'sportsbook'] as (keyof Pick<
          TFavoritesState,
          'sportsbook' | 'esport'
        >)[]
      ).forEach(key => {
        draft[key].live = { ...draft[key].live, data: {} };
        draft[key].prematch = { ...draft[key].prematch, data: {} };
      });

      draft.favoriteMatchesCountPeerCompetition = {};

      if (
        SpringConfigs.USER_PERSONALIZATION_ENABLED &&
        !draft.competitions.length
      ) {
        const { clearResource } = FavoritePersonalization();

        draft.personalizedCompetitionResourceId &&
          clearResource(draft.personalizedCompetitionResourceId);
      }

      return draft;
    }

    case FavoriteActionTypes.SET_COLLAPSED_FAVORITE_BAR:
      return {
        ...state,
        collaspsedFavoriteBar: action.payload
      };

    default: {
      return state;
    }
  }
};

const persistConfig = {
  key: 'favData',
  storage: storage,
  blacklist: ['teams']
};

export const favData = persistReducer(persistConfig, favDataRootReducer);
