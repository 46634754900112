// prettier-ignore
export const RacingDataActionTypes = {
  SET_UPCOMING_DATA: 'SET_UPCOMING_DATA',
  REMOVE_UPCOMING_ALL_DATA: 'REMOVE_UPCOMING_ALL_DATA',
  SET_HORSE_GAME_ADDITIONAL_DATA: 'SET_HORSE_GAME_ADDITIONAL_DATA',
  REMOVE_HORSE_GAME_ADDITIONAL_DATA: 'REMOVE_HORSE_GAME_ADDITIONAL_DATA',
  REMOVE_HORSE_GAMES_ADDITIONAL_DATA: 'REMOVE_HORSE_GAMES_ADDITIONAL_DATA',
  SET_RACING_COMPETITION_DATA: 'SET_RACING_COMPETITION_DATA',
  REMOVE_RACING_COMPETITION_DATA: 'REMOVE_RACING_COMPETITION_DATA',
  SET_GAME: 'SET_GAME',
  REMOVE_GAME: 'REMOVE_GAME',
  SET_STORED_GAME_ID: 'SET_STORED_GAME_ID',
  REMOVE_STORED_GAME_IDS: 'REMOVE_STORED_GAME_IDS',
  SET_EACH_WAY_PARTNER_TERMS: 'SET_EACH_WAY_PARTNER_TERMS',
  SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB: 'SET_DATE_AND_SPORT_FILTER_ACTIVE_TAB',
  SET_IS_COMPETITIONS_LOADING: 'SET_IS_COMPETITIONS_LOADING',
  SET_IS_UPCOMING_RACES_LOADING: 'SET_IS_UPCOMING_RACES_LOADING',
  SET_GAME_FROM_ACTIVE_TAB: 'SET_GAME_FROM_ACTIVE_TAB',
  SET_IS_CHECK_GAME_START_TS: 'SET_IS_CHECK_GAME_START_TS',
  SET_IS_CHECK_COMPETITION: 'SET_IS_CHECK_COMPETITION',
  SET_RESULTS_TIME_FILTER_ACTIVE_TAB: 'SET_RESULTS_TIME_FILTER_ACTIVE_TAB',
  SET_RESULTS_COMPETITIONS_DATA: 'SET_RESULTS_COMPETITIONS_DATA',
  REMOVE_RESULTS_COMPETITIONS_DATA: 'REMOVE_RESULTS_COMPETITIONS_DATA',
  SET_IS_RESULTS_COMPETITION_DATA_LOADING: 'SET_IS_RESULTS_COMPETITION_DATA_LOADING',
  SET_RESULTS_GAME_DATA: 'SET_RESULTS_GAME_DATA',
  REMOVE_RESULTS_GAMES_DATA: 'REMOVE_RESULTS_GAMES_DATA',
};
