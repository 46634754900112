import SpringConfigs from 'utils/constants/swarm/spring-configs';
/*eslint no-magic-numbers:*/

const AccountTypeSelector: Record<string, string> = {
  0: 'default',
  1: 'sportsbook',
  2: 'casino',
  3: 'esports'
};

export const defaultEnabled =
  AccountTypeSelector[SpringConfigs.CASINO_SPORTSBOOK_SWITCHER] === 'default';

export const sportsbookEnabled =
  AccountTypeSelector[SpringConfigs.CASINO_SPORTSBOOK_SWITCHER] ===
  'sportsbook';

export const esportsEnabled =
  AccountTypeSelector[SpringConfigs.CASINO_SPORTSBOOK_SWITCHER] === 'esports';

export const casinoEnabled =
  AccountTypeSelector[SpringConfigs.CASINO_SPORTSBOOK_SWITCHER] === 'casino';

//please dont remove
// export const UKGambling =
//   'http://www.gamblingcommission.gov.uk/for-the-public/Your-rights/Protection-of-customer-funds.aspx ';

export const PaymentSystemId = [353, 1483];

export const subscriptionsHash = '#/subscriptions/?userId';

export const casinoLangPrefix =
  window.currentLanguageObject?.casinoLangPrefix ||
  SpringConfigs.LANGUAGE_PREFIX;

export const StatisticsRedirectPath = '#/sport/?';

export const RedirectToSingleViewRoute = 'redirect-to-game';

export const FIELDS_FOR_SESSION_LIMITS = [
  'DailyDuration',
  'WeeklyDuration',
  'MonthlyDuration',
  'max_session_duration_daily',
  'max_session_duration_weekly',
  'max_session_duration_monthly'
];

export const INPUT_DONT_ALLOW_SYMBOL = ['e', 'E', '-', '+', '.'];

export const fulgurPayUrl = `https://exchange.fulgurpay.com/?refId=${SpringConfigs.PARTNER_ID}&lang=${SpringConfigs.CURRENT_LANGUAGE}`;
export const CALCULATE_POWER_OF_10 = 10;

export const CalculationNumbers = {
  NUMBER_TYPE_4: 4,
  NUMBER_TYPE_6: 6,
  NUMBER_TYPE_8: 8,
  NUMBER_TYPE_24: 24,
  NUMBER_TYPE_25: 25,
  NUMBER_TYPE_44: 44,
  NUMBER_TYPE_52: 52,
  NUMBER_TYPE_78: 78
};

export const recaptchaVersionValue = {
  V2: 2,
  V3: 3
};
export enum EAnimationDuration {
  MS300 = 300,
  MS500 = 500
}

export const CMS_DOMAIN_URL = 'https://cmsbetconstruct.com';
export const HTTP_REQUEST_SUCCESS_STATUS = 200;
