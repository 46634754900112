import {
  EForecastTricastMarkets,
  ERacingTabsTimeFilters
} from 'interfaces/racing';
import { BET_TYPES } from '../betslip/bet-types';

export const MAX_DAY_VALUE = 6;
export const DAY_AFTER_TOMORROW_DAYS_NUMBER_FROM_TODAY = 2;
export const TWO_DAYS_AFTER_TOMORROW_DAYS_NUMBER_FROM_TODAY = 3;
export const WEEK_DAYS_NUMBER = 7;
export const RESULTS_NOT_FOR_SHOW_EVENT_NAME = 'vacant';
export const WINNER_MARKET_TYPE_NAME = 'Winner';
export const MATCH_BET_MARKET_TYPE_NAME = 'matchBet';
export const TRAP_WINNER_MARKET_TYPE_NAME = 'TrapWinner';
export const PMU_HORSE_RACING = 181;
export const RESULTS_ALL_GAMES_INITIAL_COUNT = 2;
export const RESULTS_ALL_GAMES_LOAD_MORE_COUNT = 1;
export const UPCOMING_GAMES_CAROUSEL_GAMES_COUNT = 5;
export const UPCOMING_GAMES_EVENTS_COUNT = 3;
export const NEXT_OFF_GAMES_COUNT = 20;
export const WINNERS_MAX_COUNT = 3;
export const COMPETITION_GAMES_SKELETON_NUMBER = 3;
export const COMPETITION_SKELETON_NUMBER = 2;
export const INFINITY_SCROLL_SCROLL_THRESHOLD = 0.6;
export const NEXT_OFF_COLUMN_SIZE = 4;
export const MAIN_CONTAINER_COLUMN_SIZE = 15;
export const BETSLIP_COLUMN_SIZE = 5;

export const FORECAST_TRICAST_SKELETON_BUTTONS_LENGTH = {
  [EForecastTricastMarkets.FORECAST]: 3,
  [EForecastTricastMarkets.TRICAST]: 4
};
export const COMPETITION_GAMES_TIME_SKELETON_NUMBER = 4;
export const RACES_FAVORITE = 'Favourite';
const RACES_SECOND_FAVORITE = '2nd Favourite';
export const RACES_ALL_GAMES = 'allGames';

export const RacingForecastTricast = {
  STRAIT_FORECAST: BET_TYPES.STRAIGHT_FORECAST,
  REVERSE_FORECAST: BET_TYPES.REVERSE_FORECAST,
  COMBINATION_FORECAST: BET_TYPES.COMBINATION_FORECAST,
  STRAIT_TRICAST: BET_TYPES.STRAIGHT_TRICAST,
  COMBINATION_TRICAST: BET_TYPES.COMBINATION_TRICAST
};

export const HORSE_RACING = {
  id: 31,
  alias: 'HorseRacing'
};

export const GREYHOUNDS_RACING = {
  id: 184,
  alias: 'SISGreyhound'
};

export const FUTURE_RACES = {
  id: 180,
  alias: 'FutureHorseRacing'
};

export const SPECIAL_RACES = {
  id: 153,
  alias: 'HorseRacingSpecials'
};

export const RACING_SPORT_IDS = [
  HORSE_RACING.id,
  GREYHOUNDS_RACING.id,
  FUTURE_RACES.id,
  SPECIAL_RACES.id
];

export const RACING_SPORT_ALIAS: Record<string, number> = {
  [HORSE_RACING.alias]: HORSE_RACING.id,
  [GREYHOUNDS_RACING.alias]: GREYHOUNDS_RACING.id,
  [FUTURE_RACES.alias]: FUTURE_RACES.id,
  [SPECIAL_RACES.alias]: SPECIAL_RACES.id
};

export const RACES_FAVORITES = [RACES_FAVORITE, RACES_SECOND_FAVORITE];

const RACING_DATE_TABS_KEYS: string[] = [
  ERacingTabsTimeFilters.TODAY,
  ERacingTabsTimeFilters.TOMORROW,
  ERacingTabsTimeFilters.DAY_AFTER_TOMORROW,
  ERacingTabsTimeFilters.TWO_DAYS_AFTER_TOMORROW
];

const RACING_SPORTS_TABS_KEYS: string[] = [
  FUTURE_RACES.alias,
  SPECIAL_RACES.alias
];

export const RACING_HORSE_SPORT_VIEW_TABS: string[] = [
  ...RACING_DATE_TABS_KEYS,
  ...RACING_SPORTS_TABS_KEYS
];

export const RACING_SPORT_EVENTS_SKELETON_LENGTHS = {
  [HORSE_RACING.alias]: 10,
  [GREYHOUNDS_RACING.alias]: 6,
  [FUTURE_RACES.alias]: 18,
  [SPECIAL_RACES.alias]: 1
};

export const RACING_LOGO_SIZES = {
  [HORSE_RACING.alias]: 32,
  [GREYHOUNDS_RACING.alias]: 24
};
export const USA_MEXICO_REGION_ALIASES = ['usa', 'mexico'];
