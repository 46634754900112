import {
  getUrlParams,
  insertUrlParam
} from 'utils/bom-dom-manipulation/url-actions';
import { equalArrays } from 'utils/collection-manipulation/equal-arrays';
import RidGenerator from 'utils/swarm/rid-generator';
import {
  CasinoInitialData,
  EStatusOptionsTypes,
  ETournamentProductTypeIds
} from 'interfaces/casino-data';
import { ActionType } from 'interfaces/generic';
import { CasinoDataActionTypes } from 'store/action-types/casino-data';

const initialData: CasinoInitialData = {
  categories: [],
  providers: [],
  casinoOptionsUID: RidGenerator.rand(),
  cachedGames: {
    activeCategories: [],
    activeProviders: []
  },
  cachedTournaments: {
    options: {
      status: [EStatusOptionsTypes.LIVE, EStatusOptionsTypes.UPCOMING],
      registration: null,
      entryType: null
    },
    tournamentsData: {
      [ETournamentProductTypeIds.CASINO]: [],
      [ETournamentProductTypeIds.SPORT]: []
    }
  },
  casinoGameTournaments: null,
  JackpotData: {},
  SportsbookJackpotData: {},
  casinoGameJackpot: {},
  selectedCasinoGame: null,
  joinedTournaments: [],
  selectedCasinoCategoryId: '',
  isLoaded: true,
  tournamentActiveProductTypeId: null,
  onlinePlayersCrashGamesIds: [],
  onlinePlayersCrashGamesData: {}
};

export const casinoData = (
  state = initialData,
  action: ActionType
): CasinoInitialData => {
  switch (action.type) {
    case CasinoDataActionTypes.SET_CASINO_OPTIONS:
      return {
        ...state,
        ...action.payload,
        casinoOptionsUID: RidGenerator.rand()
      };

    case CasinoDataActionTypes.RESET_CASINO_OPTIONS:
      return {
        ...initialData,
        cachedTournaments: { ...state.cachedTournaments }
      };

    // FIXME: Refactor complex logic in this reducer.
    //        Reducers should be as simple as possible. All business logic
    //        should be encapsulated in actions or container components.
    //        Also reducer should be pure. Which means side effects not allowed here.
    //        So we need to move URL params insertion somewhere else.
    case CasinoDataActionTypes.TOGGLE_ACTIVE_CATEGORY: {
      const options: any = {};
      let activeCategoriesCopy = [];

      if (state.cachedGames.activeCategories.includes(action.payload)) {
        return state;
      } else {
        activeCategoriesCopy = [action.payload];

        options.activeCategories = activeCategoriesCopy;
      }

      insertUrlParam({
        category: options.activeCategories.length
          ? options.activeCategories.join(',')
          : 'all',
        provider: state.cachedGames.activeProviders.length
          ? state.cachedGames.activeProviders.join(',')
          : 'all'
      });

      const finalState = {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          ...options
        }
      };

      if (action.payload.shouldUpdateUID) {
        finalState.casinoOptionsUID = RidGenerator.rand();
      }

      return finalState;
    }

    case CasinoDataActionTypes.RESET_ACTIVE_CATEGORIES: {
      const searchParams = getUrlParams(['category']);

      insertUrlParam({
        category: 'all',
        provider: state.cachedGames.activeProviders.length
          ? state.cachedGames.activeProviders.join(',')
          : 'all'
      });

      if (
        !searchParams.category ||
        (searchParams.category && searchParams.category[0] === 'all')
      ) {
        return state;
      }

      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          activeCategories: []
        }
      };
    }

    case CasinoDataActionTypes.TOGGLE_ACTIVE_PROVIDER: {
      let activeProvidersCopy = [];

      if (state.cachedGames.activeProviders.includes(action.payload)) {
        if (state.cachedGames.activeProviders.length === 1) {
          activeProvidersCopy = initialData.cachedGames.activeProviders;
        } else {
          activeProvidersCopy = [...state.cachedGames.activeProviders];
          activeProvidersCopy.splice(
            state.cachedGames.activeProviders.indexOf(action.payload),
            1
          );
        }
      } else {
        if (state.cachedGames.activeProviders.length === 0) {
          activeProvidersCopy = [action.payload];
        } else {
          activeProvidersCopy = [
            ...state.cachedGames.activeProviders,
            action.payload
          ];
        }
      }

      insertUrlParam(
        'provider',
        activeProvidersCopy.length ? activeProvidersCopy.join(',') : 'all'
      );

      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          activeProviders: activeProvidersCopy
        },
        casinoOptionsUID: RidGenerator.rand()
      };
    }

    case CasinoDataActionTypes.RESET_ACTIVE_PROVIDERS:
      insertUrlParam('provider', 'all');

      return {
        ...state,
        cachedGames: {
          ...state.cachedGames,
          activeProviders: []
        },
        casinoOptionsUID: RidGenerator.rand()
      };

    case CasinoDataActionTypes.SET_CASHED_TOURNAMENTS_DATA:
      return {
        ...state,
        cachedTournaments: {
          ...state.cachedTournaments,
          tournamentsData: {
            ...state.cachedTournaments.tournamentsData,
            [action.payload.productTypeId]: action.payload.data
          }
        }
      };

    case CasinoDataActionTypes.SET_CASHED_TOURNAMENTS_OPTIONS:
      return {
        ...state,
        cachedTournaments: {
          ...state.cachedTournaments,
          options: action.payload
        }
      };

    case CasinoDataActionTypes.SET_ACTIVE_OPTIONS: {
      const optionsCopy: any = {};
      const isActiveCategoriesExist = !!action.payload.activeCategories;
      const isActiveProvidersExist = !!action.payload.activeProviders;

      const isActiveCategoriesSame = equalArrays(
        action.payload.activeCategories,
        state.cachedGames.activeCategories
      );

      const isActiveProvidersSame = equalArrays(
        action.payload.activeProviders,
        state.cachedGames.activeProviders
      );

      if (isActiveCategoriesExist && !isActiveCategoriesSame) {
        optionsCopy.activeCategories = action.payload.activeCategories;
      }

      if (isActiveProvidersExist && !isActiveProvidersSame) {
        optionsCopy.activeProviders = action.payload.activeProviders;
      }

      const finalState = {
        ...state,
        cachedGames: { ...state.cachedGames, ...optionsCopy }
      };

      if (action.payload.shouldUpdateUID) {
        finalState.casinoOptionsUID = RidGenerator.rand();
      }

      return finalState;
    }

    case CasinoDataActionTypes.SET_CASINO_GAME_TOURNAMENTS:
      return {
        ...state,
        casinoGameTournaments: action.payload
      };

    case CasinoDataActionTypes.SET_CASINO_JACKPOT:
      return {
        ...state,
        JackpotData: {
          ...state.JackpotData,
          [action.payload.Id]: action.payload
        }
      };

    case CasinoDataActionTypes.RESET_CASINO_JACKPOT:
      return {
        ...state,
        JackpotData: {}
      };

    case CasinoDataActionTypes.SET_SPORTSBOOK_JACKPOT:
      return {
        ...state,
        SportsbookJackpotData: {
          ...state.SportsbookJackpotData,
          [action.payload.Id]: action.payload
        }
      };

    case CasinoDataActionTypes.RESET_SPORTSBOOK_JACKPOT:
      return {
        ...state,
        SportsbookJackpotData: {}
      };

    case CasinoDataActionTypes.SET_CASINO_GAME_JACKPOT:
      return {
        ...state,
        casinoGameJackpot: {
          ...state.casinoGameJackpot,
          [action.payload.Id]: action.payload
        }
      };
    case CasinoDataActionTypes.RESET_CASINO_GAME_JACKPOT:
      return {
        ...state,
        casinoGameJackpot: {}
      };

    case CasinoDataActionTypes.SET_SELECTED_CASINO_GAME:
      return {
        ...state,
        selectedCasinoGame: action.payload
      };
    case CasinoDataActionTypes.SET_JOINED_TOURNAMENTS:
      return {
        ...state,
        joinedTournaments: [...state.joinedTournaments, action.payload]
      };

    case CasinoDataActionTypes.SET_SELECTED_CASINO_CATEGORY_ID:
      return {
        ...state,
        selectedCasinoCategoryId: action.payload
      };

    case CasinoDataActionTypes.SET_IS_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      };
    case CasinoDataActionTypes.SET_TOURNAMENT_ACTIVE_PRODUCT_TYPE_ID:
      return {
        ...state,
        tournamentActiveProductTypeId: action.payload
      };

    case CasinoDataActionTypes.SET_ONLINE__PLAYERS_CRASH_GAMES_IDS:
      return {
        ...state,
        onlinePlayersCrashGamesIds: action.payload
      };

    case CasinoDataActionTypes.SET_ONLINE_PLAYERS_CRASh_GAMES_DATA:
      return {
        ...state,
        onlinePlayersCrashGamesData: action.payload
      };

    default: {
      return state;
    }
  }
};
