import dayjs from 'dayjs';
import { WINNER_MARKETS } from 'utils/constants/sportsbook/grouped-market-names';

export const events = {
  event: [
    'name',
    'id',
    'price',
    'base',
    'order',
    'type_1',
    'extra_info',
    'display_column',
    'ew_allowed'
  ]
};

export const markets = {
  market: [
    'type',
    'name',
    'order',
    'main_order',
    'type',
    'id',
    'base',
    'express_id',
    'col_count',
    'group_id',
    'group_name',
    'cashout',
    'point_sequence',
    'sequence',
    'is_new',
    'market_type',
    'extra_info'
  ]
};

export const marketTypes = [
  'P1P2',
  'P1XP2',
  'MatchResult',
  'MatchWinner',
  '1X12X2',
  'BothTeamsToScore',
  'DrawNoBet',
  'MatchTotal',
  'TotalGamesOver/Under',
  'TotalofSets',
  'BoutBetting',
  'MatchWinner3Way',
  'MoneyLine'
];

export const sports = {
  sport: ['name', 'alias', 'id', 'type', 'order']
};

export const regions = {
  region: ['name', 'alias', 'order', 'id', 'competition']
};

export const competitions = {
  competition: ['name', 'order', 'id']
};

export const gameSportcastId = ['sportcast_id'];
export const gameSportcastIdWhere = { '@gt': 1 };

export const gameList = {
  game: [
    [
      'id',
      'type',
      'team1_name',
      'team2_name',
      'team1_id',
      'team2_id',
      'order',
      'start_ts',
      'markets_count',
      'is_blocked',
      'info',
      'tv_type',
      'exclude_ids',
      'show_type',
      'text_info',
      'add_info_name',
      'tv_info',
      'is_stat_available',
      'video_id',
      'video_id2',
      'video_id3',
      'sportcast_id',
      'match_length',
      'live_events'
    ]
  ]
};

export const preMatchCompetitionWhatObj = {
  game: [
    [
      'id',
      'team1_name',
      'team2_name',
      'team1_id',
      'team2_id',
      'order',
      'start_ts',
      'markets_count',
      'is_blocked',
      'show_type',
      'sportcast_id',
      'sport_id',
      'is_stat_available'
    ]
  ],
  market: [
    'name',
    'order',
    'type',
    'id',
    'base',
    'express_id',
    'col_count',
    'main_order'
  ],
  event: ['name', 'id', 'price', 'base', 'order', 'type_1']
};

export const topLeaguesWhere = {
  game: {
    '@or': [{ type: { '@in': [0, 2] } }, { visible_in_prematch: 1, type: 1 }]
  },
  competition: { favorite: true }
};

export const topMatchesWhere = {
  game: {
    promoted: true,
    '@or': [{ type: { '@in': [0, 2] } }, { visible_in_prematch: 1, type: 1 }]
  }
};

export const createTodayEventsWhere = () => {
  return {
    game: {
      start_ts: {
        '@gt': dayjs().unix(),
        '@lt': dayjs().endOf('day').unix()
      },
      '@or': [{ type: { '@in': [0, 2] } }, { visible_in_prematch: 1, type: 1 }]
    }
  };
};

export const selectedGamesWhatObj = {
  sport: ['alias', 'id', 'type'],
  region: ['name', 'alias', 'id'],
  competition: ['id', 'name'],
  game: [
    [
      'id',
      'team1_id',
      'team1_name',
      'team2_id',
      'team2_name',
      'start_ts',
      'is_blocked',
      'info',
      'is_live',
      'type',
      'is_started',
      'is_stat_available'
    ]
  ],
  market: ['name', 'type', 'id', 'base', 'express_id', 'group_id'],
  event: ['name', 'type', 'id', 'price', 'base', 'order']
};

export const selectedGameWhere = (gameIds: Record<string, any>): Object => {
  return {
    game: {
      id: {
        '@in': gameIds
      },
      type: {
        '@in': [0, 1, 2]
      }
    },
    market: {
      type: {
        '@in': WINNER_MARKETS
      }
    }
  };
};

export const selectedGamesRandomWhere = (): Object => {
  return {
    sport: {
      type: {
        '@ne': 1
      },
      alias: { '@in': ['Soccer', 'Tennis'] }
    },
    game: {
      '@or': [
        {
          type: {
            '@in': [0, 2]
          }
        },
        {
          visible_in_prematch: 1
        }
      ],
      '@limit': 5,
      start_ts: {
        '@gt': dayjs().unix(),
        '@lt': dayjs().endOf('day').unix()
      },
      show_type: {
        '@nin': ['OUTRIGHT']
      },
      '@node_limit': 1
    },
    market: {
      type: {
        '@in': ['P1XP2', 'P1P2', 'MatchWinner', 'MatchResult']
      }
    }
  };
};

export const getSportsbookSingleGameRelations = (
  params: Record<string, string[]> = {},
  includePrematchExpressId?: boolean,
  isPayoutAvailable?: boolean
): object => {
  return {
    sport: ['id', 'name', 'alias'],
    competition: ['name', 'id'],
    game: [
      'id',
      'type',
      'team1_name',
      'team2_name',
      'team1_id',
      'team2_id',
      'info',
      'start_ts',
      'markets_count',
      'exclude_ids',
      'team1_reg_name',
      'team2_reg_name',
      'video_id',
      'video_id2',
      'stats',
      'score1',
      'score2',
      'show_type',
      'text_info',
      'is_stat_available',
      'is_started',
      'add_info_name',
      'tv_info',
      'sportcast_id',
      'match_length',
      'live_events',
      'is_blocked',
      'sport_alias'
    ],
    market: [
      ...markets.market,
      ...(includePrematchExpressId ? ['prematch_express_id'] : []),
      ...(isPayoutAvailable ? ['has_early_payout'] : [])
    ],
    event: events.event,
    ...params
  };
};

export const europeanLiveDataBySportRelations = {
  sport: sports.sport,
  competition: competitions.competition,
  region: regions.region,
  game: [
    [
      'id',
      'start_ts',
      'team1_name',
      'team2_name',
      'team1_id',
      'team2_id',
      'type',
      'info',
      'events_count',
      'events',
      'markets_count',
      'is_started',
      'is_blocked',
      'stats',
      'tv_type',
      'video_id',
      'video_id2',
      'video_id3',
      'partner_video_id',
      'is_stat_available',
      'game_number',
      'game_info',
      'last_event',
      'text_info'
    ]
  ],
  market: [
    'id',
    'name',
    'type',
    'order',
    'main_order',
    'cashout',
    'col_count',
    'display_key',
    'display_sub_key',
    'market_type',
    'name_template',
    'point_sequence',
    'sequence',
    'extra_info',
    'express_id'
  ],
  event: ['name', 'id', 'price', 'type', 'order', 'base']
};

export const multiviewDataCardsSportRelations = {
  sport: sports.sport,
  competition: competitions.competition,
  region: regions.region,
  game: [
    'id',
    'start_ts',
    'team1_id',
    'team2_id',
    'team1_name',
    'team2_name',
    'type',
    'info',
    'stats',
    'live_events',
    'sport_alias'
  ]
};

export const multiviewDataBoardsGameRelations = {
  sport: sports.sport,
  region: regions.region,
  competition: competitions.competition,
  market: [
    'name',
    'order',
    'main_order',
    'type',
    'id',
    'base',
    'express_id',
    'col_count',
    'group_id',
    'group_name',
    'cashout',
    'point_sequence',
    'sequence',
    'is_new',
    'market_type',
    'extra_info',
    'has_early_payout'
  ],
  event: [
    'id',
    'name',
    'type',
    'price',
    'type_1',
    'order',
    'base',
    'ew_allowed'
  ],
  game: [
    'id',
    'team1_id',
    'team2_id',
    'team1_name',
    'team2_name',
    'info',
    'markets_count',
    'stats',
    'starts_ts',
    'type',
    'is_blocked',
    'tv_type',
    'video_id',
    'video_id2',
    'is_feed_available',
    'text_info',
    'live_events',
    'game_number',
    'sport_alias',
    'is_live',
    'is_stat_available'
  ]
};

export const advancedEventsLayoutRelations = {
  game: [
    'id',
    'team1_id',
    'team2_id',
    'team1_name',
    'team2_name',
    'info',
    'stats',
    'start_ts',
    'type',
    'is_blocked',
    'tv_type',
    'video_id',
    'video_id2',
    'is_feed_available',
    'text_info',
    'game_number',
    'sport_alias',
    'is_live',
    'is_stat_available',
    '_parent_id',
    'region_alias',
    'show_type'
  ]
};

export const MAIN_MARKETS_TYPE = 'Main Markets';

export const PAYOUT_AVAILABLE_SPORTS = ['Soccer', 'Basketball'];

export const PREMATCH_GAME_TYPE_FOR_SWARM = {
  '@or': [{ type: { '@in': [0, 2] } }, { visible_in_prematch: 1 }]
};
