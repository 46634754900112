// prettier-ignore
export const NewCasinoActionTypes = {
  SET_CASINO_ORIGINAL_CATEGORIES: 'SET_CASINO_ORIGINAL_CATEGORIES',
  SET_CASINO_CUSTOM_CATEGORIES: 'SET_CASINO_CUSTOM_CATEGORIES',
  SET_CASINO_ORIGINAL_CATEGORIES_SET: 'SET_CASINO_ORIGINAL_CATEGORIES_SET',
  SET_CASINO_CATEGORY_PROVIDER_IDS: 'SET_CASINO_CATEGORY_PROVIDER_IDS',
  SET_CAN_REQUEST_CATEGORIES_NESTED_DATA: 'SET_CAN_REQUEST_CATEGORIES_NESTED_DATA',
  SET_CAN_REQUEST_PROVIDERS_NESTED_DATA: 'SET_CAN_REQUEST_PROVIDERS_NESTED_DATA',
  SET_CASINO_ORIGINAL_CATEGORIES_SET_LEFT_SIDEBAR: 'SET_CASINO_ORIGINAL_CATEGORIES_SET_LEFT_SIDEBAR',
  SET_LAST_PLAYED_CAT_SETTINGS: 'SET_LAST_PLAYED_CAT_SETTINGS',
  SET_CATEGORIES_CUSTOM_IDS: 'SET_CATEGORIES_CUSTOM_IDS',
  ADD_CACHED_GAME: 'ADD_CACHED_GAME',
  ADD_CACHED_GAME_ID: 'ADD_CACHED_GAME_ID',
  SET_CACHED_GAME_ACTIVE: 'SET_CACHED_GAME_ACTIVE',
  SET_CACHED_GAME_INACTIVE: 'SET_CACHED_GAME_INACTIVE',
  SET_CACHED_GAMES_INACTIVE: 'SET_CACHED_GAMES_INACTIVE',
  SET_CACHED_GAME_ORDER: 'SET_CACHED_GAME_ORDER',
  SET_CACHED_GAME_MODE: 'SET_CACHED_GAME_MODE',
  SET_GAMES_CONFIG: 'SET_GAMES_CONFIG',
  SET_ENHANCED_PARAMS:'SET_ENHANCED_PARAMS',
  SET_GAMES_TOTAL_COUNT:'SET_GAMES_TOTAL_COUNT',
};
