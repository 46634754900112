import { ReactElement } from 'react';
import cc from 'classcat';
import { isMobile } from 'utils/is-mobile';
import { GlobalIcon } from 'components/GlobalIcon';
import './index.less';

const icons: {
  [key: string]: {
    name: string;
    size: number;
    className?: string;
  };
} = {
  default: {
    name: 'noBonusesNoMarkets',
    size: 156
  },
  coupon: {
    name: 'coupon',
    size: 80,
    className: 'coupon-icon'
  },
  emptyFavorites: {
    name: 'emptyFavorites',
    size: 152
  },
  emptyMultiples: {
    name: 'emptymultiple',
    size: 170
  },
  emptyMultiviewCards: {
    name: 'noBonusesNoMarkets',
    size: 50
  },
  noRewards: {
    name: 'noRewards',
    size: 190
  },
  noNftRewards: {
    name: 'noNftRewards',
    size: 205
  },
  bonuses: {
    name: 'bonuses',
    size: 127
  },
  noBankAccount: {
    name: 'nobankaccount',
    size: 270,
    className: 'noBankAccount'
  }
};

export const NoDataIconWrapper = ({
  icon,
  applyEmptyBoxStyles = true
}: {
  icon?: string;
  applyEmptyBoxStyles?: boolean;
}): ReactElement => {
  const iconData = icon && icons[icon] ? icons[icon] : icons.default;

  return (
    <div
      className={cc([
        {
          emptyDateBox__mobile: isMobile(),
          [`emptyDateBox__${iconData.className}`]: !!iconData.className,
          emptyDateBox: applyEmptyBoxStyles
        }
      ])}
    >
      <GlobalIcon
        className={iconData.className || ''}
        lib="generic"
        name={iconData.name}
        theme="colored"
        size={iconData.size}
      />
    </div>
  );
};
