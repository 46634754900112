import {
  CSSProperties,
  FC,
  ReactElement,
  Suspense,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import cc from 'classcat';
import localStorage from 'utils/bom-dom-manipulation/local-storage';
import { sortByOrder } from 'utils/collection-manipulation/array-sort-by';
import { EIconSize } from 'utils/constants/app/ui';
import {
  competitions,
  regions,
  sports
} from 'utils/constants/sportsbook/data-fields';
import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { lazy } from 'utils/generic/lazy';
import {
  getMountPath,
  mountPoint,
  pageUrl,
  sbRedirect,
  shouldRedirect
} from 'utils/generic/route-manipulation';
import { storageKeyName } from 'utils/generic/storage-key-name';
import { createArray } from 'utils/helpers/create-fake-array';
import { isMobile } from 'utils/is-mobile';
import { getLivePreMatchSportTypes } from 'utils/sportsbook/sport-data';
import { modifyAndGetSportsAndGroupingsData } from 'utils/sportsbook/sports-menu';
import RidGenerator from 'utils/swarm/rid-generator';
import { ENotRealSports } from 'interfaces/not-real-sports';
import {
  IMenuGroupWithSports,
  ISportMenuGroup,
  Region,
  Sport
} from 'interfaces/sportsbook-data-levels';
import { executeCommand } from 'services/get-data-level';
import { getSportListGrouping } from 'services/sportsbook';
import { SportsListCategoriesView } from './__partials/SportsListCategoriesView';
import { CryptoSideBarSportList } from './CryptoSideBarSportList';
import { SportListSkeleton } from './SportListSkeleton';
import { SportRegions } from './SportRegions';
import { EventCollapse } from 'components/EventCollapse';
import { GlobalIcon } from 'components/GlobalIcon';
import { NavigationCollapse } from 'components/NavigationCollapse';
import { GroupWithSports } from 'components/Shared/SportsListContent/GroupWithSports';
import { WrapperHandler } from 'components/WrapperHandler';
import { SportItemSkeleton } from 'newcomponents/Shared/sportsbook/TopMatchesSportList/SportItem';
import { SportsFavoriteButton } from 'newcomponents/UI/Menu/components/FavoriteButton';
import { Skeleton } from 'newcomponents/UI/Skeleton';
import { useSportsFavoriteClickHandler } from 'hooks/favorite/useSportsFavoriteClickHandler';
import { useBoostedOdds } from 'hooks/sportsbook/useBoostedOdds';
import { useGetUserSportOrder } from 'hooks/sportsbook/useGetUserSportOrder';
import { useSortFavoriteSportsOrdering } from 'hooks/sportsbook/useSortFavoriteSportsOrdering';
import { useMounted } from 'hooks/useMounted';
import { useSbRouteMatch } from 'newhooks/generic/useSbRouteMatch';
import { setSportsGroupingData } from 'store/actions/new-sportsbook';
import {
  setCashedSportData,
  setSportsListData,
  setTournamentSportIds
} from 'store/actions/sport-data';
import { getFavoriteSportIds } from 'store/selectors/fav-data';
import {
  getSportGroupingsData,
  getUserSportOrder
} from 'store/selectors/new-sportsbook';
import {
  getCashedSport,
  getScrollToCompetitionId,
  getTournamentSportIds
} from 'store/selectors/sport-data';
import { getIsLoggedIn } from 'store/selectors/user-data';
import { MobileSportsbookEventCollapseSkeleton } from '../MobileSportsbookEventCollapseSkeleton';
import './index.less';

const { TopMatchesSportList } = lazy(
  () =>
    import(
      /* webpackChunkName: "sportsbook-top-matches-sport-list" */ 'newcomponents/Shared/sportsbook/TopMatchesSportList'
    )
);

const { FavoriteSideBarSection } = lazy(
  () =>
    import(
      /* webpackChunkName: "favorite-without-search" */ 'elements/SportsbookLeftSideBar/partials/FavoriteSideBarSection'
    )
);

type TSportListResponse = { data: { sport: Record<string, Sport> } };
type TSportListMockedResponse = { sport: Record<string, Sport> };

if (SpringConfigs.IS_RTL) {
  import('./rtl.less');
}

export enum ESportsListLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical'
}

export interface ISportListConfigs {
  boostedOddsEnabled?: boolean;
  topLeagueEnabled?: boolean;
  topMatchesEnabled?: boolean;
  outRightEnabled?: boolean;
  todayEventsEnabled?: boolean;
  multiplesEnabled?: boolean;
  olympicCompetitionEnabled?: boolean;
  BBSportsList?: boolean;
  sportType: string;
  couponBuilderSportsList?: boolean;
  layoutSportsList: ESportsListLayout;
}

type Props = {
  wrapperStyles?: CSSProperties;
  compactView?: boolean;
  configs?: ISportListConfigs;
  clickHandler?: (
    e: SyntheticEvent,
    regionAlias: string,
    competitionId: number,
    sportId: number,
    sportAlias: string
  ) => void;
  cryptoSideBar?: boolean;
  openFromSideBarPopup?: boolean;
  isCryptoSideBarOpened?: boolean;
};

const CRYPTO_SIDE_BAR_COUNT = 10;
const SPORT_LIST_SKELETON_COUNT = 15;

export const SportListContent: FC<Props> = ({
  wrapperStyles,
  compactView,
  clickHandler,
  configs,
  cryptoSideBar,
  openFromSideBarPopup,
  isCryptoSideBarOpened
}): ReactElement | ReactElement[] => {
  const scrollToCompetitionId = useSelector(getScrollToCompetitionId);
  const cashedSport = useSelector(getCashedSport)?.prematchAllSports as Sport[];
  const favoriteSportIds = useSelector(getFavoriteSportIds);
  const userSportOrder = useSelector(getUserSportOrder);
  const tournamentSportIds = useSelector(getTournamentSportIds);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { orderSports, orderIsLoading } = useGetUserSportOrder();
  const history = useHistory();
  const sportGroupsData = useSelector(getSportGroupingsData).prematch;

  const [sportsData, setSportsData] = useState<null | Sport[]>(cashedSport);
  const [loading, setLoading] = useState(true);
  const [nonPersonalisedSportsData, setNonPersonalisedSportsData] = useState<
    null | Sport[]
  >(cashedSport);

  const routeParams = useSbRouteMatch(
    `${mountPoint(window.getPathname())}/:sport?/:region?/:competition?/:game?`
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mounted } = useMounted();
  useBoostedOdds(!!configs?.boostedOddsEnabled);

  const isCoupon = routeParams?.params?.sport === ENotRealSports.coupon;

  useEffect(() => {
    const groupingDataPromise = new Promise<ISportMenuGroup[]>(resolve => {
      const isGroupingAvailable = Boolean(+SpringConfigs.SPORTS_GROUPING);

      if (isGroupingAvailable) {
        getSportListGrouping(
          groupingData => {
            resolve(groupingData);
          },
          () => {
            resolve([]);
          }
        );
      } else {
        resolve([]);
      }
    });

    const sportsListDataPromise = new Promise<TSportListResponse>(resolve => {
      setSportsData(null);

      executeCommand(
        RidGenerator.gForCommand(
          `SportListFrom${cryptoSideBar ? 'Crypto' : 'Left'}SideBar`
        ),
        {
          sport: sports.sport
        },
        {
          sport: {
            type: { '@in': getLivePreMatchSportTypes(configs?.sportType) },
            ...(tournamentSportIds?.length
              ? {
                  id: {
                    '@in': tournamentSportIds
                  }
                }
              : {})
          },
          game: {
            '@or': [
              { type: { '@in': [0, 2] } },
              { visible_in_prematch: 1, type: 1 }
            ]
          }
        },
        (sportListData: TSportListResponse) => {
          resolve(sportListData);
        }
      );
    });

    const dataResponseCallback = (
      sportsDataResponse: TSportListResponse | TSportListMockedResponse,
      groupData: ISportMenuGroup[]
    ) => {
      if ('sport' in sportsDataResponse && SpringConfigs.MOCKED_DATA) {
        setSportsData(Object.values(sportsDataResponse?.sport));
        setLoading(false);

        return;
      }

      const sportsData = (sportsDataResponse as TSportListResponse)?.data
        ?.sport;

      if (sportsDataResponse && mounted.current) {
        if (groupData && groupData.length) {
          const result = modifyAndGetSportsAndGroupingsData(
            sportsData,
            groupData
          );

          const groupsWithSports = result[1];

          dispatch(setSportsGroupingData({ prematch: groupsWithSports }));
          groupsWithSports.forEach(group => {
            group.sports.forEach(({ id }) => {
              delete sportsData[id as string];
            });
          });
        }

        const sortedData = sortByOrder(
          Object.values(orderSports(Object.values(sportsData)) || {})
        );

        setNonPersonalisedSportsData(sortedData);

        setSportsData(sortedData);
        setLoading(false);
        dispatch(
          setCashedSportData({
            data: sortedData,
            alias: `prematchAllSports`
          })
        );
      }
    };

    Promise.all([sportsListDataPromise, groupingDataPromise]).then(
      ([sportsDataResponse, groupData]) =>
        dataResponseCallback(sportsDataResponse, groupData)
    );
  }, [tournamentSportIds?.length]);

  useEffect(() => {
    if (sportsData && SpringConfigs.RENDER_COMPETITIONS_IN_DOM) {
      const sportIds = sportsData.map(sport => sport.id);
      executeCommand(
        RidGenerator.gForCommand(),
        {
          region: regions.region,
          competition: competitions.competition,
          sport: ['alias']
        },
        {
          sport: { id: { '@in': sportIds } },
          game: {
            '@or': [
              { type: { '@in': [0, 2] } },
              { visible_in_prematch: 1, type: 1 }
            ]
          }
        },
        (data: {
          data: {
            sport: Record<
              number,
              {
                alias: string;
                region: Record<string, Region>;
              }
            >;
          };
        }) => {
          if (data?.data?.sport && mounted.current) {
            Object.values(data.data.sport).forEach(sport => {
              dispatch(
                setSportsListData(
                  sortByOrder(Object.values(sport.region)),
                  sport.alias
                )
              );
            });
          }
        }
      );
    }
  }, [sportsData]);

  useEffect(() => {
    if (!SpringConfigs.MOCKED_DATA && sportsData) {
      setSportsData(orderSports(nonPersonalisedSportsData));
      setLoading(false);
    }
  }, [userSportOrder, isLoggedIn]);

  const sortedSportsData = useSortFavoriteSportsOrdering(
    sportsData ?? undefined,
    'id'
  );

  const sportFavoriteClickHandler = useSportsFavoriteClickHandler();

  const handleCompetitionClick = useCallback(
    (url: string, sportAlias?: string) => {
      if (sportAlias) {
        localStorage.setItem(
          storageKeyName('sportsbook', 'EU_SPORT_ALIAS'),
          sportAlias
        );
      }

      sbRedirect(SpringConfigs.PAGE_URLS.prematch.concat(url), false, true);
    },
    []
  );

  const handleClickEventItem = useCallback(
    (url: string, sportType?: number) => {
      const urlPath = `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}${url}`;

      if (tournamentSportIds?.length) {
        dispatch(setTournamentSportIds(null));
      }

      if (openFromSideBarPopup) {
        const pathname = getMountPath(
          routeParams?.path?.replace(/:sport\?.*$/, url.slice(1)) || ''
        );

        if (sportType === 0 && SpringConfigs.PAGE_URLS.esport) {
          if (
            location.pathname.includes(
              `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/`
            ) &&
            pathname &&
            document.querySelector('#esport-left-sidebar-wrapper')
          ) {
            history.push(`${pathname}/all/all?type=all`);
          } else {
            sbRedirect(
              SpringConfigs.PAGE_URLS.esport.concat(urlPath),
              false,
              true
            );
          }
        } else if (
          location.pathname.includes(
            `/${SpringConfigs.SPORTSBOOK_MOUNT_PATH}/`
          ) &&
          pathname &&
          document.querySelector('.prematch__type-page')
        ) {
          history.push(`${pathname}${history.location.search}`);
        } else if (
          shouldRedirect(SpringConfigs.PAGE_URLS.prematch || '/') &&
          SpringConfigs.PAGE_URLS.prematch
        ) {
          sbRedirect(
            SpringConfigs.PAGE_URLS.prematch.concat(urlPath),
            false,
            true
          );
        } else {
          history.push(
            `${pageUrl(SpringConfigs.PAGE_URLS['prematch'] || '')}${urlPath}`
          );
        }
      } else {
        if (!shouldRedirect(SpringConfigs.PAGE_URLS.prematch)) {
          sbRedirect(SpringConfigs.PAGE_URLS.prematch, false, true);
        } else {
          sbRedirect(
            SpringConfigs.PAGE_URLS.prematch.concat(urlPath),
            false,
            true
          );
        }
      }
    },
    [routeParams?.path, tournamentSportIds?.length]
  );

  const isOpenBetBuilder =
    SpringConfigs.BET_BUILDER_SWITCHER &&
    SpringConfigs.BET_BUILDER_URL &&
    configs?.BBSportsList;

  return cryptoSideBar ? (
    !loading && !orderIsLoading ? (
      <CryptoSideBarSportList
        handleClickEventItem={handleClickEventItem}
        sportsData={sortedSportsData || []}
        configs={configs}
        open={!!isCryptoSideBarOpened}
      />
    ) : (
      <>
        {createArray(CRYPTO_SIDE_BAR_COUNT).map(key => (
          <Skeleton.Avatar
            key={key}
            size={EIconSize._30}
            active
            shape="circle"
            className="cryptoSideBar__skeleton"
          />
        ))}
      </>
    )
  ) : (
    <div className="sportsList" style={wrapperStyles}>
      {!!openFromSideBarPopup && (
        <Suspense fallback={null}>
          <Route
            path={routeParams.path}
            render={() => {
              return (
                <FavoriteSideBarSection
                  favoriteHighlight={false}
                  classNames={{ cryptoSideBar__favorite: true }}
                />
              );
            }}
          ></Route>
        </Suspense>
      )}

      {configs?.topLeagueEnabled && (
        <NavigationCollapse
          key="topLeague"
          title={t('sportsbook.topLeague')}
          icon={
            <GlobalIcon
              lib="sports"
              name="topLeague"
              size={EIconSize._24}
              className="text-color"
            />
          }
          wrapperClassName="sportsList__sport"
          headerClassName="sportsList__sport__header"
          defaultActive={false}
          dataTestId={ENotRealSports.topLeague?.toLowerCase()}
        >
          <SportsListCategoriesView
            categoryAlias={ENotRealSports.topLeague}
            fromSportsList
            openFromSideBarPopup={!!openFromSideBarPopup}
            competitionClickHandler={handleCompetitionClick}
          />
        </NavigationCollapse>
      )}

      {configs?.outRightEnabled && (
        <div
          className="sportsList__sport manual-sport-item"
          onClick={() => {
            handleClickEventItem('/outright');
          }}
        >
          <div className="sportsList__sport__header">
            <div className="style__Icon-sc-nitumr-4">
              <GlobalIcon
                lib="sports"
                name="outright"
                size={EIconSize._24}
                className="text-color"
              />
            </div>
            <span>{t('sportsbook.outright')}</span>
          </div>
          <GlobalIcon
            lib="generic"
            name="caretDownSmall"
            theme="default"
            size={EIconSize._24}
            className="sport-arrow-right"
          />
        </div>
      )}

      {configs?.todayEventsEnabled && (
        <div
          className="sportsList__sport manual-sport-item"
          onClick={() => {
            handleClickEventItem('/todayEvents');
          }}
        >
          <div className="sportsList__sport__header">
            <div className="style__Icon-sc-nitumr-4">
              <GlobalIcon
                lib="sports"
                name="todayEvents"
                prefix={{ showAfterIconIsRendered: true }}
                size={EIconSize._24}
                className="text-color"
              />
            </div>
            <span>{t('sportsbook.todayEvents')}</span>
          </div>
          <GlobalIcon
            lib="generic"
            name="caretDownSmall"
            theme="default"
            size={EIconSize._24}
            className="sport-arrow-right"
          />
        </div>
      )}

      {configs?.topMatchesEnabled && (
        <NavigationCollapse
          key="topMatches"
          title={t('sportsbook.topMatches')}
          icon={
            <GlobalIcon
              lib="sports"
              name="topMatches"
              size={EIconSize._24}
              className="text-color"
            />
          }
          wrapperClassName="sportsList__sport topMatches__wrapper"
          headerClassName="sportsList__sport__header topMatches__header"
          defaultActive={false}
          dataTestId={ENotRealSports.topMatches?.toLowerCase()}
        >
          <Suspense
            fallback={
              <div className="topMatchesSportsListWrapper">
                <SportItemSkeleton />
              </div>
            }
          >
            <TopMatchesSportList handleRedirect={handleCompetitionClick} />
          </Suspense>
        </NavigationCollapse>
      )}
      {configs?.olympicCompetitionEnabled &&
        (openFromSideBarPopup ? (
          <div
            className="sportsList__sport manual-sport-item"
            onClick={() => {
              handleClickEventItem('/olympicCompetition');
            }}
          >
            <div className="sportsList__sport__header">
              <div className="style__Icon-sc-nitumr-4">
                <GlobalIcon
                  lib="sports"
                  name={`olympicCompetition${
                    SpringConfigs.OLYMPIC_ICON_OTHER ? 'other' : ''
                  }`}
                  size={EIconSize._24}
                  className="text-color"
                />
              </div>
              <span>{t('sportsbook.olympicCompetition')}</span>
            </div>
            <GlobalIcon
              lib="generic"
              name="caretDownSmall"
              theme="default"
              size={EIconSize._24}
              className="sport-arrow-right"
            />
          </div>
        ) : (
          <NavigationCollapse
            key="olympicCompetition"
            title={t('sportsbook.olympicCompetition')}
            icon={
              <GlobalIcon
                lib="sports"
                name={`olympicCompetition${
                  SpringConfigs.OLYMPIC_ICON_OTHER ? 'other' : ''
                }`}
                size={EIconSize._24}
                className="text-color"
              />
            }
            wrapperClassName="sportsList__sport"
            headerClassName="sportsList__sport__header"
            defaultActive={false}
            dataTestId={ENotRealSports.olympicCompetition?.toLowerCase()}
          >
            <SportsListCategoriesView
              categoryAlias={ENotRealSports.olympicCompetition}
              fromSportsList
              competitionClickHandler={handleCompetitionClick}
              openFromSideBarPopup={false}
            />
          </NavigationCollapse>
        ))}

      {configs?.boostedOddsEnabled && (
        <NavigationCollapse
          key="boostedOdds"
          title={t('sportsbook.boostedOdds')}
          icon={
            <GlobalIcon
              lib="sports"
              name="boostedOdds"
              size={EIconSize._24}
              className="text-color"
            />
          }
          wrapperClassName="sportsList__sport sportsList__boosted"
          headerClassName="sportsList__sport__header"
          defaultActive={false}
          dataTestId={ENotRealSports.boostedOdds?.toLowerCase()}
        >
          <SportsListCategoriesView
            categoryAlias={ENotRealSports.boostedOdds}
            fromSportsList
            openFromSideBarPopup={!!openFromSideBarPopup}
            competitionClickHandler={handleCompetitionClick}
          />
        </NavigationCollapse>
      )}

      {configs?.multiplesEnabled && (
        <div
          className="sportsList__sport manual-sport-item"
          onClick={() => {
            handleClickEventItem('/multiples');
          }}
        >
          <div className="sportsList__sport__header">
            <div className="style__Icon-sc-nitumr-4">
              <GlobalIcon
                lib="sports"
                name="multiples"
                size={EIconSize._24}
                className="text-color"
              />
            </div>
            <span>{t('sportsbook.multiples')}</span>
          </div>
          <GlobalIcon
            lib="generic"
            name="caretDownSmall"
            theme="default"
            size={EIconSize._24}
            className="sport-arrow-right"
          />
        </div>
      )}

      {isOpenBetBuilder && !isMobile() && (
        <div
          className="sportsList__sport manual-sport-item"
          onClick={() => {
            handleClickEventItem(`/${ENotRealSports.betBuilder}`);
          }}
        >
          <div className="sportsList__sport__header">
            <div className="style__Icon-sc-nitumr-4">
              <GlobalIcon
                lib="sports"
                name={`${ENotRealSports.betBuilder}${
                  SpringConfigs.IS_SGB_TYPE_FOR_BB ? 'sgp' : ''
                }`}
                size={EIconSize._24}
                className="text-color"
              />
            </div>
            <span>{t('sportsbook.betBuilder')}</span>
          </div>
          <GlobalIcon
            lib="generic"
            name="caretDownSmall"
            theme="default"
            size={EIconSize._24}
            className="sport-arrow-right"
          />
        </div>
      )}

      {SpringConfigs.COUPON_BUILDER_SWITCHER &&
        configs?.couponBuilderSportsList && (
          <div
            className="sportsList__sport manual-sport-item"
            onClick={() => {
              !isCoupon && handleClickEventItem(`/${ENotRealSports.coupon}`);
            }}
          >
            <div className="sportsList__sport__header">
              <div className="style__Icon-sc-nitumr-4">
                <GlobalIcon
                  lib="sports"
                  name={ENotRealSports.coupon}
                  size={EIconSize._24}
                  className="text-color"
                  useWrapper
                />
              </div>
              <span>{t('sportsbook.coupon')}</span>
            </div>
            <GlobalIcon
              lib="generic"
              name="caretDownSmall"
              theme="default"
              size={EIconSize._24}
              className="sport-arrow-right"
            />
          </div>
        )}

      {!loading && sportGroupsData?.length
        ? sportGroupsData.map(g => (
            <GroupWithSports
              key={g.id}
              isCompactView={!!compactView}
              groupData={g as IMenuGroupWithSports}
              openFromSideBarPopup={openFromSideBarPopup}
            />
          ))
        : null}

      {sortedSportsData?.length &&
      (!orderIsLoading || SpringConfigs.MOCKED_DATA) ? (
        sortedSportsData?.map((sport, index) => (
          <WrapperHandler
            key={sport.id}
            condition={!!compactView}
            positiveWrapper={children => (
              <EventCollapse
                dataTestId={sport.name.toLowerCase()}
                key={sport.id}
                title={
                  <span
                    className={cc([
                      'sportTitleWithFavoriteButton',
                      {
                        'sportTitleWithFavoriteButton--active':
                          favoriteSportIds.includes(+sport.id),
                        'sportTitleWithFavoriteButton--mobile': isMobile()
                      }
                    ])}
                  >
                    {sport.name}
                    <SportsFavoriteButton
                      onClick={() => sportFavoriteClickHandler(+sport.id)}
                      sportId={+sport.id}
                    />
                  </span>
                }
                icon={
                  <GlobalIcon
                    lib="sports"
                    name={sport.alias || ''}
                    size={EIconSize._24}
                    className="text-color"
                  />
                }
                wrapperClassName="prematch__sportsList__sport__wrapper"
                headerClassName="prematch__sportsList__sport__header"
                defaultActive={
                  (!Number(SpringConfigs.SPORT_COMPETITION_IS_COMPACT) &&
                    index === 0 &&
                    !scrollToCompetitionId) ||
                  scrollToCompetitionId?.sportId === sport.id
                }
                renderInDom={SpringConfigs.RENDER_COMPETITIONS_IN_DOM}
              >
                {children}
              </EventCollapse>
            )}
            negativeWrapper={children => (
              <NavigationCollapse
                key={sport.id}
                title={
                  <span
                    className={cc([
                      'sportTitleWithFavoriteButton',
                      { active: favoriteSportIds.includes(+sport.id) }
                    ])}
                  >
                    {sport.name}
                    <SportsFavoriteButton
                      onClick={() => sportFavoriteClickHandler(+sport.id)}
                      sportId={+sport.id}
                    />
                  </span>
                }
                icon={
                  <GlobalIcon
                    lib="sports"
                    name={sport.alias || ''}
                    size={EIconSize._24}
                    className="text-color"
                  />
                }
                wrapperClassName="sportsList__sport"
                headerClassName="sportsList__sport__header"
                renderInDom={SpringConfigs.RENDER_COMPETITIONS_IN_DOM}
                dataTestId={(sport.name || '').toLowerCase()}
              >
                {children}
              </NavigationCollapse>
            )}
          >
            <SportRegions
              sport={sport}
              clickHandler={clickHandler}
              compactView={compactView}
              openFromSideBarPopup={openFromSideBarPopup}
            />
          </WrapperHandler>
        ))
      ) : compactView ? (
        <MobileSportsbookEventCollapseSkeleton />
      ) : (
        createArray(SPORT_LIST_SKELETON_COUNT).map(key => (
          <SportListSkeleton key={key} />
        ))
      )}
    </div>
  );
};
