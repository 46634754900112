import { ActionType } from 'interfaces/generic';
import { NewCasinoGame } from 'interfaces/new-casino';
import { TMarketCountResponse } from 'interfaces/sportsbook';
import {
  FavoriteCompetitionInfo,
  TClearFavoriteGroupKey,
  TFavoritesState,
  TTeam,
  TToggleFavoriteGameAction
} from 'interfaces/sportsbook-data-levels';
import { FavoriteActionTypes } from 'store/action-types';

export const setFavoritesMarketsCount = (
  payload: TMarketCountResponse['game']
) => {
  return {
    type: FavoriteActionTypes.SET_FAVORITES_MARKETS_COUNT,
    payload
  };
};

export const toggleFavorite = (payload: TToggleFavoriteGameAction) => {
  return {
    type: FavoriteActionTypes.TOGGLE_FAVORITE,
    payload
  };
};

export const setFavoriteSportsData = (
  payload: Pick<
    TFavoritesState,
    'sportsbook' | 'esport' | 'favoriteMatchesCountPeerCompetition'
  >
) => {
  return {
    type: FavoriteActionTypes.SET_FAVORITE_SPORTS_DATA,
    payload
  };
};

export const setMarketsCounts = (payload: TMarketCountResponse['game']) => {
  return {
    type: FavoriteActionTypes.SET_MARKETS_COUNTS,
    payload
  };
};

export const clearAllFavorite = (payload: TClearFavoriteGroupKey) => {
  return {
    type: FavoriteActionTypes.CLEAR_FAVORITE,
    payload
  };
};

export const setFavoriteDataLoading = (payload: boolean) => {
  return {
    type: FavoriteActionTypes.SET_FAVORITE_DATA_LOADING,
    payload
  };
};

export const setFavoriteIdsLoading = (payload: boolean) => {
  return {
    type: FavoriteActionTypes.SET_FAVORITE_IDS_LOADING,
    payload
  };
};

export const setFavoriteCompetitions = (payload: FavoriteCompetitionInfo[]) => {
  return {
    type: FavoriteActionTypes.SET_FAVORITE_COMPETITION,
    payload
  };
};

export const setSportTeams = (payload: TTeam[]) => {
  return {
    type: FavoriteActionTypes.SET_SPORT_TEAMS,
    payload
  };
};

export const setFavoriteSports = (payload: number[]) => {
  return {
    type: FavoriteActionTypes.SET_FAVORITE_SPORTS,
    payload
  };
};

export const setFavoriteCasinoGames = (
  payload: NewCasinoGame[] | undefined
) => {
  return {
    type: FavoriteActionTypes.SET_CASINO_FAV_DATA,
    payload
  };
};

export const setFavoriteCasinoProviders = (payload: string[] | undefined) => {
  return {
    type: FavoriteActionTypes.SET_PROVIDERS_FAV_DATA,
    payload
  };
};

export const clearFavoriteMatches = () => {
  return {
    type: FavoriteActionTypes.CLEAR_FAVORITE_MATCHES
  };
};

export const setFavoriteMarkets = (payload: string[]) => {
  return {
    type: FavoriteActionTypes.SET_MARKETS_FAV_DATA,
    payload
  };
};

export const setPersonalizedGameResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_GAME_RESOURCE_ID,
    payload
  };
};

export const setPersonalizedProviderResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_PROVIDER_RESOURCE_ID,
    payload
  };
};

export const setPersonalizedCompetitionResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_COMPETITION_RESOURCE_ID,
    payload
  };
};

/* --- payload type is any because for personalized data there is no exact data structure, we can set anything to swarm --- */
export const setPersonalizationData = (payload: any): ActionType => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZATION_DATA,
    payload
  };
};

export const setPersonalizedMarketResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_MARKET_RESOURCE_ID,
    payload
  };
};

export const setPersonalizedSportGamesResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_SPORT_GAMES_RESOURCE_ID,
    payload
  };
};

export const setPersonalizedSportsResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_SPORTS_RESOURCE_ID,
    payload
  };
};

export const setPersonalizedSportTeamsResourceId = (payload: string) => {
  return {
    type: FavoriteActionTypes.SET_PERSONALIZED_SPORT_TEAMS_RESOURCE_ID,
    payload
  };
};

export const setCollaspsedFavoriteBar = (payload: boolean) => {
  return {
    type: FavoriteActionTypes.SET_COLLAPSED_FAVORITE_BAR,
    payload
  };
};
