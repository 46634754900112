import styled from 'styled-components';

export const NoItemWrapper = styled.figure`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 48px 32px;
  width: 100%;
  height: 80vh;
  background: var(--v3-black-0);
  margin-top: 8px;
  border-radius: 6px;
  cursor: auto;

  figcaption {
    text-align: center;
    max-width: 300px;
    color: var(--v3-black-45) !important;
    font-size: 14px !important;
    line-height: 20px !important;
    margin-top: 16px;
  }
  .no-item-title {
    font-size: 24px;
    color: var(--v3-primary-color);
    line-height: 28px;
    margin-bottom: 8px;
    font-weight: 600;
    user-select: none;
  }
  &.medium {
    height: auto;
    padding: 38px 20px;
    .no-item-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    figcaption {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-top: 12px;
    }
  }
  @media only screen and (max-width: 600px) {
    min-height: 60vh;
    .no-item-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }
`;
