// Odd type values
import { EBetslipBetType, OnAcceptBet } from 'interfaces/betslip';

type TODD_TYPES = { [key: string]: number };

export const ODD_TYPES: TODD_TYPES = {
  DECIMAL: 0,
  FRACTIONAL: 1,
  AMERICAN: 2,
  HONGKONG: 3,
  MALAY: 4,
  INDO: 5
};

// Bet mode types
export const MODE_TYPES = {
  SUPER_BET_MODE: -1,
  ODD_NOT_CHANGE: 0,
  ODD_NOT_CHANGED_AND_NOT_INCREASED: 1,
  ACCEPT_ANY_CHANGES: 2
};

// Bet types
export const BET_TYPES = {
  SINGLE: 1,
  MULTIPLE: 2,
  SYSTEM: 3,
  CHAIN: 4,
  TRIXIE: 5,
  YANKEE: 6,
  PERMED_YANKEE: 7,
  SUPER_YANKEE: 8,
  HEINZ: 9,
  SUPER_HEINZ: 10,
  GOLIATH: 11,
  PATENT: 12,
  PERMED_PATENT: 13,
  LUCKY15: 14,
  LUCKY31: 15,
  LUCKY63: 16,
  ALPHABET: 17,
  STRAIGHT_FORECAST: 40,
  REVERSE_FORECAST: 41,
  COMBINATION_FORECAST: 42,
  STRAIGHT_TRICAST: 43,
  COMBINATION_TRICAST: 44,
  BET_BUILDER: 50
};

export const BET_TYPES_FOR_SHOW_IN_UK_JURISDICTION = [
  BET_TYPES.SINGLE,
  BET_TYPES.MULTIPLE,
  BET_TYPES.STRAIGHT_FORECAST,
  BET_TYPES.REVERSE_FORECAST,
  BET_TYPES.COMBINATION_FORECAST,
  BET_TYPES.STRAIGHT_TRICAST,
  BET_TYPES.COMBINATION_TRICAST,
  BET_TYPES.BET_BUILDER
];

export const BET_OUTCOME_TYPES: Record<number, string> = {
  0: 'notResulted',
  1: 'lost',
  2: 'returned',
  3: 'won',
  5: 'cashedOut',
  [-5]: 'underReview'
};

export const EVENTS_OUTCOME_TYPES: Record<number, string> = {
  0: 'notResulted',
  1: 'lost',
  2: 'returned',
  3: 'won',
  5: 'WonReturn',
  6: 'LostReturn',
  7: 'Place'
};

export const BET_OUTCOME_STATUS: Record<number, 'error' | 'success'> = {
  1: 'error',
  2: 'success',
  3: 'success'
};

/*eslint no-magic-numbers:*/
// TODO check after betslip refactor

export const BET_TYPE_NAMES: Record<number, string> = {
  1: 'Single',
  2: 'Multiple',
  3: 'System',
  4: 'Chain',
  5: 'Trixie',
  6: 'Yankee',
  8: 'SuperYankee',
  9: 'Heinz',
  10: 'SuperHeinz',
  11: 'Goliath',
  12: 'Patent',
  14: 'Lucky15',
  15: 'Lucky31',
  16: 'Lucky63',
  40: 'StraightForecast',
  41: 'ReverseForecast',
  42: 'CombinationForecast',
  43: 'StraightTricast',
  44: 'CombinationTricast',
  50: 'betbuilder'
};

export const BETSLIP_BET_TYPES_BY_BET_NUMBER_TYPE: Record<
  number,
  EBetslipBetType
> = {
  1: EBetslipBetType.SINGLE,
  2: EBetslipBetType.MULTIPLE,
  3: EBetslipBetType.SYSTEM,
  4: EBetslipBetType.CHAIN
};

const BET_FILTER_TYPES = [
  { name: 'single', id: 1 },
  { name: 'multiple', id: 2 },
  { name: 'system', id: 3 },
  { name: 'chain', id: 4 }
];

export const BET_TYPES_FILTER: Record<number, any> = {
  0: BET_FILTER_TYPES,
  1: [
    ...BET_FILTER_TYPES,
    {
      name: 'trixie',
      id: 5
    },
    {
      name: 'yankee',
      id: 6
    },
    {
      name: 'superyankee',
      id: 8
    },
    {
      name: 'heinz',
      id: 9
    },
    {
      name: 'superheinz',
      id: 10
    },
    {
      name: 'goliath',
      id: 11
    },
    {
      name: 'block',
      id: 12
    },
    {
      name: 'patent',
      id: 13
    },
    {
      name: 'lucky15',
      id: 14
    },
    {
      name: 'lucky31',
      id: 15
    },
    {
      name: 'lucky63',
      id: 16
    }
  ],
  4: [...BET_FILTER_TYPES]
};

export const DEFAULT_BET_RESULT = -10;

const BET_FILTER = [
  { name: 'betResult', id: 'all' },
  { name: 'notResulted', id: 0 },
  { name: 'lost', id: 1 },
  { name: 'returned', id: 2 },
  { name: 'won', id: 3 }
];

export const BET_OUTCOME_FILTER = [
  ...BET_FILTER,
  { name: 'cashedOut', id: 5 }
  // { name: 'profit', id: 6 } must be used when profit task is done
];

export const BET_BUILDER_OUTCOME_FILTER = BET_FILTER;

export const BET_TYPE_NAMES_KEYS: Record<EBetslipBetType, number> = {
  single: 1,
  multiple: 2,
  system: 3,
  chain: 4
};

export const BET_TYPE_NAMES_BY_KEY: Record<number, EBetslipBetType> = {
  1: EBetslipBetType.SINGLE,
  2: EBetslipBetType.MULTIPLE,
  3: EBetslipBetType.SYSTEM,
  4: EBetslipBetType.CHAIN
};

export const ON_ACCEPT_BET_TYPES: OnAcceptBet[] = [
  {
    label: 'Accept any odd change',
    value: '2',
    key: 'acceptAnyOdds'
  },
  {
    label: 'Accept Higher odds',
    value: '1',
    key: 'acceptHigherOdds'
  },
  {
    label: 'Always ASK',
    value: '0',
    key: 'alwaysAsk'
  }
];

const BET_TYPES_CONF_DEFAULT = {
  posWin: 0,
  amount: 0,
  stake: '',
  sys_bet: false,
  each_way: false,
  ewPosWin: 0,
  active: false,
  folds: false,
  showPosWin: false
};

export const BET_TYPES_CONF_SINGLE_NAME = 'Single';

export const BET_TYPES_CONF = [
  {
    name: BET_TYPES_CONF_SINGLE_NAME,
    type: 1,
    count: 1,
    combCount: 1,
    strongCount: false,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Doubles',
    type: 2,
    count: 2,
    combCount: 1,
    strongCount: false,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Trebles',
    type: 2,
    count: 3,
    combCount: 1,
    strongCount: false,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Trixie',
    type: 5,
    count: 3,
    combCount: 4,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Patent',
    type: 12,
    count: 3,
    combCount: 7,
    strongCount: true,
    addSingleOdds: true,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Yankee',
    type: 6,
    count: 4,
    combCount: 11,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Lucky 15',
    type: 14,
    count: 4,
    combCount: 15,
    strongCount: true,
    addSingleOdds: true,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Super Yankee',
    type: 8,
    count: 5,
    combCount: 26,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Lucky 31',
    type: 15,
    count: 5,
    combCount: 31,
    strongCount: true,
    addSingleOdds: true,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Heinz',
    type: 9,
    count: 6,
    combCount: 57,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Lucky 63',
    type: 16,
    count: 6,
    combCount: 63,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Super Heinz',
    type: 10,
    count: 7,
    combCount: 120,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  },
  {
    name: 'Goliath',
    type: 11,
    count: 8,
    combCount: 247,
    strongCount: true,
    addSingleOdds: false,
    ...BET_TYPES_CONF_DEFAULT
  }
];

export const EDIT_BET_STATUSES_TO_REMOVE_EVENT_FROM_BETSLIP = [
  'CashOutNotAllowed',
  'MarketSuspended'
];

export const REPLACE_EDIT_BET_TRANSLATIONS = ['BetSelectionChanged'];

export const GET_BETTING_HISTORY_STATUS = (
  statusNumber: number | string
): 'error' | 'success' | 'processing' | 'default' | 'warning' | undefined => {
  switch (+statusNumber) {
    case 0:
      return 'processing';
    case 1:
      return 'error';
    default:
      return 'success';
  }
};

export const BET_BUILDER = {
  NAME: 'betbuilder',
  TYPE: 50
};

export const minBetStakeType = {
  MULTIPLIER_10: 10,
  MULTIPLIER_20: 20,
  MULTIPLIER_50: 50
};

export const predefinedStakesType = {
  STAKE_TYPE_1: 1,
  STAKE_TYPE_2: 2,
  STAKE_TYPE_3: 5
};

export const HISTORY_TABLE_BOTTOM_SPACE = '84px';

export const indicatorBookingId = {
  BY_HOORY: -1
};
