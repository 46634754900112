const TURNSTILE_SRC =
  'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';

export const appendScript = () => {
  const script = document.createElement('script');
  script.src = TURNSTILE_SRC;
  script.addEventListener('error', () => {
    console.error('Failed to load Turnstile.');
  });
  document.head.appendChild(script);
};
