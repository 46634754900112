// prettier-ignore
export const SportDataActionTypes = {
  SET_CURRENT_PREMATCH_GAMES: 'SET_CURRENT_PREMATCH_GAMES',
  SET_CASHED_PREMATCH_GAMES: 'SET_CASHED_PREMATCH_GAMES',
  SET_CASHED_SPORT_DATA: 'SET_CASHED_SPORT_DATA',
  SET_CAHSED_GAME_DATA: 'SET_CAHSED_GAME_DATA',
  SET_STREAM_DATA: 'SET_STREAM_DATA',
  SET_STREAM_CHANNELS: 'SET_STREAM_CHANNELS',
  SET_MARKET_TYPES: 'SET_MARKET_TYPES',
  SET_MARKET_TYPE: 'SET_MARKET_TYPE',
  SET_BOOSTED_ODDS: 'SET_BOOSTED_ODDS',
  SET_BOOSTED_ODDS_CALLED: 'SET_BOOSTED_ODDS_CALLED',
  SET_STREAM_DATA_LOADING: 'SET_STREAM_DATA_LOADING',
  SET_HAS_TOP_LEAGUE_GAMES: 'SET_HAS_TOP_LEAGUE_GAMES',
  SET_LIVE_EVENTS_DATA: 'SET_LIVE_EVENTS_DATA',
  SET_UPCOMING_EVENTS_DATA: 'SET_UPCOMING_EVENTS_DATA',
  SET_POPULAR_EVENTS_DATA: 'SET_POPULAR_EVENTS_DATA',
  SET_BOOSTED_EVENTS_DATA: 'SET_BOOSTED_EVENTS_DATA',
  RESET_EVENTS_DATA: 'RESET_EVENTS_DATA',
  SCROLL_TO_GAME_ID: 'SCROLL_TO_GAME_ID',
  SET_MULTIPLE_SELECTIONS: 'SET_MULTIPLE_SELECTIONS',
  SCROLL_TO_COMPETITION_ID: 'SCROLL_TO_COMPETITION_ID',
  SET_TIME_FILTER_DATA: 'SET_TIME_FILTER_DATA',
  SET_SPORTS_LIST_SPORT_DATA: 'SET_SPORTS_LIST_SPORT_DATA',
  SET_CALENDAR_MARKET_TYPES: 'SET_CALENDAR_MARKET_TYPES',
  SET_CALENDAR_MARKET_TYPE: 'SET_CALENDAR_MARKET_TYPE',
  SET_MARKET_INFO_TYPE: 'SET_MARKET_INFO_TYPE',
  SET_REGION_MARKETS: 'SET_REGION_MARKETS',
  SET_USER_NOTIFICATIONS: 'SET_USER_NOTIFICATIONS',
  SET_GAMES_COUNT: 'SET_GAMES_COUNT',
  SET_GAMES_BY_COMPETITIONS: 'SET_GAMES_BY_COMPETITIONS',
  SET_TOURNAMENT_SPORT_IDS: 'SET_TOURNAMENT_SPORT_IDS',
  SET_COUPONS: 'SET_COUPONS',
  SET_CLICK_FROM_FAVORITES: 'SET_CLICK_FROM_FAVORITES',
  RESET_ALL_MARKET_TYPES:'RESET_ALL_MARKET_TYPES'
};
