// prettier-ignore

export const AppDataActionTypes = {
  SET_COL_COUNT: 'SET_COL_COUNT',
  SET_RECAPTCHA_DETAILS: 'SET_RECAPTCHA_DETAILS',
  SET_TURNSTILE_DETAILS: 'SET_TURNSTILE_DETAILS',
  SET_DATA_CONFIGS: 'SET_DATA_CONFIGS',
  SET_ROUNDING: 'SET_ROUNDING',
  SET_ROUNDING_LOADING: 'SET_ROUNDING_LOADING',
  SET_SECTIONS_DATA: 'SET_SECTIONS_DATA',
  SET_POSTGRID_HIDDEN_PARAMS: 'SET_POSTGRID_HIDDEN_PARAMS',
  UPDATE_POSTGRID_HIDDEN_PARAMS: 'UPDATE_POSTGRID_HIDDEN_PARAMS',
  RESET_POSTGRID_HIDDEN_PARAMS: 'RESET_POSTGRID_HIDDEN_PARAMS',
  SET_SHOW_ADDITIONAL_INFO: 'SET_SHOW_ADDITIONAL_INFO',
  SET_OPENED_MODALS: 'SET_OPENED_MODALS',
  SET_ATOZMENU_DATA: 'SET_ATOZMENU_DATA',
  SET_MENU_JSON_DATA: 'SET_MENU_JSON_DATA',
  SET_REG_FIELD: 'SET_REG_FIELD',
  SET_CASHOUT_MODAL_OPEN: 'SET_CASHOUT_MODAL_OPEN',
  SET_DEPOSIT_MODAL_OPEN: 'SET_DEPOSIT_MODAL_OPEN',
  SET_FIREBASE_TOKEN: 'SET_FIREBASE_TOKEN',
  SET_ONE_SIGNAL_SUB_ID: 'SET_ONE_SIGNAL_SUB_ID',
  SET_DEPOSIT_PAYMENT_STATUS: 'SET_DEPOSIT_PAYMENT_STATUS',
  SET_SEON_SESSION_DATA: 'SET_SEON_SESSION_DATA',
  SET_HEADER_STATE: 'SET_HEADER_STATE',
  SET_GEOLOCATION_SHAKE_DATA: 'SET_GEOLOCATION_SHAKE_DATA',
  SET_SPORTSCONFIGS_DATA: 'SET_SPORTSCONFIGS_DATA',
  SET_RECAPTCHA_KEY: 'SET_RECAPTCHA_KEY',
  SET_HOMEWORK_DATA: 'SET_HOMEWORK_DATA',
  SET_SPORTSBOOK_TYPE: 'SET_SPORTSBOOK_TYPE',
  SET_CRYPTO_CURRENCIES: 'SET_CRYPTO_CURRENCIES',
  SET_IS_PAYMENTS_LOADING: 'SET_IS_PAYMENTS_LOADING',
  SET_BET_SHOPS: 'SET_BET_SHOPS',
  SET_SID: 'SET_SID',
  SET_REMAINING_SESSION_DURATION_MODAL: 'SET_REMAINING_SESSION_DURATION_MODAL',
  SET_IDIN_DATA: 'SET_IDIN_DATA',
  SET_LOCATE_DATA: 'SET_LOCATE_DATA',
  SET_FAVORITE_ON_HEADER_OPEN: 'SET_FAVORITE_ON_HEADER_OPEN',
  SET_FAVORITE_ON_HEADER_SELECTED_TAB: 'SET_FAVORITE_ON_HEADER_SELECTED_TAB',
  SET_IS_DARK_MODE: 'SET_IS_DARK_MODE',
  SET_HIGHER_Z_INDEX: 'SET_HIGHER_Z_INDEX',
  SET_INIT_SPORTSBOOK_IFRAME: 'SET_INIT_SPORTSBOOK_IFRAME',
  SET_FORGOT_PASSWORD_OPEN: 'SET_FORGOT_PASSWORD_OPEN',
  SET_SHOW_TO_TAP_NAV: 'SET_SHOW_TO_TAP_NAV',
  SET_FORGOT_USERNAME_OPEN: 'SET_FORGOT_USERNAME_OPEN',
  SET_TOUR_STEPS_INITIAL_DATA: 'SET_TOUR_STEPS_INITIAL_DATA',
  SET_TOUR_STEPS: 'SET_TOUR_STEPS',
  SET_TOUR_MODAL_VISIBLE: 'SET_TOUR_MODAL_VISIBLE',
  SET_PERSONALIZATION_GENERIC_SETTINGS: 'SET_PERSONALIZATION_GENERIC_SETTINGS',
  SET_AFTER_SIGN_IN_ACTIONS: 'SET_AFTER_SIGN_IN_ACTIONS'
};
