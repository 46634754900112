import SpringConfigs from 'utils/constants/swarm/spring-configs';
import { getCalculationsParams } from 'newelements/_Betslip/utils/action-middlewares/store-actions-middlewares/utils/getCalculationsParams';
import { calculateComputedFields } from 'newelements/_Betslip/utils/calculator';
import { GENERAL_FIELD_KEY } from 'newelements/_Betslip/utils/constants/shared';
import { setCalculationResult } from 'store/actions';
import store from 'store/index';
import type { IBetslipRootStateInitialData } from 'store/reducers/betslip';

export type TCalculateAndDispatchUpdatesParams = {
  id: string | number;
  stake?: string;
  isEachWay?: boolean;
  oddOffer?: string | null;
  superBet?: boolean;
};

export const calculateAndDispatchUpdates = (
  payload: TCalculateAndDispatchUpdatesParams
): void => {
  const betslipStore: IBetslipRootStateInitialData = store.getState().betSlip;

  const defaultStake = SpringConfigs.DEFAULT_STAKE_ENABLED
    ? SpringConfigs.DEFAULT_STAKE || ''
    : '';

  const stake =
    payload.stake ??
    ((payload.id === GENERAL_FIELD_KEY
      ? betslipStore.calculations.general?.stake
      : betslipStore.calculations.singleEventsCalculations[payload.id]
          ?.stake) ||
      defaultStake.toString());

  const offerFields = modifyCalculationPayloadAndGetOfferFiels(payload);

  const calculationArgs = getCalculationsParams(Number(stake) ?? 0, payload);

  if (calculationArgs) {
    const calculationResult = calculateComputedFields(calculationArgs);

    store.dispatch(
      setCalculationResult({
        result: {
          stake,
          finalStake: calculationArgs.stake,
          odd: calculationArgs.odd,
          isEachWay: calculationArgs.isEachWay,
          ...offerFields,
          ...calculationResult
        },
        id: payload.id
      })
    );
  }
};

export const calculateAndDispatchSingleBetsUpdates = (
  stake: string,
  _eventIds?: (string | number)[]
) => {
  const betslipStore: IBetslipRootStateInitialData = store.getState().betSlip;
  const eventIds =
    _eventIds ?? betslipStore.allLsBets.map(({ eventId }) => eventId);

  eventIds.forEach(id => {
    calculateAndDispatchUpdates({ stake, id });
  });
};

const modifyCalculationPayloadAndGetOfferFiels = (
  payload: TCalculateAndDispatchUpdatesParams
): Pick<TCalculateAndDispatchUpdatesParams, 'oddOffer' | 'superBet'> => {
  const betslipStore: IBetslipRootStateInitialData = store.getState().betSlip;
  const eventsCalculation =
    payload.id === GENERAL_FIELD_KEY
      ? betslipStore.calculations.general
      : betslipStore.calculations.singleEventsCalculations?.[payload.id];

  let oddOffer =
    payload.oddOffer !== null
      ? payload.oddOffer ?? eventsCalculation?.oddOffer ?? null
      : null;

  let superBet = payload.superBet ?? eventsCalculation?.superBet ?? false;

  if ('oddOffer' in payload && payload.oddOffer !== null) {
    superBet = false;
  } else if (payload.superBet) {
    oddOffer = null;
  }

  delete payload.oddOffer;
  delete payload.superBet;

  const isOddOffer = oddOffer !== null;

  if (oddOffer !== null) {
    payload.oddOffer = oddOffer;
  }

  payload.superBet = superBet;

  return {
    ...(isOddOffer && { oddOffer }),
    superBet
  };
};
