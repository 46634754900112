import { AnyAction } from 'redux';
import type { PaymentResponse } from 'interfaces/account-settings';
import { PaymentsActionType } from 'store/action-types';

export const setPayments = (payload: PaymentResponse): AnyAction => {
  return {
    type: PaymentsActionType.SET_PAYMENTS,
    payload
  };
};

export const setPaymentsLoading = (): AnyAction => {
  return {
    type: PaymentsActionType.SET_PAYMENTS_LOADING
  };
};

export const resetPayments = (): AnyAction => {
  return {
    type: PaymentsActionType.RESET_PAYMENTS
  };
};

export const setQuickDepositOpen = (open: boolean): AnyAction => {
  return {
    type: PaymentsActionType.SET_QUICK_DEPOSIT_OPEN,
    payload: open
  };
};

export const setRateWithdraw = (payload: Record<string, string>): AnyAction => {
  return {
    type: PaymentsActionType.SET_RATE_WITHDRAW,
    payload
  };
};
