import { CommandNames } from 'utils/constants/swarm/swarm-command-names';
import RidGenerator from 'utils/swarm/rid-generator';
import { WebsocketEnhanced } from 'interfaces/spring-websocket-interfaces';
import { connectingWS } from 'hooks/useSpringConnect';
import Store from 'store';

const CLOSE_WRAPPER_DURATION = 1000;

export const TurnstileCaptcha = async (actionType: string) => {
  return new Promise((resolve, reject) => {
    const turnstileVerifyActions =
      Store.getState().appData.turnstileVerifyActions || [];

    const siteKey = Store.getState().appData.turnstileSiteKey;
    const container = document.getElementsByClassName(
      'turnstile-container'
    )[0] as HTMLElement;

    const icon = document.getElementsByClassName(
      'turnstile-container__icon'
    )[0] as HTMLElement;

    const turnstileWrapper = document.getElementById(
      'turnstileWrapper'
    ) as HTMLElement;

    const closeWrapper = () => {
      setTimeout(() => {
        container.style.display = 'none';
        document.body.style.overflow = 'unset';
        turnstileWrapper.innerHTML = '';
      }, CLOSE_WRAPPER_DURATION);
    };

    if (
      turnstileVerifyActions.includes(actionType) &&
      window.turnstile &&
      siteKey
    ) {
      const onVerify = (token: string) => {
        const command = {
          command: CommandNames.VALIDATE_TURNSTILE,
          params: {
            turnstile_response_token: token,
            action: actionType
          },
          rid: RidGenerator.gForCommand()
        };

        connectingWS.then((socket: WebsocketEnhanced) =>
          socket.sendCommand(
            command,
            '',
            (data: { result: boolean | number } | string) => {
              if (
                typeof data !== 'string' &&
                typeof data.result === 'boolean'
              ) {
                closeWrapper();
                resolve(true);
              }
            },
            null,
            (error: { data: string; code: number }) => {
              closeWrapper();
              icon.style.display = 'block';
              reject(error);
            }
          )
        );
      };

      const renderTurnstile = () => {
        document.body.style.overflow = 'hidden';
        container.style.display = 'block';

        window.turnstile.render('#turnstileWrapper', {
          sitekey: siteKey,
          action: actionType,
          callback: onVerify,
          theme: Store.getState().appData.isDarkMode ? 'dark' : 'light',
          'error-callback': (error: number) => {
            console.error(`Cloudflare verification error (${error})`);
            icon.style.display = 'block';
            reject(error);
          }
        });
      };

      try {
        window.turnstile?.ready(() => {
          renderTurnstile();
        });
      } catch {
        try {
          renderTurnstile();
        } catch (err) {
          closeWrapper();
        }
      }
    } else {
      closeWrapper();
      resolve(true);
    }
  });
};
