// prettier-ignore
export const UserDataActionTypes = {
  SET_USER_DATA: 'SET_USER_DATA',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
  REMOVE_USER_DATA: 'REMOVE_USER_DATA',
  SET_USER_LOGGED_IN: 'SET_USER_LOGGED_IN',
  SET_USER_LOGIN_LIMIT: 'SET_USER_LOGIN_LIMIT',
  SET_USER_BONUSES: 'SET_USER_BONUSES',
  SET_INBOX_MESSAGES_COUNT: 'SET_INBOX_MESSAGES_COUNT',
  SET_PENDING_SUPER_BETS: 'SET_PENDING_SUPER_BETS',
  REMOVE_PENDING_SUPER_BET: 'REMOVE_PENDING_SUPER_BET',
  SET_FAST_REG_DATA: 'SET_FAST_REG_DATA',
  SET_WITHDRAWAL_CAPABILITY: 'SET_WITHDRAWAL_CAPABILITY',
  SET_CASINO_BONUS_DETAILS: 'SET_CASINO_BONUS_DETAILS',
  SET_AUTOIDENT_VERIFICATION_LINK: 'SET_AUTOIDENT_VERIFICATION_LINK',
  SET_SHOW_AUTOIDENT_VERIFICATION_MODAL: 'SET_SHOW_AUTOIDENT_VERIFICATION_MODAL',
  SET_SHOW_CANCEL_REGISTER_POPUP: 'SET_SHOW_CANCEL_REGISTER_POPUP',
  SET_USER_OPT_INS: 'SET_USER_OPT_INS',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
  SHOULD_GET_USER_OPTINS: 'SHOULD_GET_USER_OPTINS',
  SEND_AS_PROMO_CODE: 'SEND_AS_PROMO_CODE',
  HAS_FORM_ERROR: 'HAS_FORM_ERROR',
  GPS_TRACKING: 'GPS_TRACKING',
  UPDATE_GPS_TRACKING: 'UPDATE_GPS_TRACKING',
  RESET_GPS_TRACKING: 'RESET_GPS_TRACKING',
  USER_DEPOSIT_LIMITS: 'USER_DEPOSIT_LIMITS',
  SET_REMEMBER_ME: 'SET_REMEMBER_ME',
  SET_PAYMENTS: 'SET_PAYMENTS',
  SET_PAYMENT_DEPOSIT_PROMO: 'SET_PAYMENT_DEPOSIT_PROMO',
  SET_CLIENT_INFO: 'SET_CLIENT_INFO',
  HIDDEN_FIELDS_VALUES: 'HIDDEN_FIELDS_VALUES',
  DELETE_HIDDEN_FIELD_VALUE: 'DELETE_HIDDEN_FIELD_VALUE',
  RESET_HIDDEN_FIELDS_VALUES: 'RESET_HIDDEN_FIELDS_VALUES',
  SUM_SUB_MODAL_OPEN: 'SUM_SUB_MODAL_OPEN',
  STAKE_BALANCE: 'STAKE_BALANCE',
  WITHDRAWABLE_BALANCE: 'WITHDRAWABLE_BALANCE',
  SET_REG_IS_FINISHED: 'SET_REG_IS_FINISHED',
  SET_DISPLAY_CRYPTO_IN_FIAT: 'SET_DISPLAY_CRYPTO_IN_FIAT',
  SET_SELECTED_CURRENCY_FOR_DISPLAY: 'SET_SELECTED_CURRENCY_FOR_DISPLAY',
  RESET_SELECTED_CURRENCY_FOR_DISPLAY: 'RESET_SELECTED_CURRENCY_FOR_DISPLAY',
  CASHBACK_NOTIFICATIONS: 'CASHBACK_NOTIFICATIONS',
  SET_PERSONALISED_DATA_LOADED: 'SET_PERSONALISED_DATA_LOADED',
  SET_PARTNER_LOYALTY_GROUPS: 'SET_PARTNER_LOYALTY_GROUPS',
  SET_PARTNER_LOYALTY_BENEFITS: 'SET_PARTNER_LOYALTY_BENEFITS',
  SET_USER_LOYALTY_INFO: 'SET_USER_LOYALTY_INFO',
  SET_USER_LOYALTY_BENEFITS: 'SET_USER_LOYALTY_BENEFITS',
  LOYALTY_NOTIFICATIONS: 'LOYALTY_NOTIFICATIONS',
  SET_ACTIVE_CLAIMABLE_BENEFIT: 'SET_ACTIVE_CLAIMABLE_BENEFIT'
};
