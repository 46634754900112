import { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import {
  Competition,
  IRacingEvent,
  IRacingGameHorseAdditionalData,
  RacingGame,
  RacingRegion,
  StreamInfoType
} from 'interfaces/sportsbook-data-levels';

const RACING_RESULTS_NOT_RUNNER_CUSTOM_NUMBER = 10001;
const RACING_RESULTS_NOT_FINISHED_CUSTOM_NUMBER = 99;

export enum ERacingSportSideBarTabs {
  All = 'all',
  HORSE_RACING = '1',
  GREYHOUNDS_RACING = '2'
}

export type TManualTerm = {
  End: string;
  IsMinCountNotMet: boolean;
  ManualEachWayK: number;
  ManualEachWayPlace: number;
  MinCount: number;
  PartnerId: number;
  Start: string;
};

export type TDateAndSportFilterTab = {
  key: string;
  title: string;
  timeFilter: ERacingTabsTimeFilters;
};

export type TSportGameCompetitionCheck = {
  string: {
    id: number;
    competition?: {
      string: {
        id: number;
        game?: { string: { start_ts: number } };
      };
    };
  };
};

export enum EForecastTricastMarkets {
  FORECAST = 'forecast',
  TRICAST = 'tricast'
}

export enum EForcastTricastOddTypes {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  ANY = 'any'
}

export enum ERacingTabsTimeFilters {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  DAY_AFTER_TOMORROW = 'day-after-tomorrow',
  TWO_DAYS_AFTER_TOMORROW = 'two-days-after-tomorrow',
  ALL_TIME = 'all-time'
}

export enum ENextOff {
  NEXT_OFF = 'next-off'
}

export type TDayFilter =
  | {
      '@gt': number;
      '@lt': number;
    }
  | {};

export type TForecastTricastSelectedEvents = {
  first?: number;
  second?: number;
  third?: number;
  any: number[];
};

export type TOddItemsTypes = {
  oddItems: { item: EForcastTricastOddTypes; title: string }[];
  changeSelectedEvents: Function;
  eventId: number;
  selectedEvents: TForecastTricastSelectedEvents;
};

export interface IHorseJsonSelection {
  lastRunDays: number;
  jockeyInfo?: {
    id?: number;
    name?: string;
    coloursFilename?: string;
    coloursDescription?: string;
    allowancePounds?: number;
  };
  trainerInfo?: {
    id?: number;
    name?: string;
  };
  age?: number;
  weightPounds?: number;
  weightStones?: number;
  runnerNum?: number;
  drawn?: number;
  raceHistoryStat?: { type?: string }[];
  formFigures?: string;

  /*this keys exist but not used*/
  bred?: string;
  colour?: string;
  dam?: string;
  damSire?: string;
  id?: number;
  name?: string;
  startingPrice?: number;
  runnerId?: string | number;
  weightUnits?: string | null;
  silkFile?: string | number | null;
}

export interface IHorseJson {
  distanceText?: string | null;
  title?: string;
  prizes?: {
    amount: number;
    position: number;
  }[];
  going?: string;
  name: string;
  selections: IHorseJsonSelection[];

  /*this keys exist but not used*/
  id?: number;
  country?: string;
  venue?: string;
  resultTimestamp?: string;
  courseType?: string;
  date?: string;
  distance?: string;
  categoryCode?: string;
  eachWayPlaces?: number;
  handicap?: string;
  lastUpdated?: number;
  num?: number;
  numOfRunners?: number;
  resulted?: boolean;
  current?: boolean;
  status?: string;
  surfaceType?: string;
  time?: string;
  BackgroundUrl?: string;
}

export type TEventsData = { marketName: string; events: IRacingEvent[] };

export type TRacingGameViewContext = {
  region: RacingRegion | null;
  currentCompetition: Competition | null;
  loadMore: () => void;
  gamesForShow: RacingGame[];
  isMoreDataAvailable: boolean;
  backToCompetition: () => void;
  allGamesSubscribedCompetitionId: number;
};

export type TRacingGameProps = {
  gameData: RacingGame;
  eventsData: TEventsData[];
  currentGameTime: string;
  streamInfo: StreamInfoType;
  showHideStream: () => void;
  showStream: boolean;
  gameActiveMarket: string;
  selectGameActiveMarket: Dispatch<SetStateAction<string | undefined>>;
  gameMarketTabs: {
    key: string;
    title: string;
  }[];
  addToBetSlip: (eventId?: string | number, sp?: boolean) => void;
  isLoaded: boolean;
  gameAdditionalData: IRacingGameHorseAdditionalData;
  gameDataLoading: boolean;
  isAllMarketsNull: boolean;
  marketNotFound: boolean;
};

export type TForeCastTypes = {
  eventsData: TEventsData[];
  gameActiveMarket: string;
  currentGameTime: string;
  currentCompetitionName?: string;
  gameAdditionalData: IRacingGameHorseAdditionalData;
  gameDataLoading: boolean;
};

export enum ERacingPageTypes {
  UPCOMING = 'upcoming',
  RESULTS = 'results'
}

export enum ERacingResultsTimeFilterTabs {
  TODAY = 'today',
  YESTERDAY = 'yesterday'
}
export enum ERacingGreyhoundIconsRegionPaths {
  USA_MEXICO = 'usa-mexico',
  GENERIC = 'generic'
}

export type TResultsCompetitionViewGameData = {
  CompetitionId: number;
  CompetitionName: string;
  CompetitionOrder: number;
  Id: number;
  RegionAlias: string;
  RegionId: number;
  RegionName: string;
  RegionOrder: number;
  SportAlias: string;
  SportId: number;
  SportName: string;
  StartTime: number;
};

export type TRacingResultsGameListSwarmData = {
  result: number;
  result_text: string | null;
  details: TResultsCompetitionViewGameData[];
};

export type TRacingResultsCompetitionGame = { id: number; start_ts: number };

export type TRacingResultsCompetition = {
  id: number;
  order: number;
  game: Record<number, TRacingResultsCompetitionGame>;
  name: string;
};

export type TRacingResultsRegion = {
  id: number;
  order: number;
  competition: Record<number, TRacingResultsCompetition>;
  name: string;
  alias: string;
  sportId: string;
  sportAlias: string;
};

export type TRacingResultsGameEventData = {
  Id: number;
  Name: string;
  Order: number;
  Outcome: number;
  Position: number;
  SPPrice: number | null;
};

export type TRacingResultsGameData = {
  MarketExtraInfo: string;
  MarketId: number;
  MarketName: string;
  MarketTypeId: number;
  MatchId: number;
  Selections: TRacingResultsGameEventData[];
  StartTime: number;
};

export type TRacingResultsGameSwarmData = {
  result: number;
  result_text: string | null;
  details: TRacingResultsGameData;
};

export type TRacingDividend = { betType: number; dividend: string };

export type TRacingResultsGameMarketExtraInfo = {
  Created: string;
  Dividents: Array<{
    BetType: number;
    Divident: number;
    Horses: Array<{ ExtraId: string; Name: string }>;
  }>;
  EachWayK: number;
  EachWayPlace: number;
  EarlyPrices: boolean;
  IsPartnerTermsEnabled: null | boolean;
  ManualTerms: null | string | boolean;
};

export type TRacingResultsEvent = {
  id: number;
  name: string;
  order: number;
  position: number;
  outcome: number;
  price: number | null;
  type: string;
  original_order: number;
};

type TRacingResultsGameMarket = {
  id: number;
  name: string;
  type_id: number;
  extra_info: TRacingResultsGameMarketExtraInfo;
  event: Record<string, TRacingResultsEvent>;
  type: string;
};

export type TRacingResultsGame = {
  id: number;
  start_ts: number;
  market: { [key: number]: TRacingResultsGameMarket };
  winnerMarketId: number;
};

export type TRacingResultsGameViewContext = {
  region: TRacingResultsRegion | null;
  currentCompetition: TRacingResultsCompetition | null;
  loadMore: () => void;
  gamesForShow: TRacingResultsGame[];
  isMoreDataAvailable: boolean;
  backToCompetition: (e?: SyntheticEvent) => void;
  currentCompetitionOrderedGameIds: string;
  gamesCount: number;
  setGamesCount: Dispatch<SetStateAction<number>>;
};

export enum ERacingResultPositionTypes {
  NOT_FINISHED = RACING_RESULTS_NOT_FINISHED_CUSTOM_NUMBER,
  NON_RUNNER = RACING_RESULTS_NOT_RUNNER_CUSTOM_NUMBER
}

export type TResultsGameViewProps = {
  gameData: TRacingResultsGame;
  events: TRacingResultsEvent[];
  currentGameTime: string;
  betInformation: TRacingDividend[];
  showSkeleton: boolean;
  eachWayText: string;
  gameAdditionalData: IRacingGameHorseAdditionalData;
  gameDataLoading: boolean;
};

export type TRacingResultsCompetitionProps = {
  competition: {
    order: number;
    id: number;
    name: string;
    game?: {
      [key: string]: {
        id: number;
        start_ts: number;
      };
    };
  };
};

export type TRacingPageTab = {
  label: string;
  alias: string;
  sport: number;
  icon: string;
};

export type TRacingGameJsonData = {
  data: IHorseJson;
  status: number;
};
