import { bankersRounding } from 'utils/betslip/bankersRounding';
import { add } from 'utils/generic/calculator';
import { getBonusInfo } from 'newelements/_Betslip/utils/calculator/calculateAccumulatorBonus';
import { calculateProfitBoost } from 'newelements/_Betslip/utils/calculator/calculateProfitBoost';
import {
  calculateBasicTaxes,
  calculateRangeTaxes
} from 'newelements/_Betslip/utils/calculator/calculateTaxes';
import { ICalculationResult } from 'store/reducers/betslip/calculations';

// @Todo need to group types after finalization
export type TCalculationParams = {
  odd: number;
  stake: number;
  sportIds: number[];
  eventId: number | string;
  isEachWay: boolean;
};

type TCalculateComputedFields = (
  params: TCalculationParams
) => ICalculationResult;

export const calculateComputedFields: TCalculateComputedFields = params => {
  // Attention!! do not change order calling of functions

  const basicTaxesResult = calculateBasicTaxes(params);

  // bonus of stake should calculate before tax applying (maybe need to check)
  const bonusCalculationResult = getBonusInfo({
    eventId: params.eventId,
    stake: basicTaxesResult.result.stake,
    multiTotalReturn: basicTaxesResult.result.possibleWin
  });

  const rangeTaxesResult = calculateRangeTaxes({
    stake: basicTaxesResult.result.stake,
    possibleWin: bankersRounding(
      add(
        basicTaxesResult.result.possibleWin,
        bonusCalculationResult.bonusAmount
      )
    )
  });

  const profitBoostResult = calculateProfitBoost({
    fields: rangeTaxesResult.result,
    eventId: params.eventId
  });

  return {
    basicTaxes: basicTaxesResult.taxes,
    rangeTaxes: rangeTaxesResult.taxes,
    bonusInfo: bonusCalculationResult,
    boostInfo: profitBoostResult.profitBoost,
    finalResult: profitBoostResult.result
  };
};
