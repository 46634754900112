import { AnyAction } from 'redux';
import { getBookBetIdFromLs } from 'utils/betslip/betslip';
import { BetslipActionTypes } from 'store/action-types';
import { IBetslipRootStateInitialData } from 'store/reducers/betslip/index';

// free bet
// super bet
// profit boost

enum EConflictingSwitchers {
  FREE_BET = 'FREE_BET',
  COUNTER_OFFER = 'COUNTER_OFFER',
  SUPER_BET = 'SUPER_BET',
  PROFIT_BOOST = 'PROFIT_BOOST'
}

export interface IBetslipFeaturesSliceInitialData {
  activeConflictingFeature: EConflictingSwitchers | null;
  bookBetId: string | null;
  useBonusBalance: boolean;
  quickBetEnabled: boolean;
  shouldShowQuickBetInfoPopover: boolean;
  quickBetStake: number;
  quickBetInProcessCount: number;
  isBookingMode: boolean;
  quickBetSelectedEventId: string;
}

export const betslipFeaturesSliceInitialData: IBetslipFeaturesSliceInitialData =
  {
    activeConflictingFeature: null,
    quickBetEnabled: false,
    shouldShowQuickBetInfoPopover: true,
    quickBetStake: 0,
    useBonusBalance: false,
    quickBetInProcessCount: 0,
    bookBetId: getBookBetIdFromLs(),
    isBookingMode: false,
    quickBetSelectedEventId: ''
  };

export const betslipFeaturesSliceReducer = (
  state: IBetslipRootStateInitialData,
  action: AnyAction
): IBetslipRootStateInitialData | null => {
  switch (action.type) {
    case BetslipActionTypes.SET_ACTIVE_CONFLICTING_FEATURE:
      return {
        ...state,
        activeConflictingFeature: action.payload
      };

    case BetslipActionTypes.TOGGLE_QUICK_BET:
      return {
        ...state,
        quickBetEnabled: action.payload ?? !state.quickBetEnabled
      };

    case BetslipActionTypes.UPDATE_QUICK_BET_STAKE:
      return {
        ...state,
        quickBetStake: action.payload
      };
    case BetslipActionTypes.TOGGLE_QUICK_BET_POPUP:
      return {
        ...state,
        shouldShowQuickBetInfoPopover: !state.shouldShowQuickBetInfoPopover
      };

    case BetslipActionTypes.SET_USE_BALANCE_BONUS:
      return {
        ...state,
        useBonusBalance: action.payload
      };

    case BetslipActionTypes.SET_IS_BOOKING_MODE:
      return {
        ...state,
        isBookingMode: action.payload
      };

    case BetslipActionTypes.UPDATE_QUICK_BET_IN_PROCESS_COUNT:
      return {
        ...state,
        quickBetInProcessCount:
          action.payload === 'increase'
            ? state.quickBetInProcessCount + 1
            : state.quickBetInProcessCount - 1
      };

    case BetslipActionTypes.SET_BOOK_BET_ID:
      return {
        ...state,
        bookBetId: action.payload
      };

    case BetslipActionTypes.SET_QUICK_BET_SELECTED_EVENT_ID:
      return {
        ...state,
        quickBetSelectedEventId: action.payload
      };
  }

  return null;
};
