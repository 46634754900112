import {
  CasinoOptions,
  CasinoTournamentsType,
  CrashGamesDataResults,
  ETournamentProductTypeIds,
  SelectedOptions
} from 'interfaces/casino-data';
import { ActionType } from 'interfaces/generic';
import { TJackpotMain } from 'interfaces/jackpot';
import { NewCasinoGame } from 'interfaces/new-casino';
import { CasinoDataActionTypes } from 'store/action-types/casino-data';

export const setCasinoOptions = (data: CasinoOptions): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_CASINO_OPTIONS,
    payload: data
  };
};

export const resetCasinoOptions = (): ActionType => {
  return {
    type: CasinoDataActionTypes.RESET_CASINO_OPTIONS
  };
};

// Categories and providers could be implemented by the same action/reducer.
// But maybe in future we will need different behaviour, that's why i separated them.
export const toggleActiveCategory = (id: string): ActionType => {
  return {
    type: CasinoDataActionTypes.TOGGLE_ACTIVE_CATEGORY,
    payload: id
  };
};

export const resetActiveCategories = (): ActionType => {
  return {
    type: CasinoDataActionTypes.RESET_ACTIVE_CATEGORIES
  };
};

export const toggleActiveProvider = (name: string): ActionType => {
  return {
    type: CasinoDataActionTypes.TOGGLE_ACTIVE_PROVIDER,
    payload: name
  };
};

export const resetActiveProviders = (): ActionType => {
  return {
    type: CasinoDataActionTypes.RESET_ACTIVE_PROVIDERS
  };
};

export const setCasinoGameTournaments = (
  payload: Array<CasinoTournamentsType> | null
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_CASINO_GAME_TOURNAMENTS,
    payload
  };
};

export const setIsLoaded = (payload: boolean) => {
  return {
    type: CasinoDataActionTypes.SET_IS_LOADED,
    payload
  };
};

export const setCasinoJackpot = (payload: TJackpotMain | null): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_CASINO_JACKPOT,
    payload
  };
};

export const setSportsbookJackpot = (
  payload: TJackpotMain | null
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_SPORTSBOOK_JACKPOT,
    payload
  };
};

export const setCasinoGameJackpot = (
  payload: TJackpotMain | null
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_CASINO_GAME_JACKPOT,
    payload
  };
};

export const resetCasinoJackpot = (): ActionType => {
  return {
    type: CasinoDataActionTypes.RESET_CASINO_JACKPOT
  };
};

export const resetSportsbookJackpot = (): ActionType => {
  return {
    type: CasinoDataActionTypes.RESET_SPORTSBOOK_JACKPOT
  };
};

export const resetCasinoGameJackpot = (): ActionType => {
  return {
    type: CasinoDataActionTypes.RESET_CASINO_GAME_JACKPOT
  };
};

export const setActiveOptions = (
  data: {
    activeCategories?: string[];
    activeProviders?: string[];
  },
  shouldUpdateUID = true
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_ACTIVE_OPTIONS,
    payload: { ...data, shouldUpdateUID }
  };
};

export const setCashedTournamentData = (data: {
  data: CasinoTournamentsType[];
  productTypeId: ETournamentProductTypeIds;
}): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_CASHED_TOURNAMENTS_DATA,
    payload: data
  };
};

export const setCashedTournamentOptions = (
  data: SelectedOptions
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_CASHED_TOURNAMENTS_OPTIONS,
    payload: data
  };
};

export const setSelectedCasinoGame = (
  data: NewCasinoGame | null
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_SELECTED_CASINO_GAME,
    payload: data
  };
};

export const setJoinedTournaments = (payload: number): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_JOINED_TOURNAMENTS,
    payload
  };
};

export const setSelectedCasinoCategoryId = (payload: string): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_SELECTED_CASINO_CATEGORY_ID,
    payload
  };
};

export const setTournamentActiveProductTypeId = (
  payload: number
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_TOURNAMENT_ACTIVE_PRODUCT_TYPE_ID,
    payload
  };
};

export const setOnlinePlayersCrashGamesIds = (
  payload: Array<number>
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_ONLINE__PLAYERS_CRASH_GAMES_IDS,
    payload
  };
};

export const setOnlinePlayesCrashGamesData = (
  payload: CrashGamesDataResults
): ActionType => {
  return {
    type: CasinoDataActionTypes.SET_ONLINE_PLAYERS_CRASh_GAMES_DATA,
    payload
  };
};
