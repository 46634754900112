/**
 * This section describes error codes coming from backend
 * usually with “result” field in “data” node
 */

export const backendErrorCodes: Record<string, string> = {
  ONHOLD: 'swarm.acceptedButPlacedReview',
  0: '0',
  12: 'swarm.captchaFieldInvalid',
  18: 'swarm.sizeLimitException',
  20: 'swarm.withdrawBalanceIsLess',
  21: 'swarm.captchaStringMismatch',
  25: 'swarm.BirthDateRequired',
  26: 'swarm.invalidPromoCode',
  99: 'swarm.counterOfferError',
  1000: 'swarm.internalError',
  1001: 'swarm.nullValueForArgument',
  1002: 'swarm.wrongLoginPassword',
  1003: 'swarm.userBlocked',
  1004: 'swarm.userDismissed',
  1005: 'swarm.IncorrectOldPassword',
  1008: 'swarm.LoggingInThePageIsNotPossible',
  1009: 'swarm.VerificationCodeNotExist',
  1012: 'swarm.IncorrectPhoneNumber',
  1013: 'swarm.PasswordShort',
  1014: 'swarm.cerificationSMS',
  1021: 'swarm.passShouldContain8Chars',
  1023: 'swarm.notVerifiedViaEmail',
  1070: 'swarm.switchToFTNWallet',
  1099: 'swarm.forkException',
  1100: 'swarm.GameAlreadyStarted',
  1102: 'swarm.gameStartTimeIsPast',
  1103: 'swarm.BetEditingTimePast',
  1104: 'swarm.BetIsPayed',
  1105: 'swarm.BetStatusNotFixed',
  1106: 'swarm.betLose',
  1107: 'swarm.BetIsOnline',
  1108: 'swarm.WrongValueCoefficient',
  1109: 'swarm.wrongValueForAmount',
  1112: 'swarm.RequestAlreadyPaid',
  1113: 'swarm.RequestAlreadyStored',
  1117: 'swarm.WrongLoginOrEmail',
  1118: 'swarm.duplicateLogin',
  1119: 'swarm.DuplicateEmail',
  1120: 'swarm.duplicateNickname',
  1121: 'swarm.duplicateRootNode',
  1122: 'swarm.duplicatePersonalId',
  1123: 'swarm.passportNumberIsAlreadyRegistered',
  1124: 'swarm.AmountNotValidRange',
  1125: 'swarm.BetTypeError',
  1126: 'swarm.BetDeclinedSKKS',
  1127: 'swarm.duplicatePhoneNumber',
  1150: 'swarm.notAllowedBetEvent',
  1151: 'swarm.duplicateFacebookID',
  1170: 'swarm.CardLotBlocked',
  1171: 'swarm.ScratchCardAlreadyActivated',
  1172: 'swarm.ScratchCardBlocked',
  1174: 'swarm.wrongScratchCardCurrency',
  1200: 'swarm.wrongValueException',
  1273: 'swarm.wrongScratchCardNumber',
  1300: 'swarm.doubleValueException',
  1400: 'swarm.doubleEventException',
  1500: 'swarm.limitException',
  1550: 'swarm.theSumExceedsAllowableLimit',
  1560: 'swarm.TheSumIsLessAllowableLimit',
  1600: 'swarm.correctionOfCoefficient.',
  1700: 'swarm.WrongAccessException',
  1800: 'swarm.OddsIsChanged',
  BetSelectionChanged: 'swarm.OddsIsChanged',
  1900: 'swarm.eventsOnlyExpress',
  1910: 'swarm.TheEventsOnlyOrdinar',
  2000: 'swarm.oddsRestrictionException',
  2001: 'swarm.argumentError',
  2002: 'swarm.betshopNotFound',
  2003: 'swarm.betStateError',
  2004: 'swarm.canNotDeleteNodeWithChildren',
  2005: 'swarm.CashdesknotFound',
  2006: 'swarm.CashdeskNotRegistered',
  2007: 'swarm.currencyMismatch',
  2008: 'swarm.ClientExcluded',
  '-2008': 'swarm.ClientExcluded',
  2009: 'swarm.clientLocked',
  2011: 'swarm.dbEntityValidationException',
  2012: 'swarm.dbUpdateConcurrencyException',
  2013: 'swarm.documentAlreadyInitialized',
  2014: 'swarm.documentInvalidAction',
  2015: 'swarm.documentNotInitialized',
  2016: 'swarm.documentNotFound',
  2017: 'swarm.documentTypeError',
  2018: 'swarm.emailEmpty',
  2020: 'swarm.FirstNameEmpty',
  2021: 'swarm.gameAlreadyExisits',
  2022: 'swarm.gameNotExist',
  2023: 'swarm.incorrectRequest',
  2024: 'swarm.invalidEmail',
  2025: 'swarm.invalidFilter',
  2026: 'swarm.invalidNodeId',
  2027: 'swarm.invalidTreeId',
  2028: 'swarm.InvalidPaymentSystem',
  2029: 'swarm.inputValuesMismatch',
  2030: 'swarm.isLiveFlagMismatch',
  2031: 'swarm.LastNameNotBeEmpty',
  2032: 'swarm.matchAccessError',
  2033: 'swarm.marketSuspended',
  MarketSuspended: 'swarm.marketSuspended',
  2034: 'swarm.matchNotFound',
  2035: 'swarm.matchStateNotFound',
  2036: 'swarm.gameSuspended',
  2037: 'swarm.maxDailyBetAmountError',
  MaxDailyBetAmountError: 'swarm.maxDailyBetAmountError',
  2038: 'swarm.maxSingleBetAmountError',
  MaxSingleBetAmountError: 'swarm.maxSingleBetAmountError',
  2039: 'swarm.MinDepositRequestSum',
  2040: 'swarm.MaxWithdrawalRequestsCount',
  2041: 'swarm.maxWithdrawalRequestSum',
  2042: 'swarm.marketTypeGroupNotFound',
  2043: 'swarm.MinWithdrawalRequestSum',
  2044: 'swarm.NodeAndSequenceAlreadyExists',
  2045: 'swarm.noRootNodeInSportResultTemplate',
  2046: 'swarm.notAllowed',
  2047: 'swarm.notAuthorized',
  2048: 'swarm.wrongRegion',
  2049: 'swarm.nickNameAlreadySet',
  2050: 'swarm.oneMarketTypeGroup',
  2051: 'swarm.partnerApiAccessNotActivated',
  2052: 'swarm.partnerApiClientBalanceError',
  2053: 'swarm.partnerApiClientLimitError',
  2054: 'swarm.partnerApiEmptyMethod',
  2055: 'swarm.partnerApiEmptyRequestBody',
  2056: 'swarm.partnerApiMaxAllowableLimit',
  2057: 'swarm.PartnerApiMinAllowableLimit',
  2058: 'swarm.partnerApiPassTokenError',
  2059: 'swarm.PartnerApiTimestampExpired',
  2060: 'swarm.partnerApiTokenExpired',
  2061: 'swarm.PartnerApiUserBlocked',
  2062: 'swarm.partnerApiWrongHash',
  2063: 'swarm.PartnerApiWrongLoginEmail',
  2064: 'swarm.partnerApiWrongAccess',
  2065: 'swarm.partnerNotFound',
  2066: 'swarm.partnerCommercialFeeNotFound',
  2068: 'swarm.permissionNotFound',
  2069: 'swarm.regionNotFound',
  2070: 'swarm.requestNotAllowed',
  2072: 'swarm.resetCodeExpired',
  2073: 'swarm.roleNotFound',
  2074: 'swarm.SamePasswordAndLogin',
  2075: 'swarm.selectionNotFound',
  2076: 'swarm.selectionCountMismatch',
  2077: 'swarm.eventSuspended',
  2078: 'swarm.sportMismatch',
  2079: 'swarm.shiftDoesNotExists',
  2080: 'swarm.SportNotSupported',
  2081: 'swarm.teamAlreadyExists',
  2082: 'swarm.transactionAlreadyExists',
  2083: 'swarm.transactionAlreadyOpen',
  2084: 'swarm.transactionAmountError',
  2085: 'swarm.translationAlreadyExists',
  2086: 'swarm.translationNotFound',
  2087: 'swarm.userBlocked',
  2088: 'swarm.userPasswordMustBeLonger',
  2089: 'swarm.userPasswordRegExpNotValid',
  2090: 'swarm.operationAlreadyProcessed',
  2091: 'swarm.passwordExpired',
  2092: 'swarm.userNotFound',
  2093: 'swarm.usernameExist',
  2094: 'swarm.matchStartTimeHasPassed',
  2095: 'swarm.withdrawalRequestDocumentNotFound',
  2097: 'swarm.wrongClassifierGrouopId',
  2098: 'swarm.wrongCurrencyCode',
  2099: 'swarm.wrongInputParameters',
  2100: 'swarm.paymentRestrictionException',
  2200: 'swarm.ClientBetStakeLimitError',
  ClientBetStakeLimitError: 'swarm.CreateBetClientBetStakeLimitError',
  2300: 'swarm.officeLimitException',
  2302: 'swarm.terminalBalanceException',
  2400: 'swarm.transactionAmountError',
  2403: 'swarm.activeRequestsForClient',
  2404: 'swarm.cashOutNotAllowed',
  2405: 'swarm.clientBonusNotFound',
  2406: 'swarm.PartnerBonusNotFound',
  2407: 'swarm.ClientHasActiveBonus',
  2408: 'swarm.invalidClientVerificationStep',
  2409: 'swarm.partnerSettingSelfExclusion',
  2410: 'swarm.invalidSelfExclusionType',
  2411: 'swarm.invalidClientLimitType',
  2412: 'swarm.invalidClientBonus',
  2413: 'swarm.clientRestrictedAction',
  2414: 'swarm.eventsOnlyOrdinar',
  2415: 'swarm.partnerNotSupportedTestClient',
  2416: 'swarm.partnerLoyaltyProgram',
  2417: 'swarm.pointExchangeRangeExceed',
  2418: 'swarm.clientLoyaltyProgram',
  2419: 'swarm.officeLimitException',
  2420: 'swarm.clientHasAcceptedBonus',
  2421: 'swarm.partnerApiError',
  2422: 'swarm.TeamNotFound',
  2423: 'swarm.invalidVlienVerificationStepState',
  2424: 'swarm.partnerSportsbookCurrencySettingError',
  2425: 'swarm.ClientBetStakeMinLimitErrors',
  ClientBetMinStakeLimitError: 'swarm.ClientBetStakeMinLimitErrors',
  PartnerLimitError: 'swarm.PartnerLimitError',
  3025: 'swarm.PartnerLimitError',
  2429: 'swarm.transactionAmountExceedsFrozenMoney',
  2443: 'swarm.notEnoughPoint',
  2455: 'swarm.operationInProgress',
  OperationInProgress: 'swarm.operationInProgress',
  2457: 'swarm.captchaRequired',
  2460: 'swarm.CounterOfferWrongPrice',
  2461: 'swarm.CounterOfferUnavailableForMarketType',
  2468: 'swarm.amountChanged',
  2472: 'swarm.confirmationCodeCannotBeEmpty',
  2473: 'swarm.phoneNumberCannotBeEmpty',
  2474: 'swarm.invalidVerificationCode',
  2475: 'swarm.CounterOfferMinAmountError',
  2476: 'swarm.codeExpired',
  2481: 'swarm.codeAlreadyUsed',
  2493: 'swarm.invalidIpAddress',
  2494: 'swarm.matchNotBooked',
  2495: 'swarm.ClientHasNoAccessToBonus',
  2497: 'swarm.MaxWeeklyBetAmountError',
  2499: 'swarm.MaxMonthlyBetAmountError',
  2503: 'swarm.BirthDateRequired',
  2507: 'swarm.LimitByPeriodError',
  2508: 'swarm.personalIdRequired',
  2524: 'swarm.PlayerAlreadyHasAccountInTheSameLicense',
  2530: 'swarm.PasswordHasBeenUsedPreviously',
  2538: 'swarm.WithdrawalNotAllowedUnplayedAmount',
  2547: 'swarm.MaxWithdrawalAmountPerDay',
  2556: 'swarm.MinCashoutAmount',
  2613: 'swarm.BetSelectionsTeamCombinationError',
  3000: 'swarm.wrongLoginAttempts',
  3002: 'swarm.wrongOldPassword',
  3003: 'swarm.wrongTransactionId',
  3004: 'swarm.invalidToken',
  3005: 'swarm.tokenAlreadyExists',
  3006: 'swarm.invalidPaymentSystemOperation',
  3007: 'swarm.invalidPaymentSystemCommission',
  3008: 'swarm.imageUploadFailed',
  3009: 'swarm.incorrectClientRequest',
  3011: 'swarm.isNotAnImage',
  3012: 'swarm.cantCreateDirectory',
  3013: 'swarm.invalidAgent',
  3014: 'swarm.invalidAgentSystem',
  3015: 'swarm.negativeAmount',
  3016: 'swarm.wrongAgentGroup',
  3017: 'swarm.wrongAgentGroupValue',
  3018: 'swarm.invalidAgentGroupItem',
  3019: 'swarm.betSelectionsCannotChainedTogether',
  2553: 'swarm.IbanCheckDigitNotCorrect',
  2505: 'swarm.DateRangeNotValid',
  2491: 'swarm.LinkedMatches',
  999: 'swarm.NoneType',
  2430: 'swarm.WrongHash',
  2431: 'swarm.PartnerMismatch',
  2432: 'swarm.MatchNotVisible',
  2433: 'swarm.LoyaltyLevelNotFount',
  2434: 'swarm.MaxWithdrawalAmount',
  2435: 'swarm.InvalidShopItem',
  2436: 'swarm.SelectionSuspendedBeforeStartTime',
  2437: 'swarm.BonusNotAllowedForSuperbet',
  2438: 'swarm.LockTimeout',
  2439: 'swarm.InvalidClaimOptionTemplate',
  2440: 'swarm.PartialCashOutAmountError',
  2441: 'swarm.ClientLimitChangePeriod',
  2442: 'swarm.PlayerNotAllowedToRegisterOnWebsite',
  2426: 'swarm.MaxDepositRequestSum',
  2427: 'swarm.EmailWrongHash',
  2428: 'swarm.ClientAlreadySelfExcluded',
  2067: 'swarm.PartnerApiSpecific',
  2071: 'swarm.RequestStateError',
  2444: 'swarm.PartnerBonusMarkedDeleted',
  2445: 'swarm.BonusEngineError',
  2446: 'swarm.MaxDepositAmount',
  2447: 'swarm.ClientExcludedNotAllowedRange',
  2448: 'swarm.CashOutNotAllowedMaximumOdd',
  2449: 'swarm.MarketNotVisible',
  2450: 'swarm.BonusCanNotBeAccepted',
  2451: 'swarm.RequiredFieldsMissing',
  2452: 'swarm.CounterOfferNotAvailable',
  2453: 'swarm.ObjectNotFound',
  2454: 'swarm.InvalidDepositWithDrawBetshop',
  2456: 'swarm.EmailTypeNotFound',
  2458: 'swarm.OperationRestrictedForCurrency',
  2459: 'swarm.ClientAgeRestriction',
  2462: 'swarm.ClientVerificationStepExpired',
  2463: 'swarm.MessageNotFound',
  2464: 'swarm.NotSeparateWalletForPartner',
  2465: 'swarm.CashDeskIsBlocked',
  2466: 'swarm.BetSelectionsCanNotBeNullOrEmpty',
  2467: 'swarm.NotAllowedLicenseSelfExcluded',
  2469: 'swarm.InvalidPromoCode',
  2470: 'swarm.PartnerNotSupportSMS',
  2471: 'swarm.PromoCodeAlreadyApplied',
  2477: 'swarm.FreeBetTokenUsed',
  2478: 'swarm.SelectionMultipleCount',
  2479: 'swarm.ModelErrors',
  2480: 'swarm.InvalidSwiftCode',
  2482: 'swarm.PhoneNumberOrContentAreInvalid',
  2483: 'swarm.PhoneNumberIsBlackListed',
  2484: 'swarm.DuplicateIBAN',
  2485: 'swarm.CounterOfferUnavailable',
  2486: 'swarm.DuplicateRFID',
  2487: 'swarm.CounterOfferUnavailableForSport',
  2488: 'swarm.NotallowedPasswordContainsName',
  2489: 'swarm.TerminalTicketAlreadyPaid',
  2490: 'swarm.TerminalTicketNotFound',
  2492: 'swarm.CounterOfferError',
  2496: 'swarm.RestrictedIPAddress',
  2498: 'swarm.PartnerRestrictedCountry',
  2500: 'swarm.ReportNotFound',
  2501: 'swarm.InvalidRelatedPartner',
  2502: 'swarm.CashDeskIsDeleted',
  2504: 'swarm.InvalidSwitchingType',
  2512: 'swarm.PartnerBlocked',
  3035: 'swarm.RegistrationWithReferenceNotAllowedForAffiliateClient',
  3091: 'swarm.ClientRestrictedForActionDeposit',
  3093: 'swarm.ClientRestrictedForActionLogin',
  3094: 'swarm.ClientRestrictedForActionBet',
  3098: 'swarm.BetAmountGreaterThanWinning',
  3096: 'swarm.ClientRestrictedForActionClaimBonus',
  3109: 'swarm.SessionDurationLimitExceeded',
  3047: 'swarm.BoostBonusMaxAmountExceeded',
  3113: 'swarm.ClientGamingStatueCantChangeWaitingPeriod',
  //20007: "swarm.transactionFrozenMoney",
  MaxMonthlyBetAmountError: 'swarm.MaxMonthlyBetAmountError',
  '-1': 'swarm.fail',
  '-99': 'swarm.alreadyClaimed',
  '-1000': 'swarm.internalError',
  '-1001': 'swarm.userNotFound',
  '-1002': 'swarm.wrongLoginPassword',
  '-1005': 'swarm.passwordError',
  '-1010': 'swarm.invalidTournamentIdError',
  '-1013': 'swarm.alreadyJoinedTournamentError',
  '-1118': 'swarm.duplicateLogin',
  '-1123': 'swarm.passportNumberisRegistered',
  '-1127': 'swarm.duplicatePhone',
  '-2480': 'swarm.invalidSwiftCode',
  '-2077': 'swarm.selectionSuspended',
  '-2071': 'swarm.requestStateError',
  '-2009': 'swarm.clientLocked',
  '-3001': 'swarm.currencyNotSupported',
  '-2018': 'swarm.emailEmpty',
  '-2408': 'swarm.invalidClientVerificationStep',
  '-2404': 'swarm.CashOutNotAllowed',
  '-2046': 'swarm.notAllowed',
  '-2074': 'swarm.samePasswordAndLogin',
  '-2467': 'swarm.notAllowedLicenseSelfExcluded',
  '-2447': 'swarm.clientExcludedNotAllowedRange',
  '-2469': 'swarm.invalidPromoCode',
  '-2471': 'swarm.promoCodeAlreadyApplied',
  '-2428': 'swarm.clientAlreadySelfExcluded',
  '-2400': 'swarm.transactionAmountError',
  '-2409': 'swarm.partnerSettingSelfExclusion',
  '-2440': 'swarm.cashOutNotAllowed',
  '-2446': 'swarm.MaxDepositAmount',
  '-2036': 'swarm.gameSuspended',
  '-2033': 'swarm.marketSuspended',
  '-2418': 'swarm.ClientNotUsingLoyaltyProgram',
  '-2472': 'swarm.confirmationCodeCannotBeEmpty',
  '-2473': 'swarm.phoneNumberCannotBeEmpty',
  '-2474': 'swarm.invalidVerificationCode',
  '-2476': 'swarm.codeExpired',
  '-2481': 'swarm.codeAlreadyUsed',
  '-2493': 'swarm.invalidIpAddress',
  '-1119': 'swarm.DuplicateEmail',
  '-2098': 'swarm.wrongCurrencyCode',
  '-2543': 'swarm.invalidBirthDate',
  '-2545': 'swarm.ClientVerificationStepNotFound',
  '-2549': 'swarm.InvalidPhoneNumber',
  '-10000': 'swarm.serviceUnavailable',
  // "-20007": "swarm.transactionFrozenMoney",
  '-1122': 'swarm.duplicatePersonalId',
  '-2099': 'swarm.wrongInputParameters',
  '-2001': 'swarm.argumentError',
  '-2506': 'swarm.limitAlreadyExists',
  '2533': 'swarm.clientMustBeVerifiedToAccessBonus',
  '-2532': 'swarm.PasswordConfirmationError',
  '-2524': 'swarm.PlayerAlreadyHasAccountInTheSameLicense',
  '-2525': 'swarm.ClientVerificationFailed',
  '-2528': 'swarm.ImageDataNotFound',
  '-2539': 'swarm.MarketTypeNotAvailableforBetBuilder',
  '-2488': 'swarm.notallowedPasswordContainsName',
  '-2541': 'swarm.notallowedPasswordContainsEmail',
  '-2534': 'swarm.phoneNumberIsNotSet',
  '-2456': 'swarm.emailTypeNotFound',
  '-2484': 'swarm.dublicateIban',
  '-2535': 'swarm.SMSProviderError',
  '-2503': 'swarm.BirthDateRequired',
  '-2513': 'swarm.twoFactorAuthenticationIsNotEnabled',
  '-2516': 'swarm.invalidClientTwoFactorAuthenticationCode',
  '-2515': 'swarm.invalidDevice',
  '2514': 'swarm.invalidDeviceFingerprint',
  '-2430': 'swarm.wrongHash',
  '-2028': 'swarm.buddyToBuddyInvalidPaymentSystem',
  '-2056': 'swarm.PartnerApiMaxAllowableLimit',
  '-2007': 'swarm.currencyMismatch',
  '-2464': 'swarm.NotSeparateWalletForPartner',
  '-2517': 'swarm.ClientNotVerified',
  '-2556': 'swarm.MinCashoutAmount',
  '-2589': 'swarm.ADMSubjectAlreadyHoldsAccount',
  '-3030': 'swarm.RequestTypeError',
  '-2023': 'swarm.incorrectRequest',
  '-3009': 'swarm.incorrectClientRequest',
  '-2553': 'swarm.IbanCheckDigitNotCorrect',
  '-2505': 'swarm.DateRangeNotValid',
  '-2527': 'swarm.ClientWalletDoesNotExist',
  '-3043': 'swarm.DublicateDocumentName',
  '-3060': 'swarm.EmptyCredetialsError',
  '-3064': 'swarm.InvalidCPF',
  '-3068': 'swarm.PromoCodeInvalidLastSportBetTime',
  '-3039': 'swarm.PromoCodeInvalidLastDepositTime',
  '-3040': 'swarm.PromoCodeInvalidLastCasinoBetTime',
  '-999': 'swarm.NoneType',
  '-2611': 'swarm.NotEnoughDeposits',
  '-2037': 'swarm.maxDailyBetAmountError',
  '-2038': 'swarm.maxSingleBetAmountError',
  '-2497': 'swarm.MaxWeeklyBetAmountError',
  '-2499': 'swarm.MaxMonthlyBetAmountError',
  '-2455': 'swarm.OperationInProgress',
  '-2413': 'swarm.clientRestrictedAction',
  '-3073': 'swarm.invalidRequest',
  '-3088': 'swarm.IbanStructureNotCorrect',
  '-3081': 'swarm.ConsultasPrimeCPFError',
  '-2536': 'swarm.RegularExpressionNotmatching',
  1006: 'swarm.wrongClientToken',
  '-1006': 'swarm.wrongClientToken',
  '-2451': 'swarm.RequiredFieldsMissing',
  '-3085': 'swarm.NotAllowedToRemoveDepositLimit',
  '-1070': 'swarm.switchToFTNWallet',
  '-3065': 'swarm.PhoneNumberOrContentContainsInvalidOrIllegialValues',
  '-2453': 'swarm.ObjectNotFound',
  '-3070': 'swarm.PlayerAlreadyHasAccountInTheSamePartner',
  '-3095': 'swarm.ClientRestrictedForActionIncreaseLimit',
  '-3035': 'swarm.RegistrationWithReferenceNotAllowedForAffiliateClient',
  '-3105': 'swarm.ClientLimitChangePeriodDeposit',
  '-3106': 'swarm.BonusRequestInvalidDepositCount',
  '-3100': 'swarm.BonusRequestInvalidLastDepositTime',
  '-3036': 'swarm.PromoCodeNotallowedPlayerCurrency',
  '-3031': 'swarm.PromoCodeNotallowedAffilateId',
  '-3101': 'swarm.BonusRequestInvalidLastDepositCount',
  '-3102': 'swarm.BonusRequestInvalidSportsbookProfile',
  '-3103': 'swarm.BonusRequestInvalidLastBetTime',
  '-3104': 'swarm.MaximumRejectedRequestsPerDay',
  '-2448': 'swarm.CashOutNotAllowedMaximumOdd',
  '-503': 'swarm.serviceUnavailable',
  '-2507': 'swarm.LimitByPeriodError',
  '-2070': 'swarm.requestNotAllowed',
  '-2412': 'swarm.invalidClientBonus',
  '-3111': 'swarm.PromoCodeInvalidDepositAmount',
  ClientRestrictedForAction: 'swarm.ClientRestrictedForAction',
  MaxDailyDepositAmount: 'swarm.MaxDailyDepositAmount',
  MaxWeeklyDepositAmount: 'swarm.MaxWeeklyDepositAmount',
  MaxMonthlyDepositAmount: 'swarm.MaxMonthlyDepositAmount',
  CashOutNotAllowed: 'swarm.CashOutNotAllowed',
  SelectionSuspended: 'swarm.eventSuspended',
  MarketNotVisible: 'swarm.MarketNotVisible',
  ClientExcluded: 'swarm.ClientExcluded',
  AmountChanged: 'swarm.amountChanged',
  LimitByPeriodError: 'swarm.LimitByPeriodError',
  PendingDepositRequests: 'swarm.PendingDepositRequests',
  MaxWithdrawalRequestsCount: 'swarm.MaxWithdrawalRequestsCount',
  ClientRestrictedForActionWithdraw: 'swarm.ClientRestrictedForActionWithdraw',
  PendingWithdrawalRequests: 'swarm.PendingWithdrawalRequests',
  ClientBetStakeNoLimitError: 'swarm.ClientBetStakeNoLimitError',
  MaxWithdrawalPaidCountPerDayExceeded:
    'swarm.MaxWithdrawalPaidCountPerDayExceeded',
  WithdrawalNotAllowedUnplayedAmount:
    'swarm.WithdrawalNotAllowedUnplayedAmount',
  CashOutNotAllowedMaximumOdd: 'swarm.CashOutNotAllowedMaximumOdd',
  TransactionAmountError: 'swarm.TransactionAmountError',
  ClientHasActiveBonus: 'swarm.ClientHasActiveBonus'
};
const ERROR_CODES_SHOW_ERROR = 20007;
export const ErrorCodesShowError = [ERROR_CODES_SHOW_ERROR, '-20007'];

export const skipedErrorCodes: Record<
  string,
  { notify: boolean; msg?: string }
> = {
  12: { notify: false },
  // there where error visibility issue with duplicated message in case of casino limits change
  2441: { notify: false, msg: 'swarm.clientLimitChangePeriod' },
  '-2441': { notify: true, msg: 'swarm.clientLimitChangePeriod' },
  '-3105': { notify: true, msg: 'swarm.ClientLimitChangePeriodDeposit' }
};

export const errorCodesToShowBackendError = ['-99'];

export const backendErrorDetailsKeys: Record<string, string> = {
  max_bet: 'MaxAllowedBetStake',
  min_bet: 'MinAllowedBetStake'
};
export const SHOW_RECAPTCHA_POPUP_ERROR_CODES: Record<number, number> = {
  29: 29,
  21: 21,
  27: 27
};

export const SHOW_RECAPTCHA_POPUP_ERROR_STATUS = 2457;

export const ErrorCode = {
  COUNTER_OFFER_ERROR: 99
};
